import React from "react";
import UrlArgsBundle from "../../../core/url_args_bundle";
import {ACTIVITY_TAG, FRAGMENT_TAG, GAME_TYPES, STARLINE_SUB_CATEGORIES} from "../../../utils/constants";
import {withRouter} from "react-router-dom";
import SingleDigit from "../../../drawable/subcategory_images/single_digit.png";
import SinglePaane from "../../../drawable/subcategory_images/single_panna.png";
import DoublePaane from "../../../drawable/subcategory_images/double_panna.png";
import TriplePaane from "../../../drawable/subcategory_images/tripple_panna.png";

class StarlineGameSubCategoriesFragment extends React.Component {

    urlArgsBundle = new UrlArgsBundle();
    changeFragment = (fragmentTag) => {
        this.urlArgsBundle.setActivityTag(ACTIVITY_TAG.MAIN).setFragmentTag(fragmentTag);
        return this.urlArgsBundle.getArgsAsUrlParams()
    }

    render() {
        return (
            <div className={"p-10 gap-12 md:p-24 md:gap-16 lg:p-36 flex flex-wrap lg:gap-24 justify-center"}>
                <div className="cursor-pointer w-36 h-36 lg:w-48 lg:h-48" onClick={() => {
                    this.urlArgsBundle.putExtra('subCategory' , STARLINE_SUB_CATEGORIES.SINGLE_DIGIT)
                    this.urlArgsBundle.putExtra('gameType' , GAME_TYPES.STARLINE)
                    this.props.history.push(this.changeFragment(FRAGMENT_TAG.GAME_RATE))
                }}>
                    <img src={SingleDigit} alt="singleDigit" />
                </div>
                <div className="cursor-pointer w-36 h-36 lg:w-48 lg:h-48" onClick={() => {
                    this.urlArgsBundle.putExtra('subCategory' , STARLINE_SUB_CATEGORIES.SINGLE_PAANA)
                    this.urlArgsBundle.putExtra('gameType' , GAME_TYPES.STARLINE)
                    this.props.history.push(this.changeFragment(FRAGMENT_TAG.GAME_RATE))
                }}>
                    <img src={SinglePaane} alt="singleDigit"/>
                </div>
                <div className="cursor-pointer w-36 h-36 lg:w-48 lg:h-48" onClick={() => {
                    this.urlArgsBundle.putExtra('subCategory' , STARLINE_SUB_CATEGORIES.DOUBLE_PAANA)
                    this.urlArgsBundle.putExtra('gameType' , GAME_TYPES.STARLINE)
                    this.props.history.push(this.changeFragment(FRAGMENT_TAG.GAME_RATE))
                }}>
                    <img src={DoublePaane} alt="singleDigit"/>
                </div>
                <div className="cursor-pointer w-36 h-36 lg:w-48 lg:h-48" onClick={() => {
                    this.urlArgsBundle.putExtra('subCategory' , STARLINE_SUB_CATEGORIES.TRIPLE_PAANA)
                    this.urlArgsBundle.putExtra('gameType' , GAME_TYPES.STARLINE)
                    this.props.history.push(this.changeFragment(FRAGMENT_TAG.GAME_RATE))
                }}>
                    <img src={TriplePaane} alt="singleDigit"/>
                </div>
            </div>
        );
    }
}

export default withRouter(StarlineGameSubCategoriesFragment)