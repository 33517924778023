import React from 'react';
import './fragment_update_city_styles.scss';

import axios from 'axios';
import { createApiServerRoute } from "../../../models/network/api_client";

import { connect } from 'react-redux';
import {actionSetCurrentUpdateCityName} from "../../../models/redux/reducers/activity_main_reducers/update_city_reducer/action_update_city";
import {actionSetCurrentUpdateCityEnabled} from "../../../models/redux/reducers/activity_main_reducers/update_city_reducer/action_update_city";
import { withRouter } from "react-router-dom";

class UpdateCityFragment extends React.Component {

    componentDidMount() {
        let activeCity = this.props.cities.filter(city => city.id === this.props.match.params["captain_id"])[0]
        this.props.actionSetCurrentUpdateCityName(activeCity.name)
        this.props.actionSetCurrentUpdateCityEnabled(activeCity.enabled ? 'Y' : 'N')
    }

    updateCity = (event) =>{
        event.preventDefault();

        const params = new URLSearchParams();
        params.append('city_id', this.props.match.params["captain_id"]);
        params.append('city_name', this.props.city_name);
        params.append('enabled', this.props.city_enabled);

        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('update_city.php'), params, config)
            .then(res => {
                if(res.data.cab_5_response_state === 'OK'){
                    this.props.history.goBack();
                }
            });
    }

    render() {
        return <div className="flex flex-column justify-center items-center md:mt-44">
            <form className="w-full max-w-lg" onSubmit={this.updateCity}>
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 grid-cols-2">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                               htmlFor="grid-first-name">
                            City Name
                        </label>
                        <input
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            id="grid-first-name" type="text" placeholder="Islamabad" value={this.props.city_name} required={true} onChange={(event) =>{
                            this.props.actionSetCurrentUpdateCityName(event.target.value)
                        }
                        }/>
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 grid-cols-2"
                               htmlFor="grid-state">
                            Enable/Disable
                        </label>
                        <div className="relative">
                            <select value={this.props.city_enabled}
                                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="grid-state" onChange={(event) =>{
                                this.props.actionSetCurrentUpdateCityEnabled(event.target.value)
                            }
                            }>
                                <option value='Y'>Enable</option>
                                <option value='N'>Disable</option>
                            </select>
                            <div
                                className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 20 20">
                                    <path
                                        d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="md:flex md:items-center md:ml-12">
                    <div className="md:w-1/3"></div>
                    <div className="md:w-2/3">
                        <button
                            className="shadow bg-blue-500 hover:bg-blue-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                            type="submit">
                            Update City
                        </button>
                    </div>
                </div>
            </form>
        </div>
    }
}

const mapStateToProps = rootReducer => ({
    city_name: rootReducer.update_city.cityName,
    city_enabled: rootReducer.update_city.enabled,
    cities: rootReducer.cities.currentCities
});

const mapDispatchToProps = dispatch => ({
    actionSetCurrentUpdateCityName: name => dispatch(actionSetCurrentUpdateCityName(name)),
    actionSetCurrentUpdateCityEnabled: enabled => dispatch(actionSetCurrentUpdateCityEnabled(enabled))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UpdateCityFragment));
