import React from 'react';
import './play_history_styles.scss';
import axios from 'axios';
import { createApiServerRoute } from "../../../models/network/api_client";
import { connect } from 'react-redux';
import { actionSetCurrentClients } from "../../../models/redux/reducers/activity_main_reducers/clients_reducer/action_clients";
import Table from "../../../components/table_component/table/table";
import TableHeader from "../../../components/table_component/table_header/table_header";
import TableBody from "../../../components/table_component/table_body/table_body";
import TableIndexColumn from "../../../components/table_component/table_index_column/table_index_column";
import TableGreenishTextualColumn from "../../../components/table_component/table_greenish_textual_column/table_greenish_textual_column";
import TableTextualColumn from "../../../components/table_component/table_textual_column/table_textual_column";
import TableHeading from "../../../components/table_component/table_heading/table_heading";
import { API_KEY } from "../../../utils/constants";
import {withRouter} from "react-router-dom";
import {Button} from "@material-ui/core";
import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';
import moment from 'moment';


class PlayHistoryFragment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            all_histories: null,
      currentPage: 1,  // Current page number
      itemsPerPage: 20  // Number of items to display per page
        }
    }

    componentDidMount() {
        const params = new URLSearchParams();
        params.append("__api_key__", API_KEY)
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('fetch_all_users_bid_histories.php'), params, config)
            .then(res => {
                if (res.data.state === 'OK') {
                    const mergeResult = [...res.data.data.bids.both_side_fc_bid_histories, ...res.data.data.bids.same_side_fc_bid_histories, ...res.data.data.bids.fc_bid_histories, ...res.data.data.bids.teer_bid_histories];
                    const sortedResult=mergeResult.slice(0).sort((a,b)=>
                        a.played_at.localeCompare(b.played_at));
                    this.setState({
                        all_histories: sortedResult.filter(item=>moment(item['played_at']).format('DD-MM-YYYY') === moment(new Date()).format('DD-MM-YYYY'))
                    })
                } else {
                    console.log(res)
                }
            });
    }

    
    downloadExcel = () => {

        const workbook = new ExcelJS.Workbook();

// Create a new worksheet
const worksheet = workbook.addWorksheet('Sheet1');

// Define your data
const exportData = this.state.all_histories.map((item, i) => ({
  id: i+1,
  Name: item['name'],
  ContactNumber: item['phone'],
  GameName: item['game_name'],
  GameType: item['game_type'],
  BidOn: this.bidOnrenderForExcel(item),
  Amount: item['amount'] === undefined ? `₹ ${this.teerAmountrender(item)}` : `₹ ${item['amount']}`,
  Total: item['game_type'] === 'Direct FC' ? `₹ ${item['amount']}`: (item['total'] === undefined ? `₹ ${this.teerAmountrender(item)}` : `₹ ${item['total']}`),
  Date: item['played_at'],
}));

// Add headers to the worksheet
const headers = Object.keys(exportData[0]);
worksheet.addRow(headers);

// Add data to the worksheet
exportData.forEach((rowData) => {
  const row = Object.values(rowData);
  worksheet.addRow(row);
});

// Set column widths
worksheet.columns = [
  { width: 10 },
  { width: 20 },
  { width: 25 },
  { width: 30 },
  { width: 20 },
  { width: 60 },
  { width: 20 },
  { width: 20 },
  { width: 20 },
];

worksheet.eachRow((row) => {
    row.eachCell((cell) => {
      cell.alignment = { vertical: 'middle' };
    });
  });

// Set wrapText for the desired range of cells
const desiredRange = worksheet.getColumn('F');
desiredRange.eachCell({ includeEmpty: true }, (cell) => {
  cell.alignment = { wrapText: true };
});

// Generate a buffer containing the Excel file
workbook.xlsx.writeBuffer().then((buffer) => {
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = URL.createObjectURL(blob);

  // Create a download link
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `All_game_${moment(new Date()).format('DD-MM-YYYY')}.xlsx`);
  document.body.appendChild(link);

  // Trigger the download
  link.click();

  // Cleanup
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
});
        
      };

      bidOnrenderForExcel = (item)=>{
        if(item['game_type'] == 'Bothside FC'){
            const left_digit = [];
            const right_digit = [];
            item.left_digit_data !== null && item.left_digit_data.digits.map(data=> left_digit.push(` ${data}`))
            item.right_digit_data !== null && item.right_digit_data.digits.map(data=> right_digit.push(` ${data}`))
            return `${left_digit.toString()} * ${right_digit.toString()}`;
        } else if(item['game_type'] == 'Same FC'){
            const digit = [];
            item.left_digit_data !== null && item.left_digit_data.digits.map(data=> digit.push(` ${data}`))
            item.right_digit_data !== null && item.right_digit_data.digits.map(data=> digit.push(` ${data}`))
            return `${digit.toString()}`;
        } else if(item['game_type'] === 'Direct FC'){
            return `${item.left_digit} * ${item.right_digit}`
        } else{
            const bidOnData = [];
            for (var i=0; i < 100; i++) {
            if(item[`field_${i}`] !== null && item[`field_${i}`] !== undefined){
                bidOnData.push(` ${i} = ₹ ${item[`field_${i}`]}`)
            }}
            return bidOnData.toString();
        }
      }

      sortedDownloadExcel = (gameName) => {
        const exportData = [];
        this.state.all_histories.map((item, i) => item['game_name'] === gameName && moment(item['played_at']).format('DD-MM-YYYY') === moment(new Date()).format('DD-MM-YYYY') && exportData.push({
          id: i+1,
          Name: item['name'],
          ContactNumber: item['phone'],
        //   Email: item['email'],
          GameName: item['game_name'],
          GameType: item['game_type'],
          BidOn: this.bidOnrenderForExcel(item),
          Amount: item['amount'] === undefined ? `₹ ${this.teerAmountrender(item)}` : `₹ ${item['amount']}`,
          Total: item['game_type'] === 'Direct FC' ? `₹ ${item['amount']}`: `₹ ${item['total']}`,
          Date: item['played_at'],
        }));

        const worksheet = XLSX.utils.json_to_sheet(exportData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, `${gameName}_game_${moment(new Date()).format('DD-MM-YYYY')}.xlsx`);
      };



      teerDownloadExcel = (gameName) => {
        const exportData = [];
        console.log("fieldArray");
        this.state.all_histories.map((item, i) => {
            if(item['game_name'] === gameName && moment(item['played_at']).format('DD-MM-YYYY') === moment(new Date()).format('DD-MM-YYYY')) { 
                const fieldArray=[]
                for (let i=0; i < 100; i++) {
                    const value = (item[`field_${i}`] !== null && item[`field_${i}`] !== undefined) ? String(item[`field_${i}`]) : String(0)  
                        fieldArray.push({[`field_${i}`]: value})
                    }
                    const convertObj = fieldArray.reduce(function(result, item) {
                        var key = Object.keys(item)[0];
                        result[key] = item[key];
                        return result;
                      }, {});
                    exportData.push(convertObj);
            }});
            
            const excelData = []

            for (let i=0; i < 100; i++) {

                const formattedI = i < 10 ? `0${i}` : `${i}`;

                let sum = exportData.reduce(function(prev, current) {
                return prev + +current[`field_${i}`]
              }, 0);
              
              
              excelData.push({
                date: moment(new Date()).format('DD-MM-YYYY'),
                [gameName] : formattedI,
                Amount: sum
              })
            }

            console.log("exportData",excelData);

        
        const worksheet = XLSX.utils.json_to_sheet(excelData);
        
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, `${gameName}_game_${moment(new Date()).format('DD-MM-YYYY')}.xlsx`);
      };




      teerAmountrender = (item)=>{
            const amountData = [];
            for (var i=0; i < 100; i++) {
            if(item[`field_${i}`] !== null && item[`field_${i}`] !== undefined){
                amountData.push(Number(item[`field_${i}`]))
            }}
            return amountData.reduce((a,v) =>  a = a + v , 0 );
      }
    
    render() {
        console.log(this.props.clients)
        return <>
            <div className="flex flex-col">
                <div className='py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8' style={{display: 'flex', justifyContent:'space-between'}}>
                        <Button className={"h-12 rounded-md"} variant="contained"
                         style={{ backgroundColor: '#ED7D31', color: 'white' }}
                                color="primary" onClick={() => this.sortedDownloadExcel('KHANAPARA FC')}>
                            KHANAPARA FC
                        </Button>
                        <Button className={"h-12 rounded-md"} variant="contained"
                              style={{ backgroundColor: '#1B9C85', color: 'white' }}
                                color="primary" onClick={() => this.sortedDownloadExcel('SHILLONG FC')}>
                            SHILLONG FC
                        </Button>
                        <Button className={"h-12 rounded-md"} variant="contained"
                         style={{ backgroundColor: '#7F5283', color: 'white' }}
                                color="primary" onClick={() => this.teerDownloadExcel('KHANAPARA TEER - FR')}>
                            KHANAPARA-TEER FR
                        </Button>
                        <Button className={"h-12 rounded-md"} variant="contained"
                         style={{ backgroundColor: '#D25380', color: 'white' }}
                                color="primary" onClick={() => this.teerDownloadExcel('KHANAPARA TEER - SR')}>
                            KHANAPARA-TEER SR
                        </Button>
                        <Button className={"h-12 rounded-md"} variant="contained"
                         style={{ backgroundColor: '#1687A7', color: 'white' }}
                                color="primary" onClick={() => this.teerDownloadExcel('SHILLONG TEER - FR')}>
                            SHILLONG-TEER FR
                        </Button>
                        <Button className={"h-12 rounded-md"} variant="contained"
                         style={{ backgroundColor: '#11468F', color: 'white' }}
                                color="primary" onClick={() => this.teerDownloadExcel('SHILLONG TEER - SR')}>
                            SHILLONG-TEER SR
                        </Button>
                        <Button className={"h-12 rounded-md"} variant="contained"
                         style={{ backgroundColor: '#272727', color: 'white' }}
                                color="primary" onClick={() => this.downloadExcel()}>
                            Save As CSV
                        </Button>
                </div>
                <Table>
                    <TableHeader>
                        <TableHeading>#</TableHeading>
                        <TableHeading>Name</TableHeading>
                        <TableHeading>Contact Number</TableHeading>
                        <TableHeading>Game Name</TableHeading>
                        <TableHeading>Game Type</TableHeading>
                        <TableHeading>Bid On</TableHeading>
                        <TableHeading>Amount</TableHeading>
                        <TableHeading>Total</TableHeading>
                        <TableHeading>Date</TableHeading>
                    </TableHeader>
                    <TableBody>
                        {(this.state.all_histories || []).map((d, index) => (moment(d['played_at']).format('DD-MM-YYYY') === moment(new Date()).format('DD-MM-YYYY')) && ( <tr key={d.id}>
                            <TableIndexColumn clickHandler={() => {}} num={index + 1} />
                            <TableTextualColumn text={d['name']} />
                            <TableGreenishTextualColumn text={d['phone']} clickListener={() => window.open("https://wa.me/"+d['phone'],"_blank")} />
                            <TableTextualColumn text={d['game_name']} />
                            <TableTextualColumn text={d['game_type']} />
                            <TableTextualColumn text={this.bidOnrenderForExcel(d)} />
                            <TableTextualColumn text={d['amount'] === undefined ? `₹ ${this.teerAmountrender(d)}` : `₹ ${d['amount']}`} />
                            <TableTextualColumn text={d['game_type'] === 'Direct FC' ? `₹ ${d['amount']}`: (d['total'] === undefined ? `₹ ${this.teerAmountrender(d)}` : `₹ ${d['total']}`)} />
                            <TableTextualColumn text={d['played_at']} />
                        </tr>))}
                    </TableBody>
                </Table>
            </div>
            </>
    }
}

const mapStateToProps = rootReducer => ({
    clients: rootReducer.clients.currentClients
});

const mapDispatchToProps = dispatch => ({
    actionSetCurrentClients: clients => dispatch(actionSetCurrentClients(clients))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PlayHistoryFragment));
