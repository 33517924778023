import React from "react";
import './fragment_cities_styles.scss';
import {Switch} from "@material-ui/core";
import {Add} from "@material-ui/icons";

import axios from 'axios';
import {createApiServerRoute} from "../../../models/network/api_client";

import {connect} from 'react-redux';
import {actionSetCurrentCities} from "../../../models/redux/reducers/activity_main_reducers/cities_reducer/action_cities";
import FloatingActionButton from "../../../components/floating_action_btn/FloatingActionButton";
import {withRouter} from "react-router-dom";
import {ACTIVITY_TAG, FRAGMENT_TAG} from "../../../utils/constants";
import TableIndexColumn from "../../../components/table_component/table_index_column/table_index_column";
import TableGreenishTextualColumn from "../../../components/table_component/table_greenish_textual_column/table_greenish_textual_column";
import TableContainerColumn from "../../../components/table_component/table_container_column/table_container_column";
import TableTextualColumn from "../../../components/table_component/table_textual_column/table_textual_column";
import Table from "../../../components/table_component/table/table";
import TableHeader from "../../../components/table_component/table_header/table_header";
import TableHeading from "../../../components/table_component/table_heading/table_heading";
import TableBody from "../../../components/table_component/table_body/table_body";
import UrlArgsBundle from "../../../core/url_args_bundle";

class CitiesFragment extends React.Component {

    toggleCity = (cityId) => {
        this.props.actionSetCurrentCities(this.props.cities.map(city => ({
            ...city,
            enableStatus: city.id === cityId ? 'TOGGLING' : city.enableStatus
        })))

        const params = new URLSearchParams();
        params.append('city_id', cityId);

        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('toggle_city_enable_disable.php'), params, config)
            .then(res => {
                console.log(res.data);
                this.props.actionSetCurrentCities(this.props.cities.map(city => ({
                    ...city,
                    enableStatus: city.id === cityId ? 'TOGGLED' : city.enableStatus,
                    enabled: city.id === cityId ? res.data.data.city.enabled : city.enabled
                })));
            });
    }

    componentDidMount() {
        const params = new URLSearchParams();
        params.append('which_ones', 'ALL');

        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('fetch_cities.php'), params, config)
            .then(res => {
                this.props.actionSetCurrentCities(res.data.data.cities.map(city => ({
                    ...city,
                    enableStatus: 'IDLE'
                })));
            });
    }

    getAddCityFragment = () => {
        const urlArgsBundle = new UrlArgsBundle();
        urlArgsBundle.setActivityTag(ACTIVITY_TAG.MAIN).setFragmentTag(FRAGMENT_TAG.ADD_CITY);
        return urlArgsBundle.getArgsAsUrlParams()
    }

    render() {
        return <>
            <div className="flex flex-col">
                <Table>
                    <TableHeader>
                        <TableHeading>#</TableHeading>
                        <TableHeading>Name</TableHeading>
                        <TableHeading>Enabled</TableHeading>
                        <TableHeading>Created At</TableHeading>
                    </TableHeader>
                    <TableBody>
                        {this.props.cities.map((city, index) => <tr key={city.id}>
                            <TableIndexColumn
                                clickHandler={() => this.props.history.push(`/dashboard/update_city/${city.id}`)}
                                num={index + 1} />
                            <TableGreenishTextualColumn text={city.name} />
                            <TableContainerColumn>
                                <Switch onChange={() => {
                                    this.toggleCity(city.id)
                                }} color='primary' checked={city.enabled} disabled={city.enableStatus === 'TOGGLING'}/>
                            </TableContainerColumn>
                            <TableTextualColumn text={city.created_at} />
                        </tr>)}
                    </TableBody>
                </Table>
            </div>
            <FloatingActionButton
                clickHandler={() => {
                    console.log(this.props.history)
                    this.props.history.push(this.getAddCityFragment())
                }}
                icon={<Add/>}/>
        </>

    }
}

const mapStateToProps = rootReducer => ({
    cities: rootReducer.cities.currentCities
});

const mapDispatchToProps = dispatch => ({
    actionSetCurrentCities: cities => dispatch(actionSetCurrentCities(cities))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CitiesFragment));