import React from "react";
import UrlArgsBundle from "../../../core/url_args_bundle";
import {ACTIVITY_TAG, API_KEY, FRAGMENT_TAG} from "../../../utils/constants";
import {withRouter} from "react-router-dom";
import {DatePicker} from "antd";
import {Button, TextField} from "@material-ui/core";
import axios from "axios";
import {createApiServerRoute} from "../../../models/network/api_client";

class AddDesawarGameChartFragment extends React.Component {

    urlArgsBundle = (new UrlArgsBundle()).prepareFrom(this.props.location.search);

    getFragment = (fragmentTag) => {
        this.urlArgsBundle.setActivityTag(ACTIVITY_TAG.MAIN).setFragmentTag(fragmentTag);
        this.props.history.push(this.urlArgsBundle.getArgsAsUrlParams())
    }

    constructor(props) {
        super(props);
        this.state = {
            date: "",
            open_digit: "",
            close_digit: "",
        }
    }

    addChart = () => {
        if (this.state.name !== "" || this.state.open_digit !== "" || this.state.close_digit !== "") {
            const params = new URLSearchParams();

            params.append("__api_key__", API_KEY)
            params.append("date", this.state.date)
            params.append("open_digit", this.state.open_digit)
            params.append("close_digit", this.state.close_digit)
            params.append("game_uid", this.urlArgsBundle.getStringExtra('game_uid'))

            const config = {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                }
            };

            axios.post(createApiServerRoute('add_desawar_game_chart.php'), params, config)
                .then(res => {

                    console.log(res)
                    if (res.data.state === 'OK') {
                        this.getFragment(FRAGMENT_TAG.DESAWAR_GAME_CHART)
                    } else {
                        console.log("result NOT OK")
                    }
                })
        }
    }

    render() {
        return (
            <div className={"py-36 ba-gray-200 grid place-items-center"}>
                <div className="form-card w-96 bg-white py-6 px-10 rounded-2xl shadow-2xl flex flex-col gap-6">
                    <h1 className={"text-center text-indigo-800 text-2xl pb-3"}>Add Desawar Game Chart</h1>
                    <DatePicker
                        className={"h-14 rounded-md"}
                        format={"YYYY-MM-DD"}
                        placeholder={'Date'}
                        onChange={(time, timeString) => {
                            this.setState({
                                ...this.state,
                                date : timeString
                            })
                    }} />
                    <TextField fullWidth id="standard-basic" label="Open Digit" value={this.state.open_digit} variant="outlined"
                               onChange={(e) => {
                                   if (e.target.value.length < 2) {
                                       this.setState({...this.state, open_digit: e.target.value})
                                   }
                               }}/>
                    <TextField fullWidth id="standard-basic" label="Close Digit" value={this.state.close_digit} variant="outlined"
                               onChange={(e) => {
                                   if (e.target.value.length < 2) {
                                       this.setState({...this.state , close_digit : e.target.value})
                                   }
                               }}/>
                    <Button className={"h-12 rounded-md"} variant="contained" color="primary" onClick={this.addChart}>Add</Button>

                </div>
            </div>
        );
    }
}

export default withRouter(AddDesawarGameChartFragment);