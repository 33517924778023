export const SET_CURRENT_ADD_CITY_NAME = 'SET_CURRENT_ADD_CITY_NAME';
export const SET_CURRENT_ADD_CITY_ENABLED = 'SET_CURRENT_ADD_CITY_ENABLED';

export const actionSetCurrentAddCityName = name => ({
    type: SET_CURRENT_ADD_CITY_NAME,
    payload: name
})
export const actionSetCurrentAddCityEnabled = enabled => ({
    type: SET_CURRENT_ADD_CITY_ENABLED,
    payload: enabled
})
