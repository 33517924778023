import { SET_ADMIN_SESSION } from "./action_admin_session";

const INITIAL_STATE = {
    currentAdmin: null
}

const adminSessionReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_ADMIN_SESSION:
            return {
                ...state,
                currentAdmin: action.payload
            }
        default:
            return state;
    }
}

export default adminSessionReducer;
