import { SET_CURRENT_CLIENTS } from "./action_clients";

const INITIAL_STATE = {
    currentClients: []
}

const clientsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_CURRENT_CLIENTS:
            return {
                ...state,
                currentClients: action.payload
            }
        default:
            return state;
    }
}

export default clientsReducer;
