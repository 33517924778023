import React from "react";
import styled from "styled-components";
import {FiUploadCloud} from "react-icons/fi";
import { Button } from "@material-ui/core";
import axios from "axios";
import {createApiServerRoute} from "../../../models/network/api_client";
import {API_KEY} from "../../../utils/constants";

export const AvatarInput = styled.div`
  margin-bottom: 32px;
  position: relative;
  align-self: center;
  img {
    width: 186px;
    height: 186px;
    object-fit: cover;
    border-radius: 50%;
  }
  .circle {
    width: 186px;
    height: 186px;
    border-radius: 50%;
  }
  label {
    right: 23em !important;
    position: absolute;
    width: 48px;
    height: 48px;
    background: #312e38;
    border-radius: 50%;
    right: 0;
    bottom: 0;
    border: 0;
    cursor: pointer;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    input {
      display: none;
    }
    svg {
      width: 20px;
      height: 20px;
      color: #f4ede8;
    }
    &:hover {
      background: blue;
    }
  }
`;

class SliderImagesFragment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            url : "",
            file : null,
            images : null
        }
    }

    handleFiles = (e) => {
        console.log(e)
        this.setState({
            ...this.state,
            url : URL.createObjectURL(e.target.files[0]),
            file: e.target.files[0]
        })
    };

    uploadImage = () => {
        const params = new FormData();
        params.append('__api_key__', API_KEY);
        params.append('image', this.state.file, this.state.file.name);
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('add_slider_image.php'), params, config)
            .then(res => {
                console.log(res)
                if(res.data.state === 'OK'){
                    window.location.reload()
                }
            });
    }

    deleteSliderImage = (uid) => {
        const params = new URLSearchParams();
        params.append('__api_key__', API_KEY);
        params.append('slider_image_uid', uid);
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('delete_slider_image.php'), params, config)
            .then(res => {
                console.log(res)
                if(res.data.state === 'OK'){
                    this.setState({
                        ...this.state,
                        images : this.state.images.filter(i => i.uid !== uid)
                    })
                    window.location.reload()
                }
            });
    }

    componentDidMount() {
        const params = new URLSearchParams();
        params.append('__api_key__', API_KEY);
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('fetch_slider_images.php'), params, config)
            .then(res => {
                console.log(res)
                if(res.data.state === 'OK'){
                    this.setState({
                        ...this.state,
                        images : res.data.data.images
                    })
                }
            });
    }

    render() {
        return (
            <div className={"flex items-center divider-y flex-col"}>
                <div className={"flex justify-center items-center gap-20 w-1/2 h-48"}>
                    {
                        this.state.file === null ? null : <AvatarInput>
                            <img src={this.state.url} alt="Avatar Placeholder" />
                        </AvatarInput>
                    }

                    {
                        this.state.file !== null ? <div className={"flex flex-col gap-8"}>
                            <Button variant="contained" color={"primary"} onClick={this.uploadImage}>Confirm</Button>
                            <Button variant="contained" color={"secondary"} onClick={() => {
                                this.setState({
                                    ...this.state,
                                    url: "",
                                    file: null
                                })
                            }
                            }>Cancel</Button>
                            </div>
                            :
                            <div className={"relative"}>
                                <input type="file" className={"absolute top-0 left-0 right-0 bottom-0 opacity-0"} onChange={(e) => this.handleFiles(e)}/>
                                <FiUploadCloud style={{width: 80, height: 80, color: '#1C3FAA', cursor: "pointer"}}
                                               as={Button}/>
                                <p>Upload Image</p>
                            </div>

                    }
                </div>
                <div>
                    <h1 className={"text-3xl text-indigo-800"}>Uploaded Slider Images</h1>
                    <div>
                        {
                            (this.state.images === null ? [] : this.state.images).map(i => {
                                return <>
                                    <img src={i.url} alt="img" width={"300px"} height={"300px"}/>
                                    <Button variant="contained" color={"secondary"} onClick={() => this.deleteSliderImage(i.uid)}>Delete</Button>
                                </>
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default SliderImagesFragment
