import React from 'react';
import './withdraw_styles.scss';
import {Button, ButtonGroup, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from "@material-ui/core";
import axios from 'axios';
import { createApiServerRoute } from "../../../models/network/api_client";
import { connect } from 'react-redux';
import { actionSetCurrentClients } from "../../../models/redux/reducers/activity_main_reducers/clients_reducer/action_clients";
import Table from "../../../components/table_component/table/table";
import TableHeader from "../../../components/table_component/table_header/table_header";
import TableHeading from "../../../components/table_component/table_heading/table_heading";
import {API_KEY} from "../../../utils/constants";
import {withRouter} from "react-router-dom";
import TableIndexColumn from "../../../components/table_component/table_index_column/table_index_column";
import TableUserNameColumn from "../../../components/table_component/table_user_name_column/table_user_name_column";
import TableGreenishTextualColumn
    from "../../../components/table_component/table_greenish_textual_column/table_greenish_textual_column";
import TableTextualColumn from "../../../components/table_component/table_textual_column/table_textual_column";
import TableContainerColumn from "../../../components/table_component/table_container_column/table_container_column";
import TableBody from "../../../components/table_component/table_body/table_body";
import TableBankColumn from "../../../components/table_component/table_bank_column/table_bank_column";

class UsersFragment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: "PENDING",
            requests: null,
            pending: null,
            cancelled: null,
            approved: null,
            filtered: null
        }
    }

    componentDidMount() {
        const params = new URLSearchParams();
        params.append("__api_key__", API_KEY)
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('fetch_withdraw_request.php'), params, config)
            .then(res => {
                if (res.data.state === 'OK') {

                    this.setState({
                        ...this.state,
                        requests : res.data.data['withdraw_requests_meta'],
                        filtered: res.data.data['withdraw_requests_meta'].filter(r => r['state'] === this.state.type),
                        pending: res.data.data['withdraw_requests_meta'].filter(r => r['state'] === 'PENDING').sort((b, a) => new Date(a.created_at) - new Date(b.created_at)),
                        approved: res.data.data['withdraw_requests_meta'].filter(r => r['state'] === 'APPROVE').sort((b, a) => new Date(a.created_at) - new Date(b.created_at)),
                        cancelled: res.data.data['withdraw_requests_meta'].filter(r => r['state'] === 'CANCEL').sort((b, a) => new Date(a.created_at) - new Date(b.created_at))
                    })
                }
            });
    }

    ApproveRequest = (withdrawRequestUid) => {
        const params = new URLSearchParams();
        params.append("__api_key__", API_KEY)
        params.append("withdraw_request_uid", withdrawRequestUid)
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('approve_user_withdraw_request.php'), params, config)
            .then(res => {
                console.log(res)
                if (res.data.state === 'OK' && res.data.data.approved) {
                    this.setState({
                        ...this.state,
                        filtered : this.state.filtered.filter(f => f['uid'] !== withdrawRequestUid),
                        pending : this.state.pending.filter(f => f['uid'] !== withdrawRequestUid),
                        approved: [...this.state.approved , this.state.filtered.filter(f => f['uid'] !== withdrawRequestUid)[0]]
                    })
                }
            });
    }

    CancelRequest = (withdrawRequestUid) => {
        const params = new URLSearchParams();
        params.append("__api_key__", API_KEY)
        params.append("withdraw_request_uid", withdrawRequestUid)
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('cancel_user_withdraw_request.php'), params, config)
            .then(res => {
                console.log(res)
                if (res.data.state === 'OK' && res.data.data.cancelled) {
                    this.setState({
                        ...this.state,
                        filtered : this.state.filtered.filter(f => f['uid'] !== withdrawRequestUid),
                        pending : this.state.pending.filter(f => f['uid'] !== withdrawRequestUid),
                        cancelled: [...this.state.cancelled , this.state.filtered.filter(f => f['uid'] !== withdrawRequestUid)[0]]
                    })
                }
            });
    }



    render() {
        let user = JSON.parse(localStorage.getItem('user'))
        return <>
            <center>
                <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">Withdraw Request Type</FormLabel>
                    <RadioGroup
                        defaultValue="PENDING"
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        onChange={(e) => {
                            this.setState({
                                ...this.state,
                                type: e.target.value,
                                filtered: (e.target.value === 'PENDING' ? this.state.pending : (e.target.value === 'CANCEL' ? this.state.cancelled : this.state.approved)).map(v => v)
                            })
                        }}
                    >
                        <FormControlLabel value="PENDING" control={<Radio />} label="Pending" />
                        <FormControlLabel value="CANCEL" control={<Radio />} label="Cancel" />
                        <FormControlLabel value="APPROVE" control={<Radio />} label="Approve" />
                    </RadioGroup>
                </FormControl>
            </center>
            <div className="flex flex-col">
                <input type="text" value={this.state.filter}
                onChange={e => {this.setState({...this.state, filter: e.target.value})}}
                className="mx-8 px-8 py-2 rounded-2xl mb-6 placeholder-indigo-600 outline-none placeholder-opacity-50"  placeholder="Enter the number..."/>
                <Table>
                    <TableHeader>
                        <TableHeading>#</TableHeading>
                        <TableHeading>Username</TableHeading>
                        <TableHeading>Phone</TableHeading>
                        <TableHeading>Amount</TableHeading>
                        <TableHeading>Account Details</TableHeading>
                        <TableHeading>Date</TableHeading>
                        {this.state.type === "PENDING" ? (user['type'] === 'MAIN' ? <TableHeading>Status</TableHeading> : '') : ''}
                    </TableHeader>
                    <TableBody>
                        {(this.state.filtered || []).map((f, index) => <tr key={f['uid']}>
                            <TableIndexColumn clickHandler={() => {}} num={index + 1} />
                            <TableUserNameColumn
                                clickHandler={() => {}}
                                avatarUrl={f['avatar']} firstName={f['name']} lastName={''}
                                email={f['email']} />
                            <TableGreenishTextualColumn text={f['phone']} clickListener={() => window.open("https://wa.me/"+f['phone'],"_blank")} />
                            <TableTextualColumn text={f['amount']} />
                            <TableBankColumn upperName={f['bank_name']} lowerName={f['bank_account_number']} clickHandler={() => {}} />
                            <TableTextualColumn text={f['created_at']} />
                            {this.state.type === "PENDING" ? (
                                user['type'] === "MAIN" ? <TableContainerColumn>
                                    <ButtonGroup
                                        orientation="vertical"
                                        aria-label="vertical contained button group"
                                        variant="contained">
                                        <Button key="one" color={"primary"} onClick={() => this.ApproveRequest(f['uid'])}>Approve</Button>
                                        <Button key="two" color={"secondary"} onClick={() => this.CancelRequest(f['uid'])}>Cancel</Button>
                                    </ButtonGroup>
                                </TableContainerColumn> : '')
                                : "" }
                        </tr>)}
                    </TableBody>
                </Table>
            </div>
            </>
    }
}

const mapStateToProps = rootReducer => ({
    clients: rootReducer.clients.currentClients
});

const mapDispatchToProps = dispatch => ({
    actionSetCurrentClients: clients => dispatch(actionSetCurrentClients(clients))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UsersFragment));
