import './fragment_payments_styles.scss';
import Table from "../../../components/table_component/table/table";
import TableHeader from "../../../components/table_component/table_header/table_header";
import TableHeading from "../../../components/table_component/table_heading/table_heading";
import TableBody from "../../../components/table_component/table_body/table_body";
import TableIndexColumn from "../../../components/table_component/table_index_column/table_index_column";
import TableUserNameColumn from "../../../components/table_component/table_user_name_column/table_user_name_column";
import TableTextualColumn from "../../../components/table_component/table_textual_column/table_textual_column";
import TableContainerColumn from "../../../components/table_component/table_container_column/table_container_column";
import React from "react";
import TableBankColumn from "../../../components/table_component/table_bank_column/table_bank_column"
import TableDateColumn from "../../../components/table_component/table_date_column/table_date_column"
import axios from "axios";
import {createApiServerRoute} from "../../../models/network/api_client";
import {actionSetPaidDrivers} from "../../../models/redux/reducers/activity_main_reducers/drivers_payment_reducer/action_set_paid_drivers";
import {actionSetUnpaidDrivers} from "../../../models/redux/reducers/activity_main_reducers/drivers_payment_reducer/action_set_unpaid_drivers";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

class PaymentsFragment extends React.Component {

    setPaidDriversIfAnyDifferent = paid_drivers => {
        for (let i = 0; i < paid_drivers.length; i++) {
            const my_new_payment = paid_drivers[i];
            let myOldItemData = this.props.paid_payments.paid_drivers.drivers.filter(p => p.id === my_new_payment.id)
            if (myOldItemData.length === 0 || myOldItemData[0]['payed'] !== my_new_payment['payed']) {
                this.props.actionSetPaidDrivers({
                    api_called: true,
                    drivers: paid_drivers
                })
                break;
            }
        }
    }

    setUnpaidDrivesIfAnyDifferent = unpaid_drivers => {
        for ( let i = 0 ; i < unpaid_drivers.length ; i++ ) {
            const myNewPayment = unpaid_drivers[i];
            let myOldPayment = this.props.unpaid_payments.unpaid_drivers.drivers.filter(p => p.id === myNewPayment.id) ;
            if ( myOldPayment.length === 0 || myOldPayment[0]['payed'] !== myNewPayment['payed'] ) {
                this.props.actionSetUnpaidDrivers({
                    api_called: true,
                    drivers : unpaid_drivers
                })
            }
        }
    }

    payPayment = (paymentID) => {
        const params = new URLSearchParams();
        params.append('payment_id' , paymentID);

        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('pay_payment.php') , params , config)
            .then(res => {
                setTimeout(() => {
                    this.props.actionSetUnpaidDrivers({
                        ...this.props.unpaid_drivers,
                        drivers: this.props.unpaid_payments.unpaid_drivers.drivers.filter(x => x.id !== paymentID)
                    })
                }, 30)
                this.props.actionSetPaidDrivers({
                    ...this.props.paid_payments,
                    drivers: [
                        ...this.props.paid_payments.paid_drivers.drivers,
                        this.props.unpaid_payments.unpaid_drivers.drivers.filter(x => x.id === paymentID).map(x => {
                            x.payed = true
                            return x
                        })[0]
                    ]
                })
            })
    }

    componentDidMount() {
        const params = new URLSearchParams();

        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('fetch_unpaid_payment.php'), params, config)
            .then(res => {
                if ( this.props.unpaid_payments.unpaid_drivers.drivers.length !== res.data.data.unpaid_payments.length ) {
                    this.props.actionSetUnpaidDrivers({
                        api_called:true,
                        drivers: res.data.data.unpaid_payments
                    })
                } else {
                    this.setUnpaidDrivesIfAnyDifferent(res.data.data.unpaid_payments)
                }
                console.log(res.data);
            });

        axios.post(createApiServerRoute('fetch_paid_payment.php'), params, config)
            .then(res => {
                if (this.props.paid_payments.paid_drivers.drivers.length !== res.data.data.paid_payments.length) {
                    this.props.actionSetPaidDrivers({
                        api_called: true,
                        drivers: res.data.data.paid_payments
                    })
                } else {
                    this.setPaidDriversIfAnyDifferent(res.data.data.paid_payments)
                }
                console.log(res.data);
            });
    }

    render() {

        let paidPayments = this.props.paid_payments.paid_drivers.drivers.map(v => v);
        let unpaidPayments = this.props.unpaid_payments.unpaid_drivers.drivers.map(v => v);

        console.log(paidPayments);
        console.log(unpaidPayments);

        let totalPaid = 0;

        for (let i = 0; i< paidPayments.length; i++) {
            totalPaid += parseInt(paidPayments[i]['amount']);
        }

        let totalUnpaid = 0;

        for (let i=0; i< unpaidPayments.length; i++){
            totalUnpaid += parseInt(unpaidPayments[i]['amount']);
        }

        console.log(`Total Paid: ${totalPaid}`);
        console.log(`Total Unpaid: ${totalUnpaid}`);

        return <div className="flex flex-col gap-20">

            <div className="flex flex gap-8 mx-8">
                <div className="flex-1 flex bg-blue-500 p-4 rounded-lg shadow-lg">
                    <div className="flex flex-col">
                        <h2 className={"text-white"}>TOTAL DRIVERS EARNING (15%)</h2>
                        <p className="text-white mt-2">{
                                (totalUnpaid * 15) / 100
                        }</p>
                    </div>
                </div>
                <div className="flex-1 flex bg-red-700 p-4 rounded-lg shadow-lg">
                    <div className="flex flex-col">
                        <h2 className={"text-white"}>UNPAID DRIVERS AMOUNT</h2>
                        <p className="text-white mt-2">{totalUnpaid}</p>
                    </div>
                </div>
                <div className="flex-1 flex bg-green-700 p-4 rounded-lg shadow-lg">
                    <div className="flex flex-col">
                        <h2 className={"text-white"}>PAID DRIVERS AMOUNT</h2>
                        <p className="text-white mt-2">{totalPaid}</p>
                    </div>
                </div>
            </div>

            <div>
                <h2 className="ml-8 text-indigo-600 text-xl text-dark">Unpaid Drivers</h2>
                <Table>
                    <TableHeader>
                        <TableHeading>#</TableHeading>
                        <TableHeading>Name</TableHeading>
                        <TableHeading>Date</TableHeading>
                        <TableHeading>Bank & Account</TableHeading>
                        <TableHeading>Amount</TableHeading>
                        <TableHeading>Action</TableHeading>
                    </TableHeader>
                    <TableBody>
                        {unpaidPayments.map((p,index) => <tr key={p.id}>
                            <TableIndexColumn
                                num={index+1}/>
                            <TableUserNameColumn
                                avatarUrl={p['driver_avatar']}
                                firstName={p['driver_first_name']}
                                lastName={p['driver_last_name']}
                                email={p['driver_email']}/>
                            <TableDateColumn date={p['created_at']}>
                            </TableDateColumn>
                            <TableBankColumn upperName={p['bank_name']} lowerName={p['account_no']}>
                            </TableBankColumn>
                            <TableTextualColumn text={p['amount']}/>
                            <TableContainerColumn>
                                <button
                                    className={`shadow ${ p['in_limit'] ? "bg-red-500 hover:bg-red-400" : "bg-green-500 hover:bg-green-400"} focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded`}
                                    type="button"
                                    disabled={p['in_limit']}
                                    onClick={() => this.payPayment(p['id'])}>
                                    {p['in_limit'] ? "Disabled" : "Pay" }
                                </button>
                            </TableContainerColumn>
                        </tr>
                        )}
                    </TableBody>
                </Table>
            </div>

            <div>
                <h2 className="ml-8 text-indigo-600 text-xl text-dark">Paid Drivers</h2>
                <Table>
                    <TableHeader>
                        <TableHeading>#</TableHeading>
                        <TableHeading>Name</TableHeading>
                        <TableHeading>Date</TableHeading>
                        <TableHeading>Bank & Account</TableHeading>
                        <TableHeading>Amount</TableHeading>
                        <TableHeading>Action</TableHeading>
                    </TableHeader>
                    <TableBody>
                        {paidPayments.map((p,index) => <tr key={p.id}>
                                <TableIndexColumn
                                    num={index+1}/>
                                <TableUserNameColumn
                                    avatarUrl={p['driver_avatar']}
                                    firstName={p['driver_first_name']}
                                    lastName={p['driver_last_name']}
                                    email={p['driver_email']}/>
                                <TableDateColumn date={p['created_at']}>
                                </TableDateColumn>
                                <TableBankColumn upperName={p['bank_name']} lowerName={p['account_no']}>
                                </TableBankColumn>
                                <TableTextualColumn text={p['amount']}/>
                                <TableTextualColumn text={"Payed"}/>
                            </tr>
                        )}
                    </TableBody>
                </Table>
            </div>
        </div>
    }
}


const mapStateToProps = root_reducer => ({
    paid_payments: root_reducer.paidDriversReducer,
    unpaid_payments: root_reducer.unpaidDriversReducer
});

const mapDispatchToProps = dispatch => ({
    actionSetPaidDrivers: paidDriversData => dispatch(actionSetPaidDrivers(paidDriversData)),
    actionSetUnpaidDrivers: unpaidDriversData => dispatch(actionSetUnpaidDrivers(unpaidDriversData))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PaymentsFragment));
