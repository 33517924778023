import React from "react";
import FloatingActionButton from "../../../components/floating_action_btn/FloatingActionButton";
import {ACTIVITY_TAG, API_KEY, FRAGMENT_TAG, GAME_TYPES} from "../../../utils/constants";
import { Add } from "@material-ui/icons";
import { withRouter } from "react-router-dom";
import UrlArgsBundle from "../../../core/url_args_bundle";
import axios from "axios";
import { createApiServerRoute } from "../../../models/network/api_client";

class StandardGameChartFragment extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            charts: []
        }
    }


    urlArgsBundle = (new UrlArgsBundle()).prepareFrom(this.props.location.search);
    changeFragment = (fragmentTag) => {
        this.urlArgsBundle.setActivityTag(ACTIVITY_TAG.MAIN).setFragmentTag(fragmentTag);
        return this.urlArgsBundle.getArgsAsUrlParams()
    }

    componentDidMount() {
        const params = new URLSearchParams();
        params.append("__api_key__", API_KEY)
        params.append("game_uid", this.urlArgsBundle.getStringExtra('game_uid'))
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('fetch_standard_game_chart.php'), params, config)
            .then(res => {
                if (res.data.state === 'OK') {
                    this.setState({
                        ...this.state,
                        charts: res.data.data.meta
                    })
                }
            });
    }

    deleteChart = (gameChartUid) => {
        if (gameChartUid !== null) {

            const params = new URLSearchParams();
            params.append("__api_key__", API_KEY)
            params.append("game_type", GAME_TYPES.STANDARD)
            params.append("game_chart_uid", gameChartUid)
            const config = {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                }
            };

            axios.post(createApiServerRoute('delete_game_chart.php'), params, config)
                .then(res => {
                    console.log(res);
                    if (res.data.state === 'OK' && res.data.data['deleted']) {
                        window.location.reload()
                    }
                });

        }
    }

    render() {

        let user = JSON.parse(localStorage.getItem('user'))

        return (
            <>
                <div className="p-16 grid  grid-cols-7 gap-4 ">
                    {
                        this.state.charts.map((c) => <div
                            className="cursor-pointer bg-white grid border-solid border-2 border-gray-600 pr-2 text-xs truncate w-20 lg:text-sm lg:w-36"
                            style={{ gridTemplateColumns: "auto auto auto", gridTemplateRows: "auto auto" }} onClick={() => {

                                this.urlArgsBundle.putExtra('uid' , c.uid)
                                this.urlArgsBundle.putExtra('game_uid' , this.urlArgsBundle.getStringExtra('game_uid'))
                                this.props.history.push(this.changeFragment(FRAGMENT_TAG.UPDATE_STANDARD_GAME_CHART))
                        }}>
                            <div className="col-span-3 border-b-2 border-solid border-gray-600 font-semibold text-center align-middleborder-b p-2 relative">
                                {c.date.split(' ')[0]}<br />
                                {c.date.split(' ')[1]}
                                {
                                    user === null ? '' : (user['type'] === 'MAIN' ? <span
                                    className={"absolute top-1 right-1 cursor-pointer"}
                                    style={{fontSize: "10px"}}
                                    onClick={() => this.deleteChart(c.uid)}
                                >
                                    &#10060;
                                </span> : '' )}
                            </div>
                            <div className="grid place-items-center border-r-2 border-gray-600 p-1" style={{
                                writingMode: "vertical-lr",
                                textOrientation: "upright"
                            }}>
                                {c.open_paana}
                            </div>
                            <div className="text-center align-middle p-2">
                                {c.open_digit + c.close_digit}
                            </div>
                            <div className="grid place-items-center border-gray-600 border-l-2 p-1" style={{
                                writingMode: "vertical-lr",
                                textOrientation: "upright"
                            }}>
                                {c.close_paana}
                            </div>
                        </div>)
                    }
                </div>
                {
                    user === null ? '' : (user['type'] === 'MAIN' ? <FloatingActionButton
                    clickHandler={() => this.props.history.push(this.changeFragment(FRAGMENT_TAG.ADD_STANDARD_GAME_CHART))}
                    icon={<Add />} /> : '' )}
            </>
        );
    }

}

export default withRouter(StandardGameChartFragment)