import React from "react";
import {Button, TextField} from "@material-ui/core";
import axios from "axios";
import { createApiServerRoute } from "../../models/network/api_client";
import { API_KEY } from "../../utils/constants";

class AddFund extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            input_amount:''

        };
    }

    handleAddFunds = ()=>{
        const params = new URLSearchParams();
        params.append("__api_key__", API_KEY)
        params.append("user_uid", this.props.userUid)
        params.append("amount", this.state.input_amount)

        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('add_funds_to_user_wallet.php'), params, config)
            .then(res => {
                if (res.data.state === 'OK') {
                    this.setState({input_amount:''})
                    this.getUsersByPage(this.props.searchText,this.props.currentPage);

                    if (this.props.refreshUsersFragment) {
                        this.props.refreshUsersFragment();
                    }
                }
            });
    }

    getUsersByPage = (pageNo,searchtext) => {
        const params = new URLSearchParams();
        params.append("__api_key__", API_KEY)
        params.append("searchtext", searchtext)
        params.append("page", pageNo)
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('fetch_user_with_search.php'), params, config)
            .then(res => {
                if (res.data.state === 'OK') {
                    this.props.actionSetCurrentClients(res.data.data.users.map(user => ({
                        ...user
                    })))
                    this.setState({ totalPageCount: res.data.data.totalPages });
                    console.log(res.data.data.totalPages)

                } else {
                    console.log(res)
                }
            });
    }

  

    render() {
        return <div style={{flexDirection: 'column'}}>
        <TextField fullWidth size="small" id="outlined-size-small" margin="normal" label="Enter Amount" style={{width: 125}} value={this.state.input_amount} variant="outlined" onChange={(e) => {
        const re = /^[-]?[0-9]*$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({...this.state , input_amount : e.target.value})
        }}}/>
    <Button size="small" className={"rounded-md whitespace-nowrap"} variant="contained" color="primary" style={{width: 125}} onClick={this.handleAddFunds} >
    ADD FUNDS
    </Button>
    </div>
    }
}

export default AddFund;
