import React from "react";
import UrlArgsBundle from "../../../core/url_args_bundle";
import {ACTIVITY_TAG, API_KEY, FRAGMENT_TAG} from "../../../utils/constants";
import {Button, TextField} from "@material-ui/core";
import {withRouter} from "react-router-dom";
import axios from "axios";
import {createApiServerRoute} from "../../../models/network/api_client";

class UpdateStandardGameChartFragment extends React.Component {

    urlArgsBundle = (new UrlArgsBundle()).prepareFrom(this.props.location.search);

    getFragment = (fragmentTag) => {
        this.urlArgsBundle.setActivityTag(ACTIVITY_TAG.MAIN).setFragmentTag(fragmentTag);
        this.props.history.push(this.urlArgsBundle.getArgsAsUrlParams())
    }

    constructor(props) {
        super(props);
        this.state = {
            close_digit: "",
            open_digit: "",
            open_panna: "",
            close_panna: ""
        }
    }

    componentDidMount() {
        const params = new URLSearchParams();
        params.append("__api_key__", API_KEY)
        params.append("game_uid", this.urlArgsBundle.getStringExtra('game_uid'))
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('fetch_standard_game_chart.php'), params, config)
            .then(res => {
                if (res.data.state === 'OK') {
                    res.data.data.meta.forEach(c => {
                        console.log(c.uid)
                        if (c.uid === this.urlArgsBundle.getStringExtra('uid')) {
                            console.log(c)
                            this.setState({
                                ...this.state,
                                close_digit : c.close_digit,
                                open_digit : c.open_digit,
                                open_panna : c.open_paana,
                                close_panna : c.close_paana,
                            })
                        }
                    })
                }
            });
    }

    updateChart = () => {
        if (this.state.close_digit !== "" || this.state.close_panna !== "") {
            const params = new URLSearchParams();

            params.append("__api_key__", API_KEY)
            params.append("standard_game_chart_uid", this.urlArgsBundle.getStringExtra('uid'))
            params.append("open_digit", this.state.open_digit)
            params.append("close_digit", this.state.close_digit)
            params.append("open_panna", this.state.open_panna)
            params.append("close_panna", this.state.close_panna)

            const config = {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                }
            };

            axios.post(createApiServerRoute('update_standard_game_chart.php'), params, config)
                .then(res => {

                    console.log(res)
                    if (res.data.state === 'OK' && res.data.data.updated) {
                        this.getFragment(FRAGMENT_TAG.STANDARD_GAME_CHART)
                    } else {
                        console.log("result NOT OK")
                    }
                })
        }
    }


    render() {
        return (
            <div className={"py-36 ba-gray-200 grid place-items-center"}>
                <div className="form-card w-96 bg-white py-6 px-10 rounded-2xl shadow-2xl flex flex-col gap-6">
                    <h1 className={"text-center text-indigo-800 text-2xl pb-3"}>Update Standard Game Chart</h1>
                    <TextField fullWidth id="standard-basic" label="Close Digit" value={this.state.close_digit} variant="outlined"
                               onChange={(e) => {
                                   if (e.target.value.length < 2) {
                                       this.setState({...this.state , close_digit : e.target.value})
                                   }
                               }}/>
                    <TextField fullWidth id="standard-basic" label="Open Panna" value={this.state.open_digit} variant="outlined"
                               onChange={(e) => {
                                   if (e.target.value.length < 4) {
                                       this.setState({...this.state , open_digit : e.target.value})
                                   }
                               }}/>
                    <TextField fullWidth id="standard-basic" label="Open Digit" value={this.state.open_panna} variant="outlined"
                               onChange={(e) => {
                                   if (e.target.value.length < 2) {
                                       this.setState({...this.state , open_panna : e.target.value})
                                   }
                               }}/>
                    <TextField fullWidth id="standard-basic" label="Close Panna" value={this.state.close_panna} variant="outlined"
                               onChange={(e) => {
                                   if (e.target.value.length < 4) {
                                       this.setState({...this.state , close_panna : e.target.value})
                                   }
                               }}/>
                    <Button className={"h-12 rounded-md"} variant="contained" color="primary" onClick={this.updateChart}>Update</Button>
                </div>
            </div>);
    }
}

export default withRouter(UpdateStandardGameChartFragment);