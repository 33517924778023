import './fragment_reviews_styles.scss';
import React from "react";
import ReviewCard from "../../../components/review_card_component/review_card";
import {actionSetCurrentReviews} from "../../../models/redux/reducers/activity_main_reducers/reviews_reducer/action_reviews";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import axios from "axios";
import {createApiServerRoute} from "../../../models/network/api_client";

class ReviewsFragment extends React.Component {

    componentDidMount() {
        const params = new URLSearchParams();

        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('get_all_reviews.php'), params, config)
            .then(res => {
                console.log(res.data.data);
                this.props.actionSetCurrentReviews(res.data.data.reviews);
            });
    }

    render() {

        console.log(this.props.currentReviews)

        return <>
            <div className="review-cards">
                {
                    this.props.currentReviews.map(r => {
                        return <ReviewCard key={r.id}
                                           avatarURL={r.url}
                                           pFirstName={r.passenger_first_name}
                                           pLastName={r.passenger_last_name}
                                           dFirstName={r.driver_first_name}
                                           dLastName={r.driver_last_name}
                                           message={r.review}
                                           star={r.rating}
                                           date={r.created_at}/>
                    })
                }
            </div>
        </>
    }

}

const mapStateToProps = rootReducer => ({
    currentReviews: rootReducer.reviewsReducer.currentReviews
});

const mapDispatchToProps = dispatch => ({
    actionSetCurrentReviews: reviews => dispatch(actionSetCurrentReviews(reviews))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ReviewsFragment));

