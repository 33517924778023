import {SET_CANCELLED_TRIPS} from './action_set_cancelled_trips';

const INITIAL_STATE = {
    cancelledTrips: []
}

const setCancelledTripsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_CANCELLED_TRIPS:
            return {
                ...state,
                cancelledTrips: action.payload
            }
        default:
            return state;
    }
}

export default setCancelledTripsReducer;
