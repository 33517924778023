import React from 'react';
import axios from 'axios';
import "./freeKilometerFragment.scss"
import { createApiServerRoute } from "../../../models/network/api_client";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import {actionUpdateFreeDistance} from "../../../models/redux/reducers/activity_main_reducers/update_free_distance_reducer/action_update_free_distance";

class FreeKilometersFragment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show_alert : false
        }
    }


    componentDidMount() {
        const params = new URLSearchParams();

        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('get_free_distance_detail.php'), params, config)
            .then(res => {
                console.log(res.data);
                if (res.data['cab_5_response_state'] === 'OK') {
                    const distanceInMeters = res.data.data['distance_in_meters'];

                    this.props.actionUpdateFreeDistance({
                        freeDistanceInput: distanceInMeters / 1000,
                        freeDistance: distanceInMeters,
                        fetching: false,
                        updating: this.props.updateFreeDistanceReducer.updating
                    })
                }
            });
    }

    handleInput = (event) => {
        this.props.actionUpdateFreeDistance({
            freeDistanceInput: event.target.value,
            freeDistance: this.props.updateFreeDistanceReducer.freeDistance,
            fetching: this.props.updateFreeDistanceReducer.fetching,
            updating: this.props.updateFreeDistanceReducer.updating
        })
    }

    setNewDistance = () => {

        this.setState({
            ...this.state,
            show_alert: !this.state.show_alert
        })

        const params = new URLSearchParams();
        const newDistance = parseInt(this.props.updateFreeDistanceReducer.freeDistanceInput) * 1000;
        params.append('new_free_distance', newDistance.toString());

        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        this.props.actionUpdateFreeDistance({
            freeDistanceInput: this.props.updateFreeDistanceReducer.freeDistanceInput,
            freeDistance: this.props.updateFreeDistanceReducer.freeDistance,
            fetching: this.props.updateFreeDistanceReducer.fetching,
            updating: true
        })

        axios.post(createApiServerRoute('set_new_free_distance.php'), params, config)
            .then(res => {
                console.log(res.data);
                if(res.data['cab_5_response_state'] === 'OK'){
                    this.props.actionUpdateFreeDistance({
                        freeDistanceInput: this.props.updateFreeDistanceReducer.freeDistanceInput,
                        freeDistance: this.props.updateFreeDistanceReducer.freeDistance,
                        fetching: this.props.updateFreeDistanceReducer.fetching,
                        updating: false
                    })
                    console.log("Updated!");
                }
            });
    }

    render() {

        return <>

            {
                this.state.show_alert && <div className={"alert-container "}>
                    <div role="alert" className={"alert"}>
                        <div className="bg-red-500 text-white font-bold rounded-t px-4 py-2">
                            Danger
                        </div>
                        <div className="border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700">
                            <p>Something not ideal might be happening.</p>
                            <div className={"buttons_container my-4"}>
                                <button className={"bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"} onClick={this.setNewDistance}>
                                    Yes
                                </button>
                                <button className={"bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"}
                                        onClick={() => this.setState({...this.state , show_alert: !this.state.show_alert})}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }

        <div className="flex flex-column justify-center items-center md:mt-44">

            <div className="w-full max-w-lg">
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full px-3  mb-6 md:mb-0 grid-cols-2 text-center">
                        <label className="block uppercase tracking-wide text-gray-700 text-md font-bold mb-2" htmlFor="grid-first-name">
                            Free Kilometers
                        </label>
                        <input
                            className="mt-8 appearance-none block w-3/5 mx-auto bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            id="grid-first-name"
                            type="number"
                            placeholder="Enter Free Kilometers"
                            required={true}
                            disabled={this.props.updateFreeDistanceReducer.fetching || this.props.updateFreeDistanceReducer.updating}
                            value={this.props.updateFreeDistanceReducer.freeDistanceInput}
                            onChange={this.handleInput}/>
                    </div>
                </div>
                <div className="md:flex md:items-center md:ml-12">
                    <div className="md:w-1/3" />
                    <div className="md:w-2/3">
                        <button
                            className="shadow bg-blue-500 hover:bg-blue-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                            disabled={this.props.updateFreeDistanceReducer.fetching || this.props.updateFreeDistanceReducer.updating }
                            type="submit"
                            onClick={() => this.setState({...this.state , show_alert: !this.state.show_alert})}>
                            Update
                        </button>
                    </div>
                </div>
            </div>
        </div>
        </>
    }
}

const mapStateToProps = rootReducer => ({
    updateFreeDistanceReducer: rootReducer.updateFreeDistanceReducer,
});

const mapDispatchToProps = dispatch => ({
    actionUpdateFreeDistance: payload => dispatch(actionUpdateFreeDistance(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FreeKilometersFragment));