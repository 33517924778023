import React from 'react';
import './add_city_fragment_styles.scss';

import axios from 'axios';
import { createApiServerRoute } from "../../../models/network/api_client";

import { connect } from 'react-redux';
import {actionSetCurrentAddCityName} from "../../../models/redux/reducers/activity_main_reducers/add_city_reducer/action_add_city";
import {actionSetCurrentAddCityEnabled} from "../../../models/redux/reducers/activity_main_reducers/add_city_reducer/action_add_city";
import { withRouter } from "react-router-dom";

class AddCityFragment extends React.Component {

    addCity = (event) => {
        event.preventDefault();

        const params = new URLSearchParams();
        params.append('city_name', this.props.city_name);
        params.append('enabled', this.props.city_enabled);
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('add_city.php'), params, config)
            .then(res => {
                if(res.data.cab_5_response_state === 'OK'){
                    this.props.history.goBack();
                }
            });
    }

    render() {
        return <div className="flex flex-column justify-center items-center md:mt-44">
            <form className="w-full max-w-lg" onSubmit={this.addCity}>
            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 grid-cols-2">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                           htmlFor="grid-first-name">
                        City Name
                    </label>
                    <input
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                        id="grid-first-name" type="text" placeholder="Islamabad" required={true} onChange={(event) =>{
                        this.props.actionSetCurrentAddCityName(event.target.value)
                        }
                    }/>
                </div>
                <div className="w-full md:w-1/2 px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 grid-cols-2"
                               htmlFor="grid-state">
                            Enable/Disable
                        </label>
                        <div className="relative">
                            <select
                                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="grid-state" onChange={(event) =>{
                                this.props.actionSetCurrentAddCityEnabled(event.target.value)
                            }
                            }>
                                <option value='Y' selected>Enable</option>
                                <option value='N'>Disable</option>
                            </select>
                            <div
                                className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 20 20">
                                    <path
                                        d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                                </svg>
                            </div>
                        </div>
                </div>
            </div>
            <div className="md:flex md:items-center md:ml-12">
                <div className="md:w-1/3"></div>
                <div className="md:w-2/3">
                    <button
                        className="shadow bg-blue-500 hover:bg-blue-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                        type="submit">
                        Add City
                    </button>
                </div>
            </div>
        </form>
        </div>
    }
}

const mapStateToProps = rootReducer => ({
    city_name: rootReducer.add_city.cityName,
    city_enabled: rootReducer.add_city.enabled
});

const mapDispatchToProps = dispatch => ({
    actionSetCurrentAddCityName: name => dispatch(actionSetCurrentAddCityName(name)),
    actionSetCurrentAddCityEnabled: enabled => dispatch(actionSetCurrentAddCityEnabled(enabled))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddCityFragment));
