import React from "react";
import './fragemnt_user_profile_styles.scss';
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";
import axios from 'axios';
import {createApiServerRoute} from "../../../models/network/api_client";
import {actionSetAddPassengerWallet} from "../../../models/redux/reducers/activity_main_reducers/add_passenger_wallet_reducer/action_add_passenger_wallet";
import UrlArgsBundle from "../../../core/url_args_bundle";

class UserProfileFragment extends React.Component {

    addAmountInWallet = () => {
        const params = new URLSearchParams();
        const {client} = this.state;
        params.append('__passenger_id__', client['id']);
        params.append('__abracadabra__', client['authorization_token']['abracadabra']);
        params.append('__authorization_token__', client['authorization_token']['token']);
        params.append('amount', this.state.input_amount);

        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('add_amount_to_passenger_wallet.php'), params, config)
            .then(res => {
                this.setState({
                    ...this.state,
                    input_amount: ''
                })
                let {data} = res.data;
                this.props.actionSetAddPassengerWallet(data['newAmount'])
            });
    }

    componentDidMount() {
        const params = new URLSearchParams();
        const {client} = this.state;
        params.append('__passenger_id__', client['id']);
        params.append('__abracadabra__', client['authorization_token']['abracadabra']);
        params.append('__authorization_token__', client['authorization_token']['token']);

        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('get_passenger_wallet_amount.php'), params, config)
            .then(res => {
                console.log(res.data);
                this.props.actionSetAddPassengerWallet(res.data.data.wallet)
            });
    }

    constructor(props) {
        super(props);
        const urlArgsBundle = new UrlArgsBundle().prepareFrom(this.props.location.search);
        console.log(this.props.clients);
        this.state = {
            activeNavItemName: "About",
            input_amount: '',
            client: this.props.clients.filter(client => client.id === urlArgsBundle.getStringExtra('USER_ID'))[0]
        }
    }

    changeState = (active_name) => {
        this.setState(
            {
                ...this.state,
                activeNavItemName: active_name
            }
        )
    }

    render() {
        const {client} = this.state;
        return (
            <div className="profile-fragment">
                <img className="profile-img border-indigo-800 border-4" src={client.avatar}
                     alt="avatar"/>
                <h1 className="profile-heading text-indigo-800">{client['first_name']} {client['last_name']}</h1>
                <p className="profile-email">{client['email']}</p>

                <ul className="navbar">
                    <li className={`duration-500 text-indigo-800 hover:bg-indigo-800 hover:text-white py-1 px-4 my-auto rounded-lg ${this.state.activeNavItemName=== "About" && "borders"}`}
                        onClick={() => this.changeState("About")}>About
                    </li>

                    <li className={`duration-500 text-indigo-800 hover:bg-indigo-800 hover:text-white py-1 px-4 my-auto rounded-lg ${this.state.activeNavItemName=== "Plate" && "borders"}`}
                        onClick={() => this.changeState("Wallet")}>Wallet
                    </li>
                </ul>

                <div className={`div ${this.state.activeNavItemName=== "About" && "active"}`} id="City-div">
                    <p>Phone: {this.state.client.phone}</p>
                    {<p>Member Since: {client['created_at']}</p>}
                </div>

                <div id="Wallet" className={`div ${this.state.activeNavItemName=== "Wallet" && "active"}`}>
                    <p>Amount : {this.props.wallet}</p>
                    <div className="box">
                        <input type="text" value={this.state.input_amount} placeholder="Add Amount"
                               onChange={(event) => {
                                   this.setState({
                                       ...this.state,
                                       input_amount: event.target.value
                                   })
                               }}/>
                        <button onClick={this.addAmountInWallet}>ADD</button>
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = rootReducer => ({
    clients: rootReducer.clients.currentClients,
    wallet: rootReducer.passenger_wallet.wallet
});

const mapDispatchToProps = dispatch => ({
    actionSetAddPassengerWallet: wallet => dispatch(actionSetAddPassengerWallet(wallet))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserProfileFragment));