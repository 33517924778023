import React from "react";
import {Button, Snackbar, TextField} from "@material-ui/core";
import {Alert} from "@mui/material";
import {API_KEY, FRAGMENT_TAG} from "../../../utils/constants";
import axios from "axios";
import {createApiServerRoute} from "../../../models/network/api_client";

class AddSubAdminFragment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            username : '',
            password: '',
            showError: false,
            vertical : 'top',
            horizontal: 'right',
            error: 'Please fill all fields'
        }
    }


    addSubAdmin = () => {
        if (this.state.username === '' || this.state.password === '') {
            this.setState({...this.state , showError: true})
        } else {

            const params = new URLSearchParams();

            params.append("__api_key__", API_KEY)
            params.append("username", this.state.username)
            params.append("password", this.state.password)

            const config = {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                }
            };

            axios.post(createApiServerRoute('create_sub_admin.php'), params, config)
                .then(res => {
                    console.log(res)
                    if (res.data.state === 'OK' && res.data.data.admin) {
                        this.urlArgsBundle.setFragmentTag(FRAGMENT_TAG.SUB_ADMINS)
                        this.props.history.goBack()
                    } else {
                        this.setState({...this.state, showError: true, error : Object.keys(res.data.data.exceptions)[0]})
                    }
                })

        }
    }

    render() {
        return (
            <div className={"py-36 ba-gray-200 grid place-items-center"}>
                <Snackbar anchorOrigin={{horizontal: "right" , vertical: "top"}} open={this.state.showError} autoHideDuration={6000} onClose={() => {
                    this.setState({...this.state , showError: false, error: 'Please fill all fields'})
                }}>
                    <Alert onClose={() => {this.setState({...this.state , showError: false, error: 'Please fill all fields'})}} severity="error" sx={{ width: '100%' }}>
                        {this.state.error}
                    </Alert>
                </Snackbar>
                <div className="form-card w-96 bg-white py-6 px-10 rounded-2xl shadow-2xl flex flex-col gap-6">
                    <h1 className={"text-center text-indigo-800 text-2xl pb-3"}>Add Sub Admin</h1>

                    <TextField fullWidth id="standard-basic" label="Username" value={this.state.username} variant="outlined"
                               onChange={(e) => {
                                   this.setState({...this.state, username: e.target.value})
                               }}/>
                    <TextField fullWidth id="standard-basic" label="Password" value={this.state.password} variant="outlined"
                               onChange={(e) => {
                                   this.setState({...this.state, password: e.target.value})
                               }}/>
                    <Button className={"h-12 rounded-md"} variant="contained" color="primary" onClick={this.addSubAdmin}>Add</Button>

                </div>
            </div>
        );
    }
}

export default AddSubAdminFragment
