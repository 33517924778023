import {SET_CURRENT_ADD_CITY_NAME} from "./action_add_city";
import {SET_CURRENT_ADD_CITY_ENABLED} from "./action_add_city";

const INITIAL_STATE = {
    cityName: '',
    enabled: 'Y'
}

const addCityReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_CURRENT_ADD_CITY_NAME:
            return {
                ...state,
                cityName: action.payload
            }
        case SET_CURRENT_ADD_CITY_ENABLED:
            return {
                ...state,
                enabled: action.payload
            }
        default:
            return state;
    }
}

export default addCityReducer;
