// ACTIVITY_TAGS
export const ACTIVITY_TAG = {
    SPLASH: 'SPLASH',
    MAIN: 'MAIN',
    LOGIN: 'LOGIN'
}

export const ACTIVITY_TITLE = {
    SPLASH: 'Online Teer Admin Panel',
    MAIN: 'Online Teer Admin Panel',
    LOGIN: 'Online Teer Admin Panel'
}

// FRAGMENTS TAGS
export const FRAGMENT_TAG = {
    ADD_CITY: 'ADD_CITY',
    ADD_RIDE_CATEGORY: 'ADD_RIDE_CATEGORY',
    CAPTAINS: 'CAPTAINS',
    CITIES: 'CITIES',
    DASHBOARD: 'DASHBOARD',
    DRIVER_PROFILE: 'DRIVER_PROFILE',
    PAYMENTS: 'PAYMENTS',
    REVIEWS: 'REVIEWS',
    RIDE_CATEGORIES: 'RIDE_CATEGORIES',
    SETUP: 'SETUP',
    SCHEDULED_RIDES: 'SCHEDULED_RIDES',
    SCHEDULE_RIDE: 'SCHEDULE_RIDE',
    UPDATE_CITY: 'UPDATE_CITY',
    TRIPS: 'TRIPS',
    UPDATE_RIDE_CATEGORY: 'UPDATE_RIDE_CATEGORY',
    USERS: 'USERS',
    FREE_KILOMETERS: 'FREE_KILOMETERS',
    RUNNING_TRIPS: 'RUNNING_TRIPS',
    CANCELLED_TRIPS: 'CANCELLED_TRIPS',
    USER_PROFILE: 'USER_PROFILE',
    CITY_TO_CITY_SCHEDULED_RIDE: 'CITY_TO_CITY_SCHEDULED_RIDE',
    CITY_TO_CITY_RIDE_CATEGORIES: 'CITY_TO_CITY_RIDE_CATEGORIES',
    ADD_CITY_TO_CITY_RIDE_CATEGORY: 'ADD_CITY_TO_CITY_RIDE_CATEGORY',
    UPDATE_CITY_TO_CITY_RIDE_CATEGORY: 'UPDATE_CITY_TO_CITY_RIDE_CATEGORY',
    CITY_TO_CITY_SCHEDULE_RIDE: 'CITY_TO_CITY_SCHEDULE_RIDE',
    STANDARD_GAME: 'STANDARD_GAMES',
    STARLINE_GAME: 'STARLINE_GAMES',
    DESAWAR_GAME: 'DESAWAR_GAMES',
    SETTING_FRAGMENT: 'SETTING_FRAGMENT',
    ADD_STANDARD_GAME: 'ADD_STANDARD_GAME',
    ADD_STARLINE_GAME: 'ADD_STARLINE_GAME',
    ADD_DESAWAR_GAME: 'ADD_DESAWAR_GAME',
    UPDATE_STARLINE_GAME: 'UPDATE_STARLINE_GAME',
    UPDATE_STANDARD_GAME: 'UPDATE_STANDARD_GAME',
    UPDATE_DESAWAR_GAME: 'UPDATE_DESAWAR_GAME',
    STANDARD_GAME_SUB_CATEGORIES: 'STANDARD_GAME_SUB_CATEGORIES',
    STARLINE_GAME_SUB_CATEGORIES: 'STARLINE_GAME_SUB_CATEGORIES',
    DESAWAR_GAME_SUB_CATEGORIES: 'DESAWAR_GAME_SUB_CATEGORIES',
    GAME_RATE: 'GAME_RATE',
    STANDARD_GAME_CHART: 'STANDARD_GAME_CHART',
    ADD_STANDARD_GAME_CHART: 'ADD_STANDARD_GAME_CHART',
    UPDATE_STANDARD_GAME_CHART: 'UPDATE_STANDARD_GAME_CHART',
    STARLINE_GAME_CHART: 'STARLINE_GAME_CHART',
    DESAWAR_GAME_CHART: 'DESAWAR_GAME_CHART',
    SLIDER_IMAGES: 'SLIDER_IMAGES',
    ADD_STARLINE_GAME_CHART: 'ADD_STARLINE_GAME_CHART',
    ADD_DESAWAR_GAME_CHART: 'ADD_DESAWAR_GAME_CHART',
    IN_APP_SETTINGS: 'IN_APP_SETTINGS',
    PAYMENT_GATEWAY: 'PAYMENT_GATEWAY',
    WITHDRAW: 'WITHDRAW',
    PUSH_NOTIFICATION: 'PUSH_NOTIFICATION',
    // SUB_ADMINS: 'SUB_ADMINS',
    WINNERS: 'WINNERS',
    ABOUT: 'ABOUT',
    ADD_SUB_ADMIN: 'ADD_SUB_ADMIN',
    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    TEER_GAME: 'TEER_GAME',
    PLAY_HISTORY: 'PLAY_HISTORY',
    UPDATE_RESULT: 'UPDATE_RESULT',
    UPDATE_TEER_GAME: 'UPDATE_TEER_GAME',
    FC_GAME: 'FC_GAME',
    UPDATE_FC_GAME: 'UPDATE_FC_GAME'
}

export const FRAGMENT_TITLE = {
    ADD_CITY: 'Add City',
    ADD_RIDE_CATEGORY: 'Add Ride Category',
    CAPTAINS: 'Captains',
    CITIES: 'Cities',
    DASHBOARD: 'Dashboard',
    DRIVER_PROFILE: 'Captain Profile',
    PAYMENTS: 'Payments',
    REVIEWS: 'Reviews',
    RIDE_CATEGORIES: 'Ride Categories',
    SETUP: 'Setup',
    SCHEDULED_RIDES: 'Scheduled Rides',
    SCHEDULE_RIDE: 'Schedule Ride',
    UPDATE_CITY: 'Update City',
    TRIPS: 'Trips',
    UPDATE_RIDE_CATEGORY: 'Update Ride Category',
    USERS: 'Users',
    FREE_KILOMETERS: 'Free Kilometers',
    RUNNING_TRIPS: 'Running Trips',
    CANCELLED_TRIPS: 'Cancelled Trips',
    USER_PROFILE: 'User Profile',
    CITY_TO_CITY_SCHEDULED_RIDE: 'City To City Scheduled Ride',
    CITY_TO_CITY_RIDE_CATEGORIES: 'City To City Ride Categories',
    ADD_CITY_TO_CITY_RIDE_CATEGORY: 'Add City To City Ride Category',
    UPDATE_CITY_TO_CITY_RIDE_CATEGORY: 'Update City To City Ride Category',
    CITY_TO_CITY_SCHEDULE_RIDE: 'City To City Schedule Ride',
    STANDARD_GAME: 'Standard Games',
    STARLINE_GAME: 'Starline Games',
    SETTING_FRAGMENT: 'Settings',
    DESAWAR_GAME: 'Desawar Games',
    ADD_STANDARD_GAME: 'Add Standard Game',
    ADD_STARLINE_GAME: 'Add Starline Game',
    ADD_DESAWAR_GAME: 'Add Desawar Game',
    UPDATE_STANDARD_GAME: 'Update Standard Game',
    UPDATE_STARLINE_GAME: 'Update Starline Game',
    UPDATE_DESAWAR_GAME: 'Update Desawar Game',
    STANDARD_GAME_SUB_CATEGORIES: 'Standard Game Sub Categories',
    STARLINE_GAME_SUB_CATEGORIES: 'Starline Game Sub Categories',
    DESAWAR_GAME_SUB_CATEGORIES: 'Desawar Game Sub Categories',
    GAME_RATE: 'Game Rate',
    STANDARD_GAME_CHART: 'Standard Game Chart',
    ADD_STANDARD_GAME_CHART: 'Add Standard Game Chart',
    UPDATE_STANDARD_GAME_CHART: 'Update Standard Game Chart',
    STARLINE_GAME_CHART: 'Starline Game Chart',
    DESAWAR_GAME_CHART: 'Desawar Game Chart',
    SLIDER_IMAGES: 'Slider Images',
    ADD_STARLINE_GAME_CHART: 'Add Starline Game Chart',
    ADD_DESAWAR_GAME_CHART: 'Add Desawar Game Chart',
    IN_APP_SETTINGS: 'In App Settings',
    PAYMENT_GATEWAY: 'Payment Gateway',
    WITHDRAW: 'Withdraw Requests',
    PUSH_NOTIFICATION: 'Push Notification',
    //SUB_ADMINS: 'Sub Admins',
    ABOUT: 'About',
    WINNERS: 'Winners List',
    ADD_SUB_ADMIN: 'Add Sub Admin',
    CHANGE_PASSWORD: 'Change Password',
    TEER_GAME: 'Teer Game',
    PLAY_HISTORY: 'Play History',
    UPDATE_RESULT: 'Update Result',
    UPDATE_TEER_GAME: 'Update Teer Game',
    FC_GAME: 'Fc Game',
    UPDATE_FC_GAME: 'Update Fc Game'
}

export const GAME_TYPES = {
    STANDARD: 'STANDARD',
    STARLINE: 'STARLINE',
    DESAWAR: 'DESAWAR'
}

export const STANDARD_SUB_CATEGORIES = {
    SINGLE_DIGIT: 'SINGLE_DIGIT',
    JODI_DIGIT: 'JODI_DIGIT',
    SINGLE_PAANA: 'SINGLE_PAANA',
    DOUBLE_PAANA: 'DOUBLE_PAANA',
    TRIPLE_PAANA: 'TRIPLE_PAANA',
    HALF_SANGAM: 'HALF_SANGAM',
    FULL_SANGAM: 'FULL_SANGAM'
}

export const STARLINE_SUB_CATEGORIES = {
    SINGLE_DIGIT: 'SINGLE_DIGIT',
    SINGLE_PAANA: 'SINGLE_PAANA',
    DOUBLE_PAANA: 'DOUBLE_PAANA',
    TRIPLE_PAANA: 'TRIPLE_PAANA'
}

export const DESAWAR_SUB_CATEGORIES = {
    LEFT_DIGIT: 'LEFT_DIGIT',
    RIGHT_DIGIT: 'RIGHT_DIGIT',
    JODI_DIGIT: 'JODI_DIGIT'
}

export const API_KEY = "FtdCHUngqgk23Tpmk2yzaN2u4Y77VZbM3afWVnJQit2AYkufgjgjGXwbwMUz";
