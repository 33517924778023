import { combineReducers } from 'redux';

import clientsReducer from "./reducers/activity_main_reducers/clients_reducer/reducer_clients";
import captainsReducer from "./reducers/activity_main_reducers/captains_reducer/reducer_captains";
import adminSessionReducer from "./reducers/admin_session_reducer/reducer_admin_session";
import citiesReducer from "./reducers/activity_main_reducers/cities_reducer/reducer_cities";
import rideCategoriesReducer from "./reducers/activity_main_reducers/ride_categories_reducer/reducer_ride_categories";
import tripsReducer from "./reducers/activity_main_reducers/trips_reducer/reducer_trips";
import scheduleRidesReducer from "./reducers/activity_main_reducers/schedule_rides_reducer/reducer_schedule_rides";
import addCityReducer from "./reducers/activity_main_reducers/add_city_reducer/reducer_add_city";
import updateCityReducer from "./reducers/activity_main_reducers/update_city_reducer/reducer_update_city";
import walletDriverReducer from "./reducers/activity_main_reducers/add_driver_wallet_reducer/reducer_add_driver_wallet";
import setCancelledTripsReducer from "./reducers/activity_main_reducers/cancelled_trips_reducer/reducer_set_cancelled_trips";
import setRunningTripsReducer from "./reducers/activity_main_reducers/running_trips_reducer/reducer_set_running_trips";
import walletPassengerReducer from "./reducers/activity_main_reducers/add_passenger_wallet_reducer/reducer_add_passenger_wallet";
import updateFreeDistanceReducer from "./reducers/activity_main_reducers/update_free_distance_reducer/reducer_update_free_distance";
import unpaidDriversReducer from "./reducers/activity_main_reducers/drivers_payment_reducer/reducer_set_unpaid_drivers";
import paidDriversReducer from "./reducers/activity_main_reducers/drivers_payment_reducer/reduer_set_paid_drivers";
import reviewsReducer from "./reducers/activity_main_reducers/reviews_reducer/reducer_reviews";
import cityToCityRideCategoriesReducer from "./reducers/activity_main_reducers/city_to_city_ride_categories_reducer/reducer_city_to_city_ride_categories";
import metaReducer from "./reducers/activity_main_reducers/dashboard_reducer/reducer_dashboard";
import gameReducer from "./reducers/activity_main_reducers/games_reducer/reducer_game";

export default combineReducers({
    clients: clientsReducer,
    captains: captainsReducer,
    adminSession: adminSessionReducer,
    cities: citiesReducer,
    rideCategories: rideCategoriesReducer,
    trips: tripsReducer,
    schedule_rides: scheduleRidesReducer,
    add_city: addCityReducer,
    update_city: updateCityReducer,
    driver_wallet: walletDriverReducer,
    passenger_wallet: walletPassengerReducer,
    cancelledTripsReducer: setCancelledTripsReducer,
    runningTripsReducer: setRunningTripsReducer,
    updateFreeDistanceReducer: updateFreeDistanceReducer,
    unpaidDriversReducer : unpaidDriversReducer,
    paidDriversReducer : paidDriversReducer,
    reviewsReducer : reviewsReducer,
    cityToCityRideCategoriesReducer : cityToCityRideCategoriesReducer,
    metaReducer : metaReducer,
    gameReducer : gameReducer
})
