import {SET_PAID_DRIVERS} from "./action_set_paid_drivers";

const INITIAL_STATE = {
    paid_drivers: {
        api_called: false,
        drivers: []
    }
}

const paidDriversReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_PAID_DRIVERS:
            return {
                ...state,
                paid_drivers: action.payload
            }
        default:
            return state;
    }
}

export default paidDriversReducer;
