import {SET_CURRENT_CITY_TO_CITY_RIDE_CATEGORIES} from "./action_city_to_city_ride_categories";

const INITIAL_STATE = {
    currentCityToCityRideCategories: []
}

const rideCategoriesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_CURRENT_CITY_TO_CITY_RIDE_CATEGORIES:
            return {
                ...state,
                currentCityToCityRideCategories: action.payload
            }
        default:
            return state;
    }
}

export default rideCategoriesReducer;

