import React from "react";

const TableHeader = ({children}) => {
    return <thead className="bg-gray-50">
    <tr>
        {children}
    </tr>
    </thead>
}

export default TableHeader;