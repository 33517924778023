import React from 'react';
import './activity_login_styles.scss';

import {withRouter} from "react-router-dom";
import {Button, Snackbar, TextField} from "@material-ui/core";
import UrlArgsBundle from "../../../core/url_args_bundle";
import {Alert} from "@mui/material";
import {ACTIVITY_TAG, API_KEY} from "../../../utils/constants";
import axios from "axios";
import {createApiServerRoute} from "../../../models/network/api_client";

class LoginActivity extends React.Component {

    urlArgsBundle = (new UrlArgsBundle()).prepareFrom(this.props.location.search);

    constructor(props) {
        super(props);
        this.state = {
            username : '',
            password: '',
            showError: false,
            error : 'Please Fill All Fields'
        }
    }

    login = () => {
        if (this.state.username === '' || this.state.password === '') {
            this.setState({...this.state , showError: true})
        } else {

            const params = new URLSearchParams();

            params.append("__api_key__", API_KEY)
            params.append("username", this.state.username)
            params.append("password", this.state.password)
            params.append("type", this.state.type)

            const config = {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                }
            };

            axios.post(createApiServerRoute('login_admin.php'), params, config)
                .then(res => {
                    console.log(res)
                    if (res.data.state === 'OK' && res.data.data.admin) {
                        localStorage.setItem('user', JSON.stringify(res.data.data.admin))
                        this.urlArgsBundle.setActivityTag(ACTIVITY_TAG.MAIN)
                        this.props.history.push(this.urlArgsBundle.getArgsAsUrlParams())
                        console.log("done")
                        window.location.reload()
                    } else {
                        this.setState({...this.state, showError: true, error : Object.keys(res.data.data.exceptions)[0]})
                    }
                })

        }
    }

    render() {
        return <>
            <Snackbar anchorOrigin={{horizontal: "right" , vertical: "top"}} open={this.state.showError} autoHideDuration={6000} onClose={() => {
                this.setState({...this.state , showError: false, error: 'Please fill all fields'})
            }}>
                <Alert onClose={() => {this.setState({...this.state , showError: false, error: 'Please fill all fields'})}} severity="error" sx={{ width: '100%' }}>
                    {this.state.error}
                </Alert>
            </Snackbar>
            <div className="w-screen h-screen splash-activity">
                <div className="form-card w-96 bg-white py-6 px-10 rounded-2xl shadow-2xl flex flex-col gap-6">
                    <h1 className={"text-center text-indigo-800 text-2xl pb-1"}>Sign In</h1>
                    <span className={"text-center text-indigo-800 text-md pb-3 px-6"}>Enter Credentials to verify your account</span>
                    <TextField fullWidth id="standard-basic" label="Username" value={this.state.username} variant="outlined"
                               onChange={(e) => {
                                   this.setState({...this.state , username : e.target.value})
                               }}
                    />
                    <TextField fullWidth id="standard-basic" label="Password" value={this.state.password} variant="outlined" ref='password'
                               hintText="Password"
                               floatingLabelText="Password"
                               type="password"
                               onChange={(e) => {
                                   this.setState({...this.state , password : e.target.value})
                               }}
                    />
                    <Button className={"h-12 rounded-md"} variant="contained" color="primary" onClick={this.login}>Sign In</Button>
                </div>
            </div>
        </>
    }
}

export default withRouter(LoginActivity)
