import React from "react";
import { Button, TextField } from "@material-ui/core";
import { API_KEY } from "../../../utils/constants";
import axios from "axios";
import { createApiServerRoute } from "../../../models/network/api_client";

class AboutFragment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      aboutUs: "",
    };
  }

  send = () => {
    const params = new URLSearchParams();
    params.append("__api_key__", API_KEY);
    params.append("about", this.state.aboutUs);
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios
      .post(createApiServerRoute("update_about.php"), params, config)
      .then((res) => {
        if (res.data.data.send) {
          this.setState({
            ...this.state,
            aboutUs: "",
          });
        }
      });
  };

  componentDidMount() {
    const params = new URLSearchParams();
    params.append("__api_key__", API_KEY);
    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios
      .post(createApiServerRoute("fetch_in_app_settings.php"), params, config)
      .then((res) => {
        if (res.data.state === "OK") {
          this.setState({ ...this.state, aboutUs: res.data.data.meta.about });
        } else {
          console.log(res);
        }
      });
  }

  render() {
    return (
        <div className="py-8 flex justify-center items-center">
        <div className="max-w-lg w-full p-8 bg-white rounded-lg shadow-lg">
          <h1 className="text-center text-2xl font-semibold text-indigo-800 mb-6">
            About Us
          </h1>
          <TextField
            fullWidth
            multiline
            rows={8}
            label="About"
            variant="outlined"
            value={this.state.aboutUs}
            onChange={(e) => {
              this.setState({ ...this.state, aboutUs: e.target.value });
            }}
          />
          <div className="mt-6 flex justify-center">
            <Button
              className="h-12 px-6 rounded-md"
              variant="contained"
              color="primary"
              onClick={() => this.send()}
            >
              Update
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutFragment;