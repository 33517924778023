import React from 'react';
import './fragment_update_city_to_city_ride_category_styles.scss';

import axios from 'axios';
import { createApiServerRoute } from "../../../models/network/api_client";

import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import UrlArgsBundle from "../../../core/url_args_bundle";
import {actionSetCurrentCityToCityRideCategories} from "../../../models/redux/reducers/activity_main_reducers/city_to_city_ride_categories_reducer/action_city_to_city_ride_categories";

class UpdateCityToCityRideCategoryFragment extends React.Component {

    constructor(props) {
        super(props);
        const urlArgsBundle = (new UrlArgsBundle()).prepareFrom(this.props.location.search);
        this.state = {
            nameButtonState: true,
            categoryImage: null,
            categoryFromCity: '',
            categoryToCity: '',
            categoryPrice: '',
            categoryPerKilometer: '',
            currentRideCategory: this.props.cityToCityRideCategoriesReducer.filter(rideCategory => rideCategory.id === urlArgsBundle.getStringExtra('RIDE_CATEGORY_ID'))[0]
        }
    }

    updateFromCity = () => {
        const params = new FormData();
        let {categoryFromCity, currentRideCategory} = this.state;
        console.log(currentRideCategory.id);
        params.append('city_to_city_ride_category_id', currentRideCategory.id);
        params.append('new_from_city', categoryFromCity);

        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        this.setState({...this.state, nameButtonState: false})

        axios.post(createApiServerRoute('update_city_to_city_ride_category_from_city.php'), params, config)
            .then(res => {
                console.log(res.data)
                if (res.data['cab_5_response_state'] === 'OK') {
                    this.props.history.goBack();
                }
            })
    }


    updateToCity = () => {
        const params = new FormData();
        let {categoryToCity, currentRideCategory} = this.state;
        console.log(currentRideCategory.id);
        params.append('city_to_city_ride_category_id', currentRideCategory.id);
        params.append('new_to_city', categoryToCity);

        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        this.setState({...this.state, nameButtonState: false})

        axios.post(createApiServerRoute('update_city_to_city_ride_category_to_city.php'), params, config)
            .then(res => {
                console.log(res.data)
                if (res.data['cab_5_response_state'] === 'OK') {
                    this.props.history.goBack();
                }
            })
    }

    updatePrice = () => {
        const params = new FormData();
        const {categoryPrice , currentRideCategory} = this.state;
        params.append('city_to_city_ride_category_id' , currentRideCategory.id);
        params.append('new_price', categoryPrice);

        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('update_city_to_city_ride_category_price.php') , params , config)
            .then(res=> {
                if (res.data['cab_5_response_state'] === 'OK') {
                    this.props.history.goBack();
                }
            })
    }

    updatePerKilometer = () => {
        const params = new FormData();
        const {categoryPerKilometer , currentRideCategory} = this.state;
        params.append('city_to_city_ride_category_id' , currentRideCategory.id);
        params.append('new_per_kilometer', categoryPerKilometer);

        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('update_city_to_city_ride_category_per_kilometer.php') , params , config)
            .then(res=> {
                if (res.data['cab_5_response_state'] === 'OK') {
                    this.props.history.goBack();
                }
            })
    }

    updateImage = () => {
        const params = new FormData();
        const {categoryImage, currentRideCategory} = this.state;
        params.append('city_to_city_ride_category_id', currentRideCategory.id);
        params.append('new_image', categoryImage);


        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        }

        axios.post(createApiServerRoute('update_city_to_city_ride_category_image.php'), params, config)
            .then(res => {
                console.log(res.data)
                if (res.data['cab_5_response_state' === 'OK']) {
                    this.props.history.goBack();
                }
            })

    }

    render() {

        console.log(this.props.cityToCityRideCategoriesReducer);
        const {currentRideCategory} = this.state;
        console.log(currentRideCategory)
        return <div className="flex flex-column justify-center items-center md:mt-10">
            <form className="w-full max-w-lg">
                <div className="m-3 text-center">
                    <label className="inline-block mb-3 text-gray-800 font-bold text-md">Update Image</label>

                    <div className="flex items-center gap-3 justify-center w-full">
                        <div className="flex flex-col justify-content-center align-items-center w-1/5 h-32">
                            <span className="text-gray-800 font-bold">Current</span>
                            <img src={currentRideCategory.image} alt={""} />
                        </div>
                        <label
                            className="flex flex-col w-4/5 h-32 border-4 border-blue-200 border-dashed hover:bg-gray-100 hover:border-gray-300">
                            <div className="flex flex-col items-center justify-center pt-7">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     className="w-8 h-8 text-gray-400 group-hover:text-gray-600"
                                     fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"/>
                                </svg>
                                <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                                    Attach a file</p>
                            </div>
                            <input type="file" className="opacity-0" onChange={(e) => {
                                this.setState({...this.state, categoryImage: e.target.files[0]})
                            }}/>
                        </label>
                    </div>
                    <button
                        className="shadow bg-blue-500 mt-3 hover:bg-blue-400 focus:shadow-outline focus:outline-none text-sm text-white font-bold py-1 px-2 rounded"
                        type="button"
                        onClick={this.updateImage}>
                        Update Image
                    </button>
                </div>
                <div className="flex flex-column gap-8 justify-center items-center -mx-3 mb-6">
                    <div className="w-full mt-6">
                        <label className="block uppercase tracking-wide text-gray-800 text-md font-bold my-2"
                               htmlFor="grid-first-name">
                            Ride Category From City<br/>
                            <span className="text-gray-400 text-xs">Current : ( <span
                                className="text-gray-700">{currentRideCategory.from_city}</span> )</span>
                        </label>
                        <input
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            id="grid-first-name" type="text" placeholder="Ride Category Name"
                            onChange={(e) => {
                                this.setState({...this.state, categoryFromCity: e.target.value})
                            }}/>
                        <button
                            className="shadow bg-blue-500 mt-3 hover:bg-blue-400 focus:shadow-outline focus:outline-none text-sm text-white font-bold py-1 px-2 rounded"
                            type="button"
                            onClick={this.updateFromCity}
                            {...this.state.nameButtonState ? null : "disabled"}>
                            Update Category
                        </button>
                    </div>
                    <div className="w-full mt-6">
                        <label className="block uppercase tracking-wide text-gray-800 text-md font-bold my-2"
                               htmlFor="grid-first-name">
                            Ride Category To City<br/>
                            <span className="text-gray-400 text-xs">Current : ( <span
                                className="text-gray-700">{currentRideCategory.to_city}</span> )</span>
                        </label>
                        <input
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            id="grid-first-name" type="text" placeholder="Ride Category Name"
                            onChange={(e) => {
                                this.setState({...this.state, categoryToCity: e.target.value})
                            }}/>
                        <button
                            className="shadow bg-blue-500 mt-3 hover:bg-blue-400 focus:shadow-outline focus:outline-none text-sm text-white font-bold py-1 px-2 rounded"
                            type="button"
                            onClick={this.updateToCity}
                            {...this.state.nameButtonState ? null : "disabled"}>
                            Update Category
                        </button>
                    </div>
                </div>
                    <div className="flex flex-column gap-8 justify-center items-center -mx-3 mb-4">
                    <div className="w-full mt-6">
                        <label className="block uppercase tracking-wide text-gray-800 text-md font-bold my-2"
                               htmlFor="grid-state">
                            Per Kilometer Price
                            <br/>
                            <span className="text-gray-400 text-xs">Current : ( <span
                                className="text-gray-700">{currentRideCategory.per_km_cost}</span> )</span>
                        </label>
                        <input
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            id="grid-first-name" type="text" placeholder="Per Kilometer Price"
                            onChange={(e) => {
                                this.setState({...this.state, categoryPerKilometer: e.target.value})
                            }}/>
                        <button
                            className="shadow bg-blue-500 mt-3 hover:bg-blue-400 focus:shadow-outline focus:outline-none text-sm text-white font-bold py-1 px-2 rounded"
                            type="button"
                            onClick={this.updatePerKilometer}>
                            Update Per_Km
                        </button>
                    </div>
                    <div className="w-full mt-6">
                        <label className="block uppercase tracking-wide text-gray-800 text-md font-bold my-2"
                               htmlFor="grid-first-name">
                            Price<br/>
                            <span className="text-gray-400 text-xs">Current : ( <span
                                className="text-gray-700">{currentRideCategory.price}</span> )</span>
                        </label>
                        <input
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            id="grid-first-name" type="text" placeholder="Price"
                            onChange={(e) => {
                                this.setState({...this.state, categoryPrice: e.target.value})
                            }}/>

                        <button
                            className="shadow bg-blue-500 mt-3 hover:bg-blue-400 focus:shadow-outline focus:outline-none text-sm text-white font-bold py-1 px-2 rounded"
                            type="button"
                            onClick={this.updatePrice}>
                            Update Price
                        </button>
                    </div>
                </div>
            </form>
        </div>


    }
}
const mapStateToProps = rootReducer => ({
    cityToCityRideCategoriesReducer: rootReducer.cityToCityRideCategoriesReducer.currentCityToCityRideCategories
});

const mapDispatchToProps = dispatch => ({
    actionSetCurrentCityToCityRideCategories: rideCategories => dispatch(actionSetCurrentCityToCityRideCategories(rideCategories))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UpdateCityToCityRideCategoryFragment));
