import React from "react";
import {Apps, Home, Image, Payment, Person, Rowing, Settings, NotificationImportant, Domain} from "@material-ui/icons";
import './drawer_styles.scss';
import DrawerMenu from "../drawer_menu/drawer_menu";
import DrawerMenuItem from "../drawer_menu_item/drawer_menu_item";
import DrawerDivider from "../drawer_divider/DrawerDivider";
import {withRouter} from "react-router-dom";
import {FRAGMENT_TAG, FRAGMENT_TITLE, ACTIVITY_TAG} from "../../../utils/constants";
import UrlArgsBundle from "../../../core/url_args_bundle";
import { Percent } from "@mui/icons-material";

class Drawer extends React.Component {

    handleClick = fragmentTag => {
        const urlArgsBundle = (new UrlArgsBundle())
            .setActivityTag(ACTIVITY_TAG.MAIN)
            .setFragmentTag(fragmentTag)
        this.props.history.push(urlArgsBundle.getArgsAsUrlParams())
    }

    clickHandler = fragmentTag => {
        return () => this.handleClick(fragmentTag)
    }

    render() {

        let user = JSON.parse(localStorage.getItem('user'))
        let standardTags = [FRAGMENT_TAG.STANDARD_GAME , FRAGMENT_TAG.ADD_STANDARD_GAME, FRAGMENT_TAG.UPDATE_STANDARD_GAME, FRAGMENT_TAG.STANDARD_GAME_SUB_CATEGORIES];
        let starlineTags = [FRAGMENT_TAG.STARLINE_GAME , FRAGMENT_TAG.ADD_STARLINE_GAME, FRAGMENT_TAG.UPDATE_STARLINE_GAME, FRAGMENT_TAG.STARLINE_GAME_SUB_CATEGORIES];
        let subAdminTags = [FRAGMENT_TAG.SUB_ADMINS, FRAGMENT_TAG.ADD_SUB_ADMIN]
        let desawarTags = [FRAGMENT_TAG.DESAWAR_GAME , FRAGMENT_TAG.ADD_DESAWAR_GAME, FRAGMENT_TAG.UPDATE_DESAWAR_GAME, FRAGMENT_TAG.DESAWAR_GAME_SUB_CATEGORIES];

        return <div className="drawer w-8 md:w-60 lg:w-64 flex flex-col">

            <div className="logo-area">
                <h2 className="text-center font-bold">Online Teer</h2>
            </div>

            <DrawerDivider/>

            <DrawerMenu>
                <DrawerMenuItem
                    icon={<Home/>}
                    name={FRAGMENT_TITLE.DASHBOARD}
                    isActive={this.props.fragmentTag === FRAGMENT_TAG.DASHBOARD}
                    clickHandler={this.clickHandler(FRAGMENT_TAG.DASHBOARD)}/>
                <DrawerMenuItem
                    icon={<Home/>}
                    name={FRAGMENT_TITLE.ABOUT}
                    isActive={this.props.fragmentTag === FRAGMENT_TAG.ABOUT}
                    clickHandler={this.clickHandler(FRAGMENT_TAG.ABOUT)}/>
                <DrawerMenuItem
                    icon={<Person/>}
                    name={FRAGMENT_TITLE.USERS}
                    isActive={this.props.fragmentTag === FRAGMENT_TAG.USERS}
                    clickHandler={this.clickHandler(FRAGMENT_TAG.USERS)}/>
                <DrawerMenuItem
                    icon={<Payment />}
                    name={FRAGMENT_TITLE.WITHDRAW}
                    isActive={this.props.fragmentTag === FRAGMENT_TAG.WITHDRAW}
                    clickHandler={this.clickHandler(FRAGMENT_TAG.WITHDRAW)}/>
                <DrawerMenuItem
                    icon={<Payment />}
                    name={FRAGMENT_TITLE.PLAY_HISTORY}
                    isActive={this.props.fragmentTag === FRAGMENT_TAG.PLAY_HISTORY}
                    clickHandler={this.clickHandler(FRAGMENT_TAG.PLAY_HISTORY)}/>
                <DrawerMenuItem
                    icon={<Payment />}
                    name={FRAGMENT_TITLE.WINNERS}
                    isActive={this.props.fragmentTag === FRAGMENT_TAG.WINNERS}
                    clickHandler={this.clickHandler(FRAGMENT_TAG.WINNERS)}/>
                <DrawerMenuItem
                    icon={<Percent />}
                    name={FRAGMENT_TITLE.GAME_RATE}
                    isActive={this.props.fragmentTag === FRAGMENT_TAG.GAME_RATE}
                    clickHandler={this.clickHandler(FRAGMENT_TAG.GAME_RATE)}/>
                <DrawerMenuItem
                    icon={<Rowing/>}
                    name={FRAGMENT_TITLE.TEER_GAME}
                    isActive={[FRAGMENT_TAG.TEER_GAME, FRAGMENT_TAG.UPDATE_TEER_GAME].includes(this.props.fragmentTag)}
                    clickHandler={this.clickHandler(FRAGMENT_TAG.TEER_GAME)}/>
                <DrawerMenuItem
                    icon={<Rowing/>}
                    name={FRAGMENT_TITLE.FC_GAME}
                    isActive={[FRAGMENT_TAG.FC_GAME, FRAGMENT_TAG.UPDATE_FC_GAME].includes(this.props.fragmentTag)}
                    clickHandler={this.clickHandler(FRAGMENT_TAG.FC_GAME)}/>
                <DrawerMenuItem
                    icon={<Payment />}
                    name={FRAGMENT_TITLE.UPDATE_RESULT}
                    isActive={this.props.fragmentTag === FRAGMENT_TAG.UPDATE_RESULT}
                    clickHandler={this.clickHandler(FRAGMENT_TAG.UPDATE_RESULT)}/>
                {
                    user === null ? "" : (user['type'] === 'MAIN' ? <>
                        <DrawerMenuItem
                            icon={<Apps />}
                            name={FRAGMENT_TITLE.IN_APP_SETTINGS}
                            isActive={this.props.fragmentTag === FRAGMENT_TAG.IN_APP_SETTINGS}
                            clickHandler={this.clickHandler(FRAGMENT_TAG.IN_APP_SETTINGS)}/>
                        <DrawerMenuItem
                            icon={<Settings />}
                            name={FRAGMENT_TITLE.SETTING_FRAGMENT}
                            isActive={this.props.fragmentTag === FRAGMENT_TAG.SETTING_FRAGMENT}
                            clickHandler={this.clickHandler(FRAGMENT_TAG.SETTING_FRAGMENT)}/>
                        <DrawerMenuItem
                            icon={<NotificationImportant />}
                            name={FRAGMENT_TITLE.PUSH_NOTIFICATION}
                            isActive={this.props.fragmentTag === FRAGMENT_TAG.PUSH_NOTIFICATION}
                            clickHandler={this.clickHandler(FRAGMENT_TAG.PUSH_NOTIFICATION)}/>
                        {/* <DrawerMenuItem
                        icon={<Domain/>}
                        name={FRAGMENT_TITLE.SUB_ADMINS}
                        isActive={subAdminTags.includes(this.props.fragmentTag)}
                        clickHandler={this.clickHandler(FRAGMENT_TAG.SUB_ADMINS)}/> */}
                    </> : "")
                }
                <DrawerMenuItem
                    icon={<Payment />}
                    name={FRAGMENT_TITLE.PAYMENT_GATEWAY}
                    isActive={this.props.fragmentTag === FRAGMENT_TAG.PAYMENT_GATEWAY}
                    clickHandler={this.clickHandler(FRAGMENT_TAG.PAYMENT_GATEWAY)}/>
                <DrawerMenuItem
                    icon={<Image />}
                    name={FRAGMENT_TITLE.SLIDER_IMAGES}
                    isActive={this.props.fragmentTag === FRAGMENT_TAG.SLIDER_IMAGES}
                    clickHandler={this.clickHandler(FRAGMENT_TAG.SLIDER_IMAGES)}/>
                {/*<DrawerMenuItem*/}
                {/*    icon={<Category/>}*/}
                {/*    name={FRAGMENT_TITLE.RIDE_CATEGORIES}*/}
                {/*    isActive={[FRAGMENT_TAG.RIDE_CATEGORIES, FRAGMENT_TAG.ADD_RIDE_CATEGORY].includes(this.props.fragmentTag)}*/}
                {/*    clickHandler={this.clickHandler(FRAGMENT_TAG.RIDE_CATEGORIES)}/>*/}
                {/*<DrawerMenuItem*/}
                {/*    icon={<Category/>}*/}
                {/*    name={FRAGMENT_TITLE.SCHEDULED_RIDES}*/}
                {/*    isActive={[FRAGMENT_TAG.SCHEDULED_RIDES, FRAGMENT_TAG.SCHEDULE_RIDE].includes(this.props.fragmentTag)}*/}
                {/*    clickHandler={this.clickHandler(FRAGMENT_TAG.SCHEDULED_RIDES)}/>*/}
                {/*/!*<DrawerMenuItem*!/*/}
                {/*/!*    icon={<Settings/>}*!/*/}
                {/*/!*    name={FRAGMENT_TITLE.SETUP}*!/*/}
                {/*/!*    isActive={this.props.fragmentTag === FRAGMENT_TAG.SETUP}*!/*/}
                {/*/!*    clickHandler={this.clickHandler(FRAGMENT_TAG.SETUP)}/>*!/*/}
                {/*<DrawerMenuItem*/}
                {/*    icon={<TripOrigin/>}*/}
                {/*    name={FRAGMENT_TITLE.TRIPS}*/}
                {/*    isActive={this.props.fragmentTag === FRAGMENT_TAG.TRIPS}*/}
                {/*    clickHandler={this.clickHandler(FRAGMENT_TAG.TRIPS)}/>*/}
                {/*<DrawerMenuItem*/}
                {/*    icon={<Feedback/>}*/}
                {/*    name={FRAGMENT_TITLE.REVIEWS}*/}
                {/*    isActive={this.props.fragmentTag === FRAGMENT_TAG.REVIEWS}*/}
                {/*    clickHandler={this.clickHandler(FRAGMENT_TAG.REVIEWS)}/>*/}
                {/*<DrawerMenuItem*/}
                {/*    icon={<Payment/>}*/}
                {/*    name={FRAGMENT_TITLE.FREE_KILOMETERS}*/}
                {/*    isActive={this.props.fragmentTag === FRAGMENT_TAG.FREE_KILOMETERS}*/}
                {/*    clickHandler={this.clickHandler(FRAGMENT_TAG.FREE_KILOMETERS)}/>*/}
                {/*<DrawerMenuItem*/}
                {/*    icon={<Payment/>}*/}
                {/*    name={FRAGMENT_TITLE.PAYMENTS}*/}
                {/*    isActive={this.props.fragmentTag === FRAGMENT_TAG.PAYMENTS}*/}
                {/*    clickHandler={this.clickHandler(FRAGMENT_TAG.PAYMENTS)}/>*/}
                {/*<DrawerMenuItem*/}
                {/*    icon={<ExitToApp/>}*/}
                {/*    name="Logout"*/}
                {/*    isActive={false}*/}
                {/*    clickHandler={() => {}} />*/}
            </DrawerMenu>
        </div>
    }
}

export default withRouter(Drawer);
