import React from "react";
import './fragment_running_trips_styles.scss';
import axios from "axios";
import {createApiServerRoute} from "../../../models/network/api_client";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import TableIndexColumn from "../../../components/table_component/table_index_column/table_index_column";
import TableTextualColumn from "../../../components/table_component/table_textual_column/table_textual_column";
import Table from "../../../components/table_component/table/table";
import TableHeader from "../../../components/table_component/table_header/table_header";
import TableHeading from "../../../components/table_component/table_heading/table_heading";
import TableBody from "../../../components/table_component/table_body/table_body";
import {actionSetRunningTrips} from "../../../models/redux/reducers/activity_main_reducers/running_trips_reducer/action_set_running_trips";
import ChipsContainer from "../../../components/chip_component/chips_container/chips_container";
import Chip from "../../../components/chip_component/chip";
import {ACTIVITY_TAG, FRAGMENT_TAG} from "../../../utils/constants";
import UrlArgsBundle from "../../../core/url_args_bundle";

class RunningTripsFragment extends React.Component {

    componentDidMount() {
        const params = new URLSearchParams();

        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('get_all_started_rides.php'), params, config)
            .then(res => {
                this.props.actionSetRunningTrips(res.data.data.trips)
            });
    }

    getRunningFragment = fragmentTag => {
        const urlArgsBundle = new UrlArgsBundle();
        urlArgsBundle.setActivityTag(ACTIVITY_TAG.MAIN).setFragmentTag(fragmentTag);
        return urlArgsBundle.getArgsAsUrlParams()
    }

    render() {
        return <div className="flex flex-col">
            <ChipsContainer>
                <Chip active={false} clickHandler={() => {this.props.history.push(this.getRunningFragment(FRAGMENT_TAG.TRIPS))}}>Completed Trips</Chip>
                <Chip active={true} clickHandler={() => {this.props.history.push(this.getRunningFragment(FRAGMENT_TAG.RUNNING_TRIPS))}}>Running Trips</Chip>
                <Chip active={false} clickHandler={() => {this.props.history.push(this.getRunningFragment(FRAGMENT_TAG.CANCELLED_TRIPS))}}>Cancelled Trips</Chip>
            </ChipsContainer>
            <Table>
                <TableHeader>
                    <TableHeading>#</TableHeading>
                    <TableHeading>Driver</TableHeading>
                    <TableHeading>User</TableHeading>
                    <TableHeading>Driver Phone</TableHeading>
                    <TableHeading>Ride Date</TableHeading>
                    <TableHeading>PickUp Location</TableHeading>
                </TableHeader>
                <TableBody>
                    {this.props.trips.map((d, index) => <tr key={d['trip_id']}>
                        <TableIndexColumn num={index + 1} clickHandler={() => {}} />
                        <TableTextualColumn text={`${d['driver_first_name']} ${d['driver_last_name']}`} />
                        <TableTextualColumn text={`${d['passenger_first_name']} ${d['passenger_last_name']}`} />
                        <TableTextualColumn text={d['driver_phone']} />
                        <TableTextualColumn text={d['ride_date']} />
                        <TableTextualColumn text={d['pickup_location']} />
                    </tr>)}
                </TableBody>
            </Table>
        </div>
    }
}

const mapStateToProps = rootReducer => ({
    trips: rootReducer.runningTripsReducer.runningTrips
});

const mapDispatchToProps = dispatch => ({
    actionSetRunningTrips: trips => dispatch(actionSetRunningTrips(trips))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RunningTripsFragment));
