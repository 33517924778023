import {SET_ADD_DRIVER_WALLET} from "./action_add_driver_wallet";

const INITIAL_STATE = {
    wallet: 'Loading...'
}

const walletDriverReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_ADD_DRIVER_WALLET:
            return {
                ...state,
                wallet: action.payload
            }
        default:
            return state;
    }
}

export default walletDriverReducer;
