import React from 'react';
import './winners_styles.scss';
import axios from 'axios';
import { createApiServerRoute } from "../../../models/network/api_client";
import { connect } from 'react-redux';
import { actionSetCurrentClients } from "../../../models/redux/reducers/activity_main_reducers/clients_reducer/action_clients";
import Table from "../../../components/table_component/table/table";
import TableHeader from "../../../components/table_component/table_header/table_header";
import TableBody from "../../../components/table_component/table_body/table_body";
import TableIndexColumn from "../../../components/table_component/table_index_column/table_index_column";
import TableGreenishTextualColumn from "../../../components/table_component/table_greenish_textual_column/table_greenish_textual_column";
import TableTextualColumn from "../../../components/table_component/table_textual_column/table_textual_column";
import TableHeading from "../../../components/table_component/table_heading/table_heading";
import { API_KEY } from "../../../utils/constants";
import {withRouter} from "react-router-dom";
import {Button} from "@material-ui/core";
import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';
import moment from 'moment';


class WinnersFragment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          khanapara_fr : 'XX',
          khanapara_sr : 'XX',
          shilong_fr : 'XX',
          shilong_sr : 'XX',
          isLoading: false,
            all_histories: null
        }
    }

    makePayment = (game_no) => {
      // Display the loading dialog
      this.setState({ isLoading: true });
  
      const params = new URLSearchParams();
      params.append("__api_key__", API_KEY);
      params.append("game_no", game_no);
      params.append("khanapara_fr", this.state.khanapara_fr)
      params.append("khanapara_sr",this.state.khanapara_sr)
      params.append("shilong_fr", this.state.shilong_fr)
      params.append("shilong_sr", this.state.shilong_sr)
      
      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
  
      axios.post(createApiServerRoute('pay_to_all_winners.php'), params, config)
        .then(res => {
          if (res.data.state === 'OK') {
      
            const mergeResult = [ ...res.data.data.winners];
            const sortedResult=mergeResult.slice(0).sort((a,b)=>
                a.played_at.localeCompare(b.played_at));
            this.setState({
                isLoading: false,
                all_histories: sortedResult.filter(item=>moment(item['played_at']).format('DD-MM-YYYY') === moment(new Date()).format('DD-MM-YYYY'))
            })
            this.getWinners();
          }
          else {
            console.log(res)
           }
        });
    }

    
    reversePayment = (game_no) => {
        // Display the loading dialog
        this.setState({ isLoading: true });
    
        const params = new URLSearchParams();
        params.append("__api_key__", API_KEY);
        params.append("game_no", game_no);
        params.append("khanapara_fr", this.state.khanapara_fr)
        params.append("khanapara_sr",this.state.khanapara_sr)
        params.append("shilong_fr", this.state.shilong_fr)
        params.append("shilong_sr", this.state.shilong_sr)
    
    
        const config = {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        };
    
        axios.post(createApiServerRoute('reverse_payment.php'), params, config)
          .then(res => {
            if (res.data.state === 'OK') {
        
              const mergeResult = [ ...res.data.data.winners];
              const sortedResult=mergeResult.slice(0).sort((a,b)=>
                  a.played_at.localeCompare(b.played_at));
              this.setState({
                  isLoading: false,
                  all_histories: sortedResult.filter(item=>moment(item['played_at']).format('DD-MM-YYYY') === moment(new Date()).format('DD-MM-YYYY'))
              })
              this.getWinners();
            }
            else {
              console.log(res)
             }
          });
      }

   
      getWinners = ()=>{
        const params = new URLSearchParams();
        console.log("khanapara_fr", this.state.khanapara_fr)
        console.log("khanapara_sr",this.state.khanapara_sr)
        console.log("shilong_fr", this.state.shilong_fr)
        console.log("shilong_sr", this.state.shilong_sr)

        params.append("__api_key__", API_KEY)
        params.append("khanapara_fr", this.state.khanapara_fr)
        params.append("khanapara_sr",this.state.khanapara_sr)
        params.append("shilong_fr", this.state.shilong_fr)
        params.append("shilong_sr", this.state.shilong_sr)
        
       
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('get_winners.php'), params, config)
        .then(res => {
            if (res.data.state === 'OK') {
                const mergeResult = [ ...res.data.data.winners];
                const sortedResult=mergeResult.slice(0).sort((a,b)=>
                    a.played_at.localeCompare(b.played_at));
                this.setState({
                    all_histories: sortedResult.filter(item=>moment(item['played_at']).format('DD-MM-YYYY') === moment(new Date()).format('DD-MM-YYYY'))
                })
            } else {
                console.log(res)
            }
        });
      }

  

    componentDidMount() {

      const params = new URLSearchParams();
      params.append("__api_key__", API_KEY)

      const config = {
          headers: {
              "Content-Type": "application/x-www-form-urlencoded",
          }
      };
      axios.post(createApiServerRoute('fetch_teer_results.php'), params, config)
          .then(res => {
              if (res.data.state === 'OK') {
                  console.log("res.data.data.teer_results",res.data.data.teer_results);
                  if(res.data.data.teer_results != null){
                  this.setState({
                      ...this.state,
                      khanapara_fr : res.data.data.teer_results.khanapara_fr,
                      khanapara_sr : res.data.data.teer_results.khanapara_sr,
                      shilong_fr : res.data.data.teer_results.shilong_fr,
                      shilong_sr : res.data.data.teer_results.shilong_sr,
                  })
                  this.getWinners()

                  }
              }
          });

    }

      bidOnrenderForExcel = (item)=>{
        if(item['game_type'] == 'Bothside FC'){
            const left_digit = [];
            const right_digit = [];
            item.left_digit_data !== null && item.left_digit_data.digits.map(data=> left_digit.push(` ${data}`))
            item.right_digit_data !== null && item.right_digit_data.digits.map(data=> right_digit.push(` ${data}`))
            return `${left_digit.toString()} * ${right_digit.toString()}`;
        } else if(item['game_type'] == 'Same FC'){
            const digit = [];
            item.left_digit_data !== null && item.left_digit_data.digits.map(data=> digit.push(` ${data}`))
            item.right_digit_data !== null && item.right_digit_data.digits.map(data=> digit.push(` ${data}`))
            return `${digit.toString()}`;
        } else if(item['game_type'] === 'Direct FC'){
            return `${item.left_digit} * ${item.right_digit}`
        } else{
            const bidOnData = [];
            for (var i=0; i < 100; i++) {
            if(item[`field_${i}`] !== null && item[`field_${i}`] !== undefined){
                bidOnData.push(` ${i} = ₹ ${item[`field_${i}`]}`)
            }}
            return bidOnData.toString();
        }
      }


      teerAmountrender = (item)=>{
            const amountData = [];
            for (var i=0; i < 100; i++) {
            if(item[`field_${i}`] !== null && item[`field_${i}`] !== undefined){
                amountData.push(Number(item[`field_${i}`]))
            }}
            return amountData.reduce((a,v) =>  a = a + v , 0 );
      }
    
    render() {
        console.log(this.props.clients)
        const totalWinners = this.state.all_histories
        ? this.state.all_histories.length
        : 0;

        return <>

<div className="py-8 bg-white mx-8 grid grid-cols-2 gap-4" >
<div className="grid grid-cols-2 gap-4  mx-2">
    {/* Item 1 */}
    <div className="bg-white rounded-lg shadow-lg p-4" style={{ background: '#183D3D'}}>
      <h2 className="text-xl font-semibold" style={{ color: '#ffffff' }}>KHANAPARA TEER - FR</h2>
      <p className="text-3xl text-indigo-800" style={{ color: '#ffffff' }}>{this.state.khanapara_fr}</p>
    </div>

    {/* Item 2 */}
    <div className="bg-white rounded-lg shadow-lg p-4" style={{ background: '#3F1D38' }}>
      <h2 className="text-xl font-semibold" style={{ color: '#ffffff' }}>KHANAPARA TEER - SR</h2>
      <p className="text-3xl text-indigo-800" style={{ color: '#ffffff' }}>{this.state.khanapara_sr}</p>
    </div>

    {/* Item 3 */}
    <div className="bg-white rounded-lg shadow-lg p-4" style={{ background: '#005B41' }}>
      <h2 className="text-xl font-semibold" style={{ color: '#ffffff' }}>SHILLONG TEER - FR</h2>
      <p className="text-3xl text-indigo-800" style={{ color: '#ffffff' }}>{this.state.shilong_fr}</p>
    </div>

    {/* Item 4 */}
    <div className="bg-white rounded-lg shadow-lg p-4" style={{ background: '#04364A' }}>
      <h2 className="text-xl font-semibold" style={{ color: '#ffffff' }}>SHILLONG TEER - SR</h2>
      <p className="text-3xl text-indigo-800" style={{ color: '#ffffff' }}>{this.state.shilong_sr}</p>
    </div>
  </div>


  <div className=" bg-white rounded-lg shadow-lg p-8" style={{ background: '#232D3F'}}>
    

  <div className="flex items-center justify-between mb-6">
      
      {this.state.isLoading && (
          <div className="loading-dialog">
            <div className="loading-spinner"></div>
          </div>
        )}
   
    <Button
        className="h-12 px-6 rounded-md"
        variant="contained"
        style={{ backgroundColor: '#ED7D31', color: 'white' }}
        onClick={() => this.makePayment('1')}
      >
        SEND PAYOUT
      </Button>
   
      <h1 className="text-2xl font-arial text-indigo-800 " style={{ color: '#ffffff' }}>KHANAPARA TEER - FR</h1>

    <Button
        className="h-12 px-6 rounded-md"
        variant="contained"
        style={{ backgroundColor: '#C70039', color: 'white' }}
        onClick={() => this.reversePayment('1')}
      >
        REVERSE PAYOUT
      </Button>
   


    </div>

    <div className="flex items-center justify-between mb-6">
      
      {this.state.isLoading && (
          <div className="loading-dialog">
            <div className="loading-spinner"></div>
          </div>
        )}
   
    <Button
        className="h-12 px-6 rounded-md"
        variant="contained"
        style={{ backgroundColor: '#F29727', color: 'white' }}
        onClick={() => this.makePayment('2')}
      >
         SEND PAYOUT
      </Button>
   
      <h1 className="text-2xl font-Arial text-indigo-800" style={{ color: '#ffffff' }}>KHANAPARA TEER - SR</h1>

    <Button
        className="h-12 px-6 rounded-md"
        variant="contained"
        style={{ backgroundColor: '#C70039', color: 'white' }}
        onClick={() => this.reversePayment('2')}
      >
        REVERSE PAYOUT
      </Button>
   


    </div>

    <div className="flex items-center justify-between mb-6">
      
      {this.state.isLoading && (
          <div className="loading-dialog">
            <div className="loading-spinner"></div>
          </div>
        )}
   
    
    
    <Button
        className="h-12 px-6 rounded-md"
        variant="contained"
        style={{ backgroundColor: '#EA1179', color: 'white' }}
        onClick={() => this.makePayment('3')}
      >
        SEND PAYOUT
      </Button>
    
      <h1 className="text-2xl font-Arial text-indigo-800" style={{ color: '#ffffff' }}>SHILLONG TEER - FR</h1>

    <Button
        className="h-12 px-6 rounded-md"
        variant="contained"
        style={{ backgroundColor: '#C70039', color: 'white' }}
        onClick={() => this.reversePayment('3')}
      >
        REVERSE PAYOUT
      </Button>
   
    </div>

    <div className="flex items-center justify-between mb-6">
      
      {this.state.isLoading && (
          <div className="loading-dialog">
            <div className="loading-spinner"></div>
          </div>
        )}
   
  
    <Button
        className="h-12 px-6 rounded-md"
        variant="contained"
        style={{ backgroundColor: '#1B9C85', color: 'white' }}
        onClick={() => this.makePayment('4')}
      >
        SEND PAYOUT
      </Button>
   
      <h1 className="text-2xl font-Arial text-indigo-800" style={{ color: '#ffffff' }}>SHILLONG TEER - SR</h1>


    <Button
        className="h-12 px-6 rounded-md"
        variant="contained"
        style={{ backgroundColor: '#C70039', color: 'white' }}
        onClick={() => this.reversePayment('4')}
      >
        REVERSE PAYOUT
      </Button>
   


    </div>
  </div>

  
</div>


        
            <div className="flex flex-col" >

            <div className="items-center justify-between mb-6">
            <Table style={{ background: '#232D3F' }}>
                    <TableHeader className="bg-white" style={{ background: '#232D3F'}}>
                        <TableHeading>#</TableHeading>
                        <TableHeading>Name</TableHeading>
                        <TableHeading>Contact Number</TableHeading>
                        <TableHeading>Game Name</TableHeading>
                        <TableHeading>Bid On</TableHeading>
                        <TableHeading>Date</TableHeading>
                        <TableHeading>Win Amount</TableHeading>
                        <TableHeading>Paid</TableHeading>
                    </TableHeader>
                    <TableBody>
        {(this.state.all_histories || []).map((d, index) => {
            const playedAt = moment(d['played_at']).format('DD-MM-YYYY');
            const today = moment(new Date()).format('DD-MM-YYYY');

            if (playedAt === today && d['game_name'] === "KHANAPARA TEER - FR") {
                return (
                    <tr key={d.id}>
                        <TableIndexColumn clickHandler={() => {}} num={index + 1} />
                        <TableTextualColumn text={d['name']} />
                        <TableGreenishTextualColumn text={d['phone']} clickListener={() => window.open("https://wa.me/"+d['phone'],"_blank")} />
                        <TableTextualColumn text={d['game_name']} />
                        <TableTextualColumn text={this.bidOnrenderForExcel(d)} />
                        <TableTextualColumn text={d['played_at']} />
                        <TableTextualColumn text={d['win_amount'] === undefined ? `₹ ${this.teerAmountrender(d)}` : `₹ ${d['win_amount']}`} />
                        <TableTextualColumn text={d['paid'] === undefined ? `₹ ${this.teerAmountrender(d)}` : `₹ ${d['paid']}`} />
                    </tr>
                    );
               }
                  return null; // Exclude rows that don't meet the condition
            })}
           </TableBody>
                </Table>

            </div>

            <div className="items-center justify-between mb-6">
            <Table>
                    <TableHeader className="bg-white">
                        <TableHeading>#</TableHeading>
                        <TableHeading>Name</TableHeading>
                        <TableHeading>Contact Number</TableHeading>
                        <TableHeading>Game Name</TableHeading>
                        <TableHeading>Bid On</TableHeading>
                        <TableHeading>Date</TableHeading>
                        <TableHeading>Win Amount</TableHeading>
                        <TableHeading>Paid</TableHeading>
                    </TableHeader>
                    <TableBody>
        {(this.state.all_histories || []).map((d, index) => {
            const playedAt = moment(d['played_at']).format('DD-MM-YYYY');
            const today = moment(new Date()).format('DD-MM-YYYY');

            if (playedAt === today && d['game_name'] === "KHANAPARA TEER - SR") {
                return (
                    <tr key={d.id}>
                        <TableIndexColumn clickHandler={() => {}} num={index + 1} />
                        <TableTextualColumn text={d['name']} />
                        <TableGreenishTextualColumn text={d['phone']} clickListener={() => window.open("https://wa.me/"+d['phone'],"_blank")} />
                        <TableTextualColumn text={d['game_name']} />
                        <TableTextualColumn text={this.bidOnrenderForExcel(d)} />
                        <TableTextualColumn text={d['played_at']} />
                        <TableTextualColumn text={d['win_amount'] === undefined ? `₹ ${this.teerAmountrender(d)}` : `₹ ${d['win_amount']}`} />
                        <TableTextualColumn text={d['paid'] === undefined ? `₹ ${this.teerAmountrender(d)}` : `₹ ${d['paid']}`} />
                    </tr>
                    );
               }
                  return null; // Exclude rows that don't meet the condition
            })}
           </TableBody>
                </Table>

</div>

<div className="items-center justify-between mb-6">
<Table>
                    <TableHeader className="bg-white">
                        <TableHeading>#</TableHeading>
                        <TableHeading>Name</TableHeading>
                        <TableHeading>Contact Number</TableHeading>
                        <TableHeading>Game Name</TableHeading>
                        <TableHeading>Bid On</TableHeading>
                        <TableHeading>Date</TableHeading>
                        <TableHeading>Win Amount</TableHeading>
                        <TableHeading>Paid</TableHeading>
                    </TableHeader>
                    <TableBody>
        {(this.state.all_histories || []).map((d, index) => {
            const playedAt = moment(d['played_at']).format('DD-MM-YYYY');
            const today = moment(new Date()).format('DD-MM-YYYY');

            if (playedAt === today && d['game_name'] === "SHILLONG TEER - FR") {
                return (
                    <tr key={d.id}>
                        <TableIndexColumn clickHandler={() => {}} num={index + 1} />
                        <TableTextualColumn text={d['name']} />
                        <TableGreenishTextualColumn text={d['phone']} clickListener={() => window.open("https://wa.me/"+d['phone'],"_blank")} />
                        <TableTextualColumn text={d['game_name']} />
                        <TableTextualColumn text={this.bidOnrenderForExcel(d)} />
                        <TableTextualColumn text={d['played_at']} />
                        <TableTextualColumn text={d['win_amount'] === undefined ? `₹ ${this.teerAmountrender(d)}` : `₹ ${d['win_amount']}`} />
                        <TableTextualColumn text={d['paid'] === undefined ? `₹ ${this.teerAmountrender(d)}` : `₹ ${d['paid']}`} />
                    </tr>
                    );
               }
                  return null; // Exclude rows that don't meet the condition
            })}
           </TableBody>
                </Table>

</div>

<div className="items-center justify-between mb-6">
<Table>
                    <TableHeader className="bg-white">
                        <TableHeading>#</TableHeading>
                        <TableHeading>Name</TableHeading>
                        <TableHeading>Contact Number</TableHeading>
                        <TableHeading>Game Name</TableHeading>
                        <TableHeading>Bid On</TableHeading>
                        <TableHeading>Date</TableHeading>
                        <TableHeading>Win Amount</TableHeading>
                        <TableHeading>Paid</TableHeading>
                    </TableHeader>
                 
                    <TableBody>
        {(this.state.all_histories || []).filter(d => {
            const playedAt = moment(d['played_at']).format('DD-MM-YYYY');
            const today = moment(new Date()).format('DD-MM-YYYY');
            return playedAt === today && d['game_name'] === "SHILLONG TEER - SR";
        }).length > 0 ? (
            (this.state.all_histories || []).map((d, index) => {
                const playedAt = moment(d['played_at']).format('DD-MM-YYYY');
                const today = moment(new Date()).format('DD-MM-YYYY');

                if (playedAt === today && d['game_name'] === "SHILLONG TEER - SR") {
                    return (
                        <tr key={d.id}>
                            <TableIndexColumn clickHandler={() => {}} num={index + 1} />
                            <TableTextualColumn text={d['name']} />
                            <TableGreenishTextualColumn text={d['phone']} clickListener={() => window.open("https://wa.me/"+d['phone'],"_blank")} />
                            <TableTextualColumn text={d['game_name']} />
                            <TableTextualColumn text={this.bidOnrenderForExcel(d)} />
                            <TableTextualColumn text={d['played_at']} />
                            <TableTextualColumn text={d['win_amount'] === undefined ? `₹ ${this.teerAmountrender(d)}` : `₹ ${d['win_amount']}`} />
                            <TableTextualColumn text={d['paid'] === undefined ? `₹ ${this.teerAmountrender(d)}` : `₹ ${d['paid']}`} />
                        </tr>
                    );
                }
                return null; // Exclude rows that don't meet the condition
            })
        ) : (
      
            <tr>
                
            </tr>
         
        )}
    </TableBody>

                </Table>

</div>
        
         

               

      
            
         
            </div>

          

          
            </>
    }
}

const mapStateToProps = rootReducer => ({
    clients: rootReducer.clients.currentClients
});

const mapDispatchToProps = dispatch => ({
    actionSetCurrentClients: clients => dispatch(actionSetCurrentClients(clients))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WinnersFragment));
