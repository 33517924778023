import React from "react";
import {Button, TextField} from "@material-ui/core";
import {ACTIVITY_TAG, API_KEY} from "../../../utils/constants";
import axios from "axios";
import {createApiServerRoute} from "../../../models/network/api_client";
import UrlArgsBundle from "../../../core/url_args_bundle";
import {withRouter} from "react-router-dom";

class GameRateFragment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            game_rate1 : 0,
            game_rate2 : 0,
            game_rate3 : 0,
            game_rate4 : 0,
            game_rate5 : 0,
            game_rate6 : 0,
            game_rate7 : 0,
            game_rate8 : 0,
        }
    }


    // urlArgsBundle =  (new UrlArgsBundle()).prepareFrom(this.props.location.search);
    // changeFragment = (fragmentTag) => {
    //     this.urlArgsBundle.setActivityTag(ACTIVITY_TAG.MAIN).setFragmentTag(fragmentTag);
    //     return this.urlArgsBundle.getArgsAsUrlParams()
    // }

    componentDidMount() {
        const params = new URLSearchParams();
        params.append("__api_key__", API_KEY)
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        // let gameType = this.urlArgsBundle.getStringExtra('gameType');
        // let subCategory = this.urlArgsBundle.getStringExtra('subCategory')

        axios.post(createApiServerRoute('fetch_game_rates.php'), params, config)
            .then(res => {
                if (res.data.state === 'OK') {
                    this.setState({
                        ...this.state,
                        game_rate1 : res.data.data.game_rates[0].price,
                        game_rate2 : res.data.data.game_rates[1].price,
                        game_rate3 : res.data.data.game_rates[2].price,
                        game_rate4 : res.data.data.game_rates[3].price,
                        game_rate5 : res.data.data.game_rates[4].price,
                        game_rate6 : res.data.data.game_rates[5].price,
                        game_rate7 : res.data.data.game_rates[6].price,
                        game_rate8 : res.data.data.game_rates[7].price
                    })
                }
            });
    }

    updateGameRate = () => {
        const params = new URLSearchParams();
        params.append("__api_key__", API_KEY)
        params.append("rate1", this.state.game_rate1)
        params.append("rate2", this.state.game_rate2)
        params.append("rate3", this.state.game_rate3)
        params.append("rate4", this.state.game_rate4)
        params.append("rate5", this.state.game_rate5)
        params.append("rate6", this.state.game_rate6)
        params.append("rate7", this.state.game_rate7)
        params.append("rate8", this.state.game_rate8)

        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('set_game_rate2.php'), params, config)
            .then(res => {
                if (res.data.state === 'OK') {
                    window.location.reload();
                    // let tag = this.urlArgsBundle.getStringExtra('gameType') + "_GAMES"
                    // this.props.history.push(this.changeFragment(tag))
                }
            });
    }

    render() {
        return (
            <div className={"py-1 ba-gray-200 grid place-items-center"}>
                <div className="form-card w-96 bg-white py-6 px-10 rounded-2xl shadow-2xl flex flex-col gap-6">
                    <h1 className={"text-center text-indigo-800 text-2xl pb-3"}>Update Game Rate</h1>
                    <TextField fullWidth id="standard-basic" label="KHANAPARA TEER FR" value={this.state.game_rate1} variant="outlined" onChange={(e) => {
                            this.setState({...this.state , game_rate1 : e.target.value})
                    }}
                        />
                        <TextField fullWidth id="standard-basic" label="KHANAPARA TEER SR" value={this.state.game_rate2} variant="outlined" onChange={(e) => {
                         this.setState({...this.state , game_rate2 : e.target.value})
                        }}/>
                        <TextField fullWidth id="standard-basic" label="SHILLONG TEER FR" value={this.state.game_rate3} variant="outlined" onChange={(e) => {
                            this.setState({...this.state , game_rate3 : e.target.value})
                        }}/>
                        <TextField fullWidth id="standard-basic" label="SHILLONG TEER SR" value={this.state.game_rate4} variant="outlined" onChange={(e) => {
                        this.setState({...this.state , game_rate4 : e.target.value})
                        }}/>
                        <TextField fullWidth id="standard-basic" label="KHANAPARA FC" value={this.state.game_rate5} variant="outlined" onChange={(e) => {
                         this.setState({...this.state , game_rate5 : e.target.value})
                        }}/>
                        <TextField fullWidth id="standard-basic" label="SHILLONG FC" value={this.state.game_rate6} variant="outlined" onChange={(e) => {
                         this.setState({...this.state , game_rate6 : e.target.value})
                        }}/>
                        <TextField fullWidth id="standard-basic" label="KHANAPARA BS" value={this.state.game_rate7} variant="outlined" onChange={(e) => {
                         this.setState({...this.state , game_rate7 : e.target.value})
                        }}/>
                        <TextField fullWidth id="standard-basic" label="SHILLONG BS" value={this.state.game_rate8} variant="outlined" onChange={(e) => {
                         this.setState({...this.state , game_rate8 : e.target.value})
                        }}/>
                    <Button className={"h-12 rounded-md"} variant="contained" color="primary" onClick={this.updateGameRate} >
                        Update
                    </Button>
                </div>
            </div>
        );
    }

}

export default withRouter(GameRateFragment)
