import React from "react";
import {FRAGMENT_TAG, DESAWAR_SUB_CATEGORIES, ACTIVITY_TAG, GAME_TYPES} from "../../../utils/constants";
import LeftDigit from "../../../drawable/subcategory_images/left_digit.png";
import RightDigit from "../../../drawable/subcategory_images/right_digit.png";
import DesawarJodiDigit from "../../../drawable/subcategory_images/desawar_jodi_digit.png";
import UrlArgsBundle from "../../../core/url_args_bundle";
import {withRouter} from "react-router-dom";

class DesawarGameSubCategoriesFragment extends React.Component{

    urlArgsBundle = new UrlArgsBundle();
    changeFragment = (fragmentTag) => {
        this.urlArgsBundle.setActivityTag(ACTIVITY_TAG.MAIN).setFragmentTag(fragmentTag);
        return this.urlArgsBundle.getArgsAsUrlParams()
    }

    render() {
        return (
            <div className={"p-10 gap-12 md:p-24 md:gap-16 lg:p-36 flex flex-wrap lg:gap-24 justify-center"}>
                <div className="cursor-pointer w-36 h-36 lg:w-48 lg:h-48" onClick={() => {
                    this.urlArgsBundle.putExtra('subCategory' , DESAWAR_SUB_CATEGORIES.LEFT_DIGIT)
                    this.urlArgsBundle.putExtra('gameType' , GAME_TYPES.DESAWAR)
                    this.props.history.push(this.changeFragment(FRAGMENT_TAG.GAME_RATE))
                }}>
                    <img src={LeftDigit} alt="singleDigit" />
                </div>
                <div className="cursor-pointer w-36 h-36 lg:w-48 lg:h-48" onClick={() => {
                    this.urlArgsBundle.putExtra('gameType' , GAME_TYPES.DESAWAR)
                    this.urlArgsBundle.putExtra('subCategory' , DESAWAR_SUB_CATEGORIES.JODI_DIGIT)
                    this.props.history.push(this.changeFragment(FRAGMENT_TAG.GAME_RATE))
                }}>
                    <img src={DesawarJodiDigit} alt="singleDigit"/>
                </div>
                <div className="cursor-pointer w-36 h-36 lg:w-48 lg:h-48" onClick={() => {
                    this.urlArgsBundle.putExtra('subCategory' , DESAWAR_SUB_CATEGORIES.RIGHT_DIGIT)
                    this.urlArgsBundle.putExtra('gameType' , GAME_TYPES.DESAWAR)
                    this.props.history.push(this.changeFragment(FRAGMENT_TAG.GAME_RATE))
                }}>
                    <img src={RightDigit} alt="singleDigit"/>
                </div>
            </div>
        );
    }

}

export default withRouter(DesawarGameSubCategoriesFragment)