import './mini_stat_card_styles.scss';
import {ArrowDownward, ArrowForward, ArrowUpward} from "@material-ui/icons";

const MiniStatCard = ({icon, label, count, up, bgColor, textualColor}) => {
    return <div className="card mini-stat-card rounded-lg" style={{backgroundColor: bgColor}}>

        <div className="upper-body">
            <div className="card-description">
                <div className="mini-stat-img">
                    <img src={icon} alt="Service"/>
                </div>
                <div className="card-details">
                    <h5 className="card-label mt-0 text-white-50" style={{color: textualColor}}>{label}</h5>
                    <h4 className="value-count" style={{color: textualColor ,fontSize: "40px"}}>
                        {count} 
                    </h4>
                </div>
            </div>
        </div>

       

    </div>
}

export default MiniStatCard;