import React from 'react';
import './fragment_captains_styles.scss';
import axios from 'axios';
import { createApiServerRoute } from "../../../models/network/api_client";
import { connect } from 'react-redux';
import { actionSetCurrentCaptains } from "../../../models/redux/reducers/activity_main_reducers/captains_reducer/action_captains";
import { withRouter } from "react-router-dom";
import TableHeading from "../../../components/table_component/table_heading/table_heading";
import TableHeader from "../../../components/table_component/table_header/table_header";
import Table from "../../../components/table_component/table/table";
import TableBody from "../../../components/table_component/table_body/table_body";
import TableIndexColumn from "../../../components/table_component/table_index_column/table_index_column";
import TableUserNameColumn from "../../../components/table_component/table_user_name_column/table_user_name_column";
import TableTextualColumn from "../../../components/table_component/table_textual_column/table_textual_column";
import TableContainerColumn from "../../../components/table_component/table_container_column/table_container_column";
import TableGreenishTextualColumn from "../../../components/table_component/table_greenish_textual_column/table_greenish_textual_column";
import UrlArgsBundle from "../../../core/url_args_bundle";
import {ACTIVITY_TAG, FRAGMENT_TAG} from "../../../utils/constants";
import IosSwitchMaterialUi from "ios-switch-material-ui";

class CaptainsFragment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: ""
        }
    }

    blockDriver = (driver_id, abracadabra, auth_token) => {
        this.props.actionSetCurrentCaptains(this.props.captains.map(driver => ({
            ...driver,
            blockStatus: driver.id === driver_id ? 'TOGGLING' : driver.blockStatus
        })))
        const params = new URLSearchParams();
        params.append('__driver_id__', driver_id);
        params.append('__abracadabra__', abracadabra);
        params.append('__authorization_token__', auth_token);

        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('toggle_driver_block.php'), params, config)
            .then(res => {
                console.log(res.data);
                this.props.actionSetCurrentCaptains(this.props.captains.map(driver => this.mapToggleDriver(driver, driver_id, res.data.data.new_state)));
            });
    }

    mapToggleDriver = (driver, driver_id, newState) => {
        let o = {
            ...driver
        }

        o['driver'] = {
            ...o.driver
        }

        o['driver']['blocked'] = driver['driver'].id === driver_id ? newState : driver.driver.blocked
        return o;
    }

    componentDidMount() {
        const params = new URLSearchParams();
        params.append('which_ones', 'ALL');

        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };
        axios.post(createApiServerRoute('fetch_drivers.php'), params, config)
            .then(res => {
                console.log(res.data.data)
                this.props.actionSetCurrentCaptains(res.data.data.drivers.map(driver => ({
                    ...driver,
                    blockStatus: 'IDLE'
                })))
            });
    }

    getCaptainProfileFragment = userId => {
        const urlArgsBundle = new UrlArgsBundle();
        urlArgsBundle.setActivityTag(ACTIVITY_TAG.MAIN).setFragmentTag(FRAGMENT_TAG.DRIVER_PROFILE);
        urlArgsBundle.putExtra('USER_ID', userId)
        console.log(urlArgsBundle.getArgsAsUrlParams())
        return urlArgsBundle.getArgsAsUrlParams()
    }

    render() {
        let matchedCaptains = this.props.captains.filter(driver => driver.driver.phone.includes(this.state.filter))


        return <>

            <div className="flex items-center bg-red-500 text-white text-sm font-bold px-4 py-3 m-4" role="alert">
                <svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path
                        d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z"/>
                </svg>
                <p className={"text-white"}>Something happened that you should know about. If you want to delete or edit please context at: <a
                     href="https://wa.me/message/OIS2QUGJKL3SD1">0335-9243703</a> </p>
            </div>

            <div className="flex flex-col">
                <input type="text" value={this.state.filter}
                       onChange={e => {
                           this.setState({...this.state, filter: e.target.value})
                       }}
                       className="mx-8 px-8 py-2 rounded-2xl mb-6 placeholder-indigo-600 outline-none placeholder-opacity-50"
                       placeholder="Enter the number..."/>
                <Table>
                    <TableHeader>
                        <TableHeading>#</TableHeading>
                        <TableHeading>Name</TableHeading>
                        <TableHeading>Phone</TableHeading>
                        <TableHeading>City</TableHeading>
                        <TableHeading>Registration Date</TableHeading>
                        <TableHeading>Blocked</TableHeading>
                    </TableHeader>
                    <TableBody>
                        {matchedCaptains.map((d, index) => <tr key={d.driver.id}>
                            <TableIndexColumn
                                clickHandler={() => this.props.history.push(this.getCaptainProfileFragment(d.driver.id))}
                                num={index + 1}/>
                            <TableUserNameColumn
                                clickHandler={() => this.props.history.push(this.getCaptainProfileFragment(d.driver.id))}
                                avatarUrl={d.avatar.avatar}
                                firstName={d.driver['first_name']}
                                lastName={d.driver['last_name']}
                                email={d.driver.email}/>
                            <TableGreenishTextualColumn text={d.driver.phone}/>
                            <TableTextualColumn text={d['city'].name}/>
                            <TableTextualColumn text={d.driver['created_at']}/>
                            <TableContainerColumn>
                                {/*<Switch onChange={() => {*/}
                                {/*    this.blockDriver(*/}
                                {/*        d.driver.id,d.authorization_token.abracadabra,d.authorization_token.token*/}
                                {/*    )*/}
                                {/*}} color='primary' checked={d.driver.blocked} disabled={d.blockStatus === 'TOGGLING'}/>*/}
                                <IosSwitchMaterialUi
                                    colorKnobOnLeft="black"
                                    colorKnobOnRight="black"
                                    colorSwitch={d.driver.blocked ? "red" : "green"}
                                    knobOnLeft={!d.driver.blocked}
                                    onChange={() => {
                                        this.blockDriver(
                                            d.driver.id, d.authorization_token.abracadabra, d.authorization_token.token
                                        )
                                    }}
                                    disabled={d.blockStatus === 'TOGGLING'}
                                />
                            </TableContainerColumn>
                        </tr>)}
                    </TableBody>
                </Table>
            </div>

        </>
    }
}

const mapStateToProps = rootReducer => ({
    captains: rootReducer.captains.currentCaptains
});

const mapDispatchToProps = dispatch => ({
    actionSetCurrentCaptains: captains => dispatch(actionSetCurrentCaptains(captains))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CaptainsFragment));
