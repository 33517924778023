import { SET_RUNNING_TRIPS } from "./action_set_running_trips";

const INITIAL_STATE = {
    runningTrips: []
}

const setRunningTripsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_RUNNING_TRIPS:
            return {
                ...state,
                runningTrips: action.payload
            }
        default:
            return state;
    }
}

export default setRunningTripsReducer;
