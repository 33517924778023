import {Rating} from "@material-ui/lab";
import './review_card_styles.scss';


const ReviewCard = ({avatarURL,pFirstName,pLastName,dFirstName,dLastName,message,star,date}) => {
  return <div className="text-gray-800 w-1/2 justify-content-center">
    <div className="bg-white w-full max-w-md mx-4 my-12 rounded-xl p-8 pt-10 shadow-sm relative">
      <div className="absolute -top-6 left-8">
        <img src={avatarURL} alt="" className="w-12 h-12 border-2  border-white rounded-full ring ring-green-400" />
      </div>

      <div className="flex  justify-between items-center">
        <span className="text-sm font-medium">{pFirstName} {pLastName} <span className="font-bold text-black">to</span> {dFirstName} {dLastName}</span>
      </div>

      <p className="mt-5  text-sm break-words leading-relaxed">{message}
      </p>

      <hr className="border-0  border-b my-6" />

      <div className="flex text-xs  text-gray-500 justify-between">
        <div className="flex  items-center space-x-3">
          <Rating name="read-only" value={star} readOnly />
        </div>
        <div className="flex  space-x-2 items-center">
          <i className="text-gray-400 far fa-calendar"></i>
          <span>{date}</span>
        </div>
      </div>
    </div>
  </div>
}

export default ReviewCard;