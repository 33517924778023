import React from "react";
import './app_bar_styles.scss';
import {
    ExitToApp, Settings
} from "@material-ui/icons";
import {withRouter} from "react-router-dom";
import UrlArgsBundle from "../../core/url_args_bundle";
import {ACTIVITY_TAG, FRAGMENT_TAG} from "../../utils/constants";

class AppBar extends React.Component {

    urlArgsBundle =  (new UrlArgsBundle()).prepareFrom(this.props.location.search);
    changeFragment = (fragmentTag) => {
        this.urlArgsBundle.setActivityTag(ACTIVITY_TAG.MAIN).setFragmentTag(fragmentTag);
        return this.urlArgsBundle.getArgsAsUrlParams()
    }

    render() {
        return <div className="app-bar rounded-t-3xl mx-12">
            <div className="l-h-s">
                <span>Application</span>
                &gt; {this.props.fragmentTitle}
            </div>
            <div className="r-h-s">
                <Settings className="menu-icon mr-8 cursor-pointer" style={{transform: "scale(1.5)"}}
                onClick={() => {
                    this.props.history.push(this.changeFragment(FRAGMENT_TAG.CHANGE_PASSWORD))
                }}
                />
                <ExitToApp className="menu-icon cursor-pointer" style={{transform: "scale(1.5)"}} onClick={() => {
                    localStorage.removeItem('user')
                    window.location.reload()
                }}/>
            </div>
        </div>
    }
}

export default withRouter(AppBar);