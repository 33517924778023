import React from "react";
import './fragment_driver_profile_styles.scss';
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";
import axios from 'axios';
import {createApiServerRoute} from "../../../models/network/api_client";
import {actionSetAddDriverWallet} from "../../../models/redux/reducers/activity_main_reducers/add_driver_wallet_reducer/action_add_driver_wallet";
import UrlArgsBundle from "../../../core/url_args_bundle";

class DriverProfileFragment extends React.Component {

    addAmountInWallet = () => {
        const params = new URLSearchParams();
        params.append('__driver_id__', this.state.captain.driver.id);
        params.append('__abracadabra__', this.state.captain.authorization_token.abracadabra);
        params.append('__authorization_token__', this.state.captain.authorization_token.token);
        params.append('amount', this.state.input_amount);

        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('add_amount_to_driver_wallet.php'), params, config)
            .then(res => {
                this.setState({
                    ...this.state,
                    input_amount: ''
                })
                this.props.actionSetAddDriverWallet(res.data.data.newAmount)
            });
    }

    componentDidMount() {
        const params = new URLSearchParams();
        params.append('__driver_id__', this.state.captain.driver.id);
        params.append('__abracadabra__', this.state.captain.authorization_token.abracadabra);
        params.append('__authorization_token__', this.state.captain.authorization_token.token);

        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('get_driver_wallet_amount.php'), params, config)
            .then(res => {
                console.log(res.data);
                this.props.actionSetAddDriverWallet(res.data.data.wallet)
            });
    }

    constructor(props) {
        super(props);
        const urlArgsBundle = new UrlArgsBundle().prepareFrom(this.props.location.search);
        this.state = {
            Value: "About",
            input_amount: '',
            captain : this.props.captains.filter(captain => captain.driver.id === urlArgsBundle.getStringExtra('USER_ID'))[0]
        }
    }

    changeState = (activename) => {
        this.setState(
            {
                ...this.state,
                Value: activename
            }
        )
    }
    
    getRideCategoryCards = (ride_categories) => {
        return ride_categories.map(rideCategory => (
            <div key={rideCategory.id}
                 className={`div ${this.state.Value === "Categories" && "active"}`} id="category-card">
                <div className="cat-img">
                    <img src={rideCategory.image} alt="category-img"/>
                </div>
                <div className="cate-info">
                    <p className="category-name">{rideCategory.name}</p>
                    <p>Enabled{rideCategory.enabled}</p>
                    <p>Per Km Cost:<span>{rideCategory.per_km_cost}</span></p>
                    <p>Price:<span>{rideCategory.price}</span></p>
                </div>
            </div>
        ));
    }
Cookies
    getCityToCityRideCategoryCards = (ride_categories) => {
        return ride_categories.map(rideCategory => (
            <div key={rideCategory.id}
                 className={`div ${this.state.Value === "CityToCity" && "active"}`} id="category-card">
                <div className="cat-img">
                    <img src={rideCategory.image} alt="category-img"/>
                </div>
                <div className="cate-info">
                    <p className="category-name">{rideCategory.name}</p>
                    <p>Enabled{rideCategory.enabled}</p>
                    <p>Per Km Cost:<span>{rideCategory.per_km_cost}</span></p>
                    <p>Price:<span>{rideCategory.price}</span></p>
                </div>
            </div>
        ));
    }

    render() {
        return (
            <div className="profile-fragment">
                <img className="profile-img border-indigo-800 border-4" src={this.state.captain.avatar.avatar} alt="avatar"/>
                <h1 className="profile-heading text-indigo-800">{this.state.captain.driver.first_name} {this.state.captain.driver.last_name}</h1>
                <p className="profile-email">{this.state.captain.driver.email}</p>

                <ul className="nav">
                    <li className={`duration-500 text-indigo-800 hover:bg-indigo-800 hover:text-white py-1 px-4 my-auto rounded-lg ${this.state.Value === "About" && "borders"}`}
                        onClick={() => this.changeState("About")}>About
                    </li>


                    <li className={`duration-500 text-indigo-800 hover:bg-indigo-800 hover:text-white py-1 px-4 my-auto rounded-lg ${this.state.Value === "Categories" && "borders"}`}
                        onClick={() => this.changeState("Categories")}>Ride Categories
                    </li>

                    <li className={`duration-500 text-indigo-800 hover:bg-indigo-800 hover:text-white py-1 px-4 my-auto rounded-lg ${this.state.Value === "Categories" && "borders"}`}
                        onClick={() => this.changeState("CityToCity")}>City To City
                    </li>


                    <li className={`duration-500 text-indigo-800 hover:bg-indigo-800 hover:text-white py-1 px-4 my-auto rounded-lg ${this.state.Value === "CNIC" && "borders"}`}
                        onClick={() => this.changeState("CNIC")}>CNIC
                    </li>


                    <li className={`duration-500 text-indigo-800 hover:bg-indigo-800 hover:text-white py-1 px-4 my-auto rounded-lg ${this.state.Value === "License" && "borders"}`}
                        onClick={() => this.changeState("License")}>License
                    </li>

                    <li className={`duration-500 text-indigo-800 hover:bg-indigo-800 hover:text-white py-1 px-4 my-auto rounded-lg ${this.state.Value === "Plate" && "borders"}`}
                        onClick={() => this.changeState("Plate")}>Number Plate
                    </li>

                    <li className={`duration-500 text-indigo-800 hover:bg-indigo-800 hover:text-white py-1 px-4 my-auto rounded-lg ${this.state.Value === "Plate" && "borders"}`}
                        onClick={() => this.changeState("Book")}>Registration Book
                    </li>

                    <li className={`duration-500 text-indigo-800 hover:bg-indigo-800 hover:text-white py-1 px-4 my-auto rounded-lg ${this.state.Value === "Plate" && "borders"}`}
                        onClick={() => this.changeState("Wallet")}>Wallet
                    </li>
                </ul>

                <div id="Wallet" className={`div ${this.state.Value === "Wallet" && "active"}`}>
                    <p>Amount : {this.props.wallet}</p>
                    <div className="box">
                        <input type="text" value={this.state.input_amount} placeholder="Add Amount" onChange={(event) => {
                            this.setState({
                                ...this.state,
                                input_amount: event.target.value
                            })
                        }} />
                        <button onClick={this.addAmountInWallet}>ADD</button>
                    </div>
                </div>

                <div className={`div ${this.state.Value === "About" && "active"}`} id="City-div">
                    <p className="city">City: {this.state.captain.city.name}</p>
                    <p>Phone: {this.state.captain.driver.phone}</p>
                    <p>Member Since: {this.state.captain.driver.created_at}</p>
                </div>

                {this.getRideCategoryCards(this.state.captain.ride_categories)}

                {this.getCityToCityRideCategoryCards(this.state.captain.city_to_city_ride_categories)}

                 <div id="CNIC" className={`div ${this.state.Value === "CNIC" && "active"}`}>
                     {this.state.captain.cnic === null ? "No image Found" :<><div className="front">
                        Front
                        <img src={this.state.captain.cnic.cnic_front} alt="Front-img"/>
                    </div>
                    <div className="back">
                        Back
                        <img src={this.state.captain.cnic.cnic_back} alt="Front-img"/>
                    </div></>
                }
                 </div>

                <div id="CNIC" className={`div ${this.state.Value === "License" && "active text-center"}`}>
                    {this.state.captain.license === null ? "No image Found" :<><div className="front">
                        Front
                        <img src={this.state.captain.license.license_front} alt="Front-img"/>
                    </div>
                        <div className="back">
                        Back
                        <img src={this.state.captain.license.license_back} alt="Front-img"/>
                    </div></>}
                </div>

                <div id="Numberplate" className={`div ${this.state.Value === "Plate" && "active"}`}>
                    {this.state.captain.number_plate === null ? "No image Found"
                        :<img className="w-full" src={this.state.captain.number_plate.number_plate} alt=""/>}
                </div>

                <div id="Numberplate" className={`div ${this.state.Value === "Book" && "active"}`}>
                    {this.state.captain.registration_book === null ? "No image Found"
                        :<img className="w-full" src={this.state.captain.registration_book.registration_book} alt=""/>}
                </div>
            </div>
        );
    }
}

const mapStateToProps = rootReducer => ({
    captains: rootReducer.captains.currentCaptains,
    wallet: rootReducer.driver_wallet.wallet
});

const mapDispatchToProps = dispatch => ({
    actionSetAddDriverWallet: wallet => dispatch(actionSetAddDriverWallet(wallet))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DriverProfileFragment));