import React from "react";
import {Button, FormControl, FormControlLabel, Switch, TextField} from "@material-ui/core";
import {Stack} from "@mui/material";
import {API_KEY} from "../../../utils/constants";
import axios from "axios";
import {createApiServerRoute} from "../../../models/network/api_client";

class SettingFragment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            autoChartUpdate: false,
            autoUserApproval: false,
            customPopupMessage: false,
            appExpiry: false,
            contactNumber: null,
            phoneNumber: null,
            whatsappNumber: null,
            telegramNumber: null,
            email: null,
            description: null,
            youtube_link: null,
        }
    }

    onAutoChartUpdateChanged = () => {
        const params = new URLSearchParams();
        params.append("__api_key__", API_KEY)
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('toggle_chart_auto_update.php'), params, config)
            .then(res => {
                if (res.data.state === 'OK') {
                    this.setState({
                        ...this.state,
                        autoChartUpdate : !this.state.autoChartUpdate
                    })
                }
            });
    }

    onCustomPopupMessageChanged = () => {
        const params = new URLSearchParams();
        params.append("__api_key__", API_KEY)
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('toggle_custom_popup_message.php'), params, config)
            .then(res => {
                if (res.data.state === 'OK') {
                    this.setState({
                        ...this.state,
                        customPopupMessage : !this.state.customPopupMessage
                    })
                }
            });
    }

    onAutoUserApprovalChanged = () => {
        const params = new URLSearchParams();
        params.append("__api_key__", API_KEY)
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('toggle_auto_approve_user.php'), params, config)
            .then(res => {
                if (res.data.state === 'OK') {
                    this.setState({
                        ...this.state,
                        autoUserApproval : !this.state.autoUserApproval
                    })
                }
            });
    }

    onAppExpiryChanged = () => {
        const params = new URLSearchParams();
        params.append("__api_key__", API_KEY)
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('toggle_app_expiry.php'), params, config)
            .then(res => {
                if (res.data.state === 'OK') {
                    this.setState({
                        ...this.state,
                        appExpiry : !this.state.appExpiry
                    })
                }
            });
    }

    onDataUpdate = () => {
        const params = new URLSearchParams();
        params.append("__api_key__", API_KEY)
        params.append("contact_number", this.state.contactNumber)
        params.append("phone_number", this.state.phoneNumber)
        params.append("whatsapp_number", this.state.whatsappNumber)
        params.append("telegram_number", this.state.telegramNumber)
        params.append("email", this.state.email)
        params.append("description", this.state.description)
        params.append("youtube_link", this.state.youtube_link)
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('update_contact_number.php'), params, config)
            .then(res => {
                console.log(res.data.data.meta)
                if (res.data.state === 'OK') {
                    this.setState({
                        ...this.state,
                        autoChartUpdate: res.data.data.meta.chart_auto_update,
                        autoUserApproval: res.data.data.meta.auto_approve_users,
                        customPopupMessage: res.data.data.meta.custom_popup_message,
                        appExpiry: res.data.data.meta.app_expiry,
                        contactNumber: res.data.data.meta.contact_number,
                        phoneNumber: res.data.data.meta.mobile_number,
                        whatsappNumber: res.data.data.meta.whatsapp_number,
                        telegramNumber: res.data.data.meta.telegram_number,
                        email: res.data.data.meta.email,
                        description: res.data.data.meta.description,
                        youtube_link: res.data.data.meta.youtube_link,
                    })
                    window.location.reload()
                }
            });
    }

    componentDidMount() {
        const params = new URLSearchParams();
        params.append("__api_key__", API_KEY)
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('fetch_contact_us_meta.php'), params, config)
            .then(res => {
                if (res.data.state === 'OK') {
                    console.log(res.data.data.meta)
                    this.setState({
                        ...this.state,
                        autoChartUpdate: res.data.data.meta.chart_auto_update,
                        autoUserApproval: res.data.data.meta.auto_approve_users,
                        customPopupMessage: res.data.data.meta.custom_popup_message,
                        appExpiry: res.data.data.meta.app_expiry,
                        contactNumber: res.data.data.meta.contact_number,
                        phoneNumber: res.data.data.meta.mobile_number,
                        whatsappNumber: res.data.data.meta.whatsapp_number,
                        telegramNumber: res.data.data.meta.telegram_number,
                        email: res.data.data.meta.email,
                        description: res.data.data.meta.description,
                        youtube_link: res.data.data.meta.youtube_link,
                    })
                }
            });
    }

    render() {
        return <div className={"py-1 ba-gray-200 grid place-items-center"}>
            <div className="form-card w-1/3 bg-white py-6 px-10 rounded-2xl shadow-2xl flex flex-col gap-6">
                <h1 className={"text-center text-indigo-800 text-2xl pb-3"}>Settings</h1>

                <FormControl component="fieldset">
                    <FormControlLabel
                        className={"justify-center"}
                        value="Auto Chart Update"
                        control={<Switch color="primary" checked={this.state.autoChartUpdate} onChange={this.onAutoChartUpdateChanged} />}
                        label="Auto Chart Update"
                        labelPlacement="Auto Chart Update" />
                </FormControl>
                <FormControl component="fieldset">
                    <FormControlLabel
                        className={"justify-center"}
                        value="Custom Popup Message"
                        control={<Switch color="primary" checked={this.state.customPopupMessage} onChange={this.onCustomPopupMessageChanged} />}
                        label="Custom Popup Message"
                        labelPlacement="Custom Popup Message" />
                </FormControl>
                <FormControl component="fieldset">
                    <FormControlLabel
                        className={"justify-center"}
                        value="Auto Approve Users"
                        control={<Switch color="primary" checked={this.state.autoUserApproval} onChange={this.onAutoUserApprovalChanged} />}
                        label="Auto Approve Users"
                        labelPlacement="Auto Approve Users" />
                </FormControl>
                <FormControl component="fieldset">
                    <FormControlLabel
                        className={"justify-center"}
                        value="App Expiry"
                        control={<Switch color="primary" checked={this.state.appExpiry} onChange={this.onAppExpiryChanged} />}
                        label="App Expiry"
                        labelPlacement="App Expiry" />
                </FormControl>
                <Stack direction={"row"}>
                    <TextField fullWidth id="outlined-basic" value={this.state.contactNumber || ''} label="Contact Number" variant="outlined"
                        onChange={e => {
                            this.setState({
                                ...this.state,
                                contactNumber : e.target.value
                            })
                        }}/>
                    <Button sx={{p: 50}} size={'large'} variant="contained" className={"px-6"} color={"primary"} onClick={() => this.onDataUpdate()}>Update</Button>
                </Stack>
                {/* <Stack direction={"row"}>
                    <TextField fullWidth id="outlined-basic" value={this.state.phoneNumber || ''} label="Phone Number" variant="outlined"
                       onChange={e => {
                           this.setState({
                               ...this.state,
                               phoneNumber : e.target.value
                           })
                       }}/>
                    <Button sx={{p: 50}} size={'large'} variant="contained" className={"px-6"} color={"primary"} onClick={this.onDataUpdate}>Update</Button>
                </Stack> */}
                <Stack direction={"row"}>
                    <TextField fullWidth id="outlined-basic" value={this.state.whatsappNumber || ''} label="WhatsApp Number" variant="outlined"
                       onChange={e => {
                           this.setState({
                               ...this.state,
                               whatsappNumber : e.target.value
                           })
                       }}/>
                    <Button sx={{p: 50}} size={'large'} variant="contained" className={"px-6"} color={"primary"} onClick={() => this.onDataUpdate()}>Update</Button>
                </Stack>
                <Stack direction={"row"}>
                    <TextField fullWidth id="outlined-basic" value={this.state.telegramNumber || ''} label="Telegram Number" variant="outlined"
                       onChange={e => {
                           this.setState({
                               ...this.state,
                               telegramNumber : e.target.value
                           })
                       }}/>
                    <Button sx={{p: 50}} size={'large'} variant="contained" className={"px-6"} color={"primary"} onClick={() => this.onDataUpdate()}>Update</Button>
                </Stack>
                <Stack direction={"row"}>
                    <TextField fullWidth id="outlined-basic" value={this.state.email || ''} label="Email" variant="outlined"
                       onChange={e => {
                           this.setState({
                               ...this.state,
                               email : e.target.value
                           })
                       }}/>
                    <Button sx={{p: 50}} size={'large'} variant="contained" className={"px-6"} color={"primary"} onClick={() => this.onDataUpdate()}>Update</Button>
                </Stack>
                <Stack direction={"row"}>
                    <TextField fullWidth id="outlined-basic" value={this.state.youtube_link || ''} label="Youtube Link" variant="outlined"
                       onChange={e => {
                           this.setState({
                               ...this.state,
                               youtube_link : e.target.value
                           })
                       }}/>
                    <Button sx={{p: 50}} size={'large'} variant="contained" className={"px-6"} color={"primary"} onClick={() => this.onDataUpdate()}>Update</Button>
                </Stack>
            </div>
        </div>

    }
}

export default SettingFragment
