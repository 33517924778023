import React from 'react';
import './fragment_users_styles.scss';
import axios from 'axios';
import { createApiServerRoute } from "../../../models/network/api_client";
import { connect } from 'react-redux';
import { actionSetCurrentClients } from "../../../models/redux/reducers/activity_main_reducers/clients_reducer/action_clients";
import Table from "../../../components/table_component/table/table";
import TableHeader from "../../../components/table_component/table_header/table_header";
import TableBody from "../../../components/table_component/table_body/table_body";
import TableIndexColumn from "../../../components/table_component/table_index_column/table_index_column";
import TableUserNameColumn from "../../../components/table_component/table_user_name_column/table_user_name_column";
import TableGreenishTextualColumn from "../../../components/table_component/table_greenish_textual_column/table_greenish_textual_column";
import TableTextualColumn from "../../../components/table_component/table_textual_column/table_textual_column";
import TableContainerColumn from "../../../components/table_component/table_container_column/table_container_column";
import TableHeading from "../../../components/table_component/table_heading/table_heading";
import UrlArgsBundle from "../../../core/url_args_bundle";
import {ACTIVITY_TAG, API_KEY, FRAGMENT_TAG} from "../../../utils/constants";
import {withRouter} from "react-router-dom";
import IosSwitchMaterialUi from 'ios-switch-material-ui';
import TableBankColumn
    from "../../../components/table_component/table_bank_column/table_bank_column";
import AddFund from '../../../components/add_fund_component/add_fund';


// Define the number of users to display per page
const USERS_PER_PAGE = 20;

class UsersFragment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: "",
            searchResults: [],
            currentPage: 1, // Initialize current page to 1
            totalPageCount : 0,
        }
    }

   
      
    componentDidMount() {
        const params = new URLSearchParams();
        params.append("__api_key__", API_KEY)
        params.append("searchtext", "")
        params.append("page", "1")
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('fetch_user_with_search.php'), params, config)
            .then(res => {
                if (res.data.state === 'OK') {
                    this.props.actionSetCurrentClients(res.data.data.users.map(user => ({
                        ...user
                    })))
                    this.setState({ totalPageCount: res.data.data.totalPages });
                    console.log(res.data.data.totalPages)

                } else {
                    console.log(res)
                }
            });
    }

    getUsersByPage = (pageNo,searchtext) => {
        const params = new URLSearchParams();
        params.append("__api_key__", API_KEY)
        params.append("searchtext", searchtext)
        params.append("page", pageNo)
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('fetch_user_with_search.php'), params, config)
            .then(res => {
                if (res.data.state === 'OK') {
                    this.props.actionSetCurrentClients(res.data.data.users.map(user => ({
                        ...user
                    })))
                    this.setState({ totalPageCount: res.data.data.totalPages });
                    console.log(res.data.data.totalPages)

                } else {
                    console.log(res)
                }
            });
    }
    

    toggleUserApproval = (userUid) => {
        const params = new URLSearchParams();
        params.append("__api_key__", API_KEY)
        params.append("user_uid", userUid)
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('toggle_user_approval.php'), params, config)
            .then(res => {
                if (res.data.state === 'OK') {
                    this.props.actionSetCurrentClients(this.props.clients.map(user => {
                        if (user.uid === userUid) {
                            user.approved = !user.approved
                        }
                        return user
                    }))
                }
            });
    }

    refreshUsersFragment = () => {
        // Your logic to refresh the UsersFragment goes here
        console.log('Refreshing UsersFragment...');
        // For example, you can call getUsersByPage or any other logic to refresh the data.
        this.getUsersByPage(this.state.currentPage, this.state.filter);
    };

    getUserProfileFragment = userId => {
        const urlArgsBundle = new UrlArgsBundle();
        urlArgsBundle.setActivityTag(ACTIVITY_TAG.MAIN).setFragmentTag(FRAGMENT_TAG.USER_PROFILE);
        urlArgsBundle.putExtra('USER_ID' , userId)
        console.log(urlArgsBundle.getArgsAsUrlParams())
        return urlArgsBundle.getArgsAsUrlParams()
    }

    render() {

        let user = JSON.parse(localStorage.getItem('user'))
        const { filter, searchResults, currentPage,totalPageCount } = this.state;
        const startIndex = (currentPage - 1) * USERS_PER_PAGE;
        const endIndex = startIndex + USERS_PER_PAGE;
       // Create a new array with all filtered clients
    const filteredClients = this.props.clients.filter(client => 
        client.phone.includes(filter) || client.username.toLowerCase().includes(filter.toLowerCase())
    );

    // const totalPageCount = Math.ceil(filteredClients.length / USERS_PER_PAGE);

    // Map the clients within the current page
    const matchedClients = filteredClients.slice(startIndex, endIndex);

    const clientsToDisplay = searchResults.length > 0 ? searchResults : this.props.clients;


        console.log(this.props.clients)
        return <>

            <div className="flex flex-col" 
            >
               
            <div className="flex items-center mb-4 ml-4" >
    <input
        type="text"
        value={this.state.filter}
        onChange={(e) => this.setState({ ...this.state, filter: e.target.value })}
        className="mx-2 px-4 py-2 rounded-2xl border-2 border-blue outline-none placeholder-indigo-600 placeholder-opacity-50 custom-search-input"
        style={{ background: 'white',color :'black', fontSize: '16px' }} 
        placeholder="Enter username or phone number"
    />

    <button
        className="ml-2 px-5 py-2 rounded-3xl bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-400"
        onClick={() => this.getUsersByPage("1", this.state.filter)}
    >
        Search
    </button>

    <button
        className="ml-2 px-5 py-2 rounded-3xl bg-red-500 text-white hover:bg-red-600 focus:outline-none focus:ring focus:border-red-400"
        onClick={() => {
            this.setState({ filter: "" }); // Clear the input value
            this.getUsersByPage("1", ""); // Reset the search with an empty filter
        }}
    >
        Reset
    </button>


</div>

           
                <Table>
                    <TableHeader>
                        <TableHeading>#</TableHeading>
                        <TableHeading>Username</TableHeading>
                        <TableHeading>Phone (click to open whatsapp)</TableHeading>
                        <TableHeading>Bank Detail</TableHeading>
                        <TableHeading>Payment ID's</TableHeading>
                        <TableHeading>Add Funds</TableHeading>
                        <TableHeading>Wallet</TableHeading>
                        {
                            user === null ? '' : (user['type'] === 'MAIN' ? <TableHeading>Approved</TableHeading> : '')
                        }
                    </TableHeader>
                    <TableBody>
                        {clientsToDisplay.map((d, index) => <tr key={d.id}>
                            <TableIndexColumn clickHandler={() => {}} num={startIndex + index + 1} />
                            <TableUserNameColumn
                                clickHandler={() => {}}
                                avatarUrl={d['avatar']} firstName={d['username']} lastName={''}
                                email={d['email']} />
                            <TableGreenishTextualColumn text={d['phone']} clickListener={() => window.open("https://wa.me/"+d['phone'],"_blank")} />
                            <TableBankColumn upperName={'Bank: ' + d['bank_name'] +'\nAcc. Num. : ' + d['account_number']} lowerName={'Acc. Holder : ' + d['account_holder_name'] + '\nifsc Code: ' + d['ifsc_code']} clickHandler={() => {}} />
                            <TableBankColumn upperName={'Phonepe | Paytm Number | Google Pay '} lowerName={d['phonepe_uid'] + ' | ' + d['paytm_number'] + ' | ' + d['googlepay_uid']} clickHandler={() => {}} />

                        <AddFund userUid={d['uid']} searchText={this.state.filter} currentPage = {this.state.currentPage} refreshUsersFragment={this.refreshUsersFragment} 
                          
                        />        
                            <TableBody>

                    
                    </TableBody>
                            <TableTextualColumn text={d['wallet']} />
                            {
                                user === null ? '' : (user['type'] === 'MAIN' ? <TableContainerColumn>
                                    <IosSwitchMaterialUi
                                        colorKnobOnLeft="black"
                                        colorKnobOnRight="black"
                                        colorSwitch={d['approved'] ? "#1C3FAA" : "lightgray"}
                                        knobOnLeft={!d['approved']}
                                        onChange={() => this.toggleUserApproval(d['uid'])}
                                        disabled={d['approved'] === 'TOGGLING'}
                                    />
                                </TableContainerColumn> : '')
                            }
                        </tr>)}
                    </TableBody>    
                </Table>
            </div>

            <div className="pagination-container">
                <button
                    className={`pagination-button${currentPage === 1 ? ' disabled' : ''}`}
                    onClick={() => {
                        this.setState({ currentPage: Math.max(currentPage - 1, 1)})
                        this.getUsersByPage(Math.min(currentPage - 1),"");
                    }}
                    disabled={currentPage === 1}
                >
                    Previous
                </button>
                <span className="current-page">
                    Page {currentPage} / {totalPageCount}
                </span>
                <button
                    className={`pagination-button${currentPage >= totalPageCount ? ' disabled' : ''}`}
                    onClick={() => {
                        this.setState({ currentPage: Math.min(currentPage + 1, totalPageCount) });
                        this.getUsersByPage(Math.min(currentPage + 1),"");
                    }}
                    disabled={currentPage >= totalPageCount}
                >
                    Next
                </button>
            </div>
            </>
    }
}

const mapStateToProps = rootReducer => ({
    clients: rootReducer.clients.currentClients
});

const mapDispatchToProps = dispatch => ({
    actionSetCurrentClients: clients => dispatch(actionSetCurrentClients(clients))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UsersFragment));
