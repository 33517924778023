import React from "react";
import {Button, TextField} from "@material-ui/core";
import {TimePicker} from "antd";
import {ACTIVITY_TAG, API_KEY, FRAGMENT_TAG} from "../../../utils/constants";
import axios from "axios";
import {createApiServerRoute} from "../../../models/network/api_client";
import UrlArgsBundle from "../../../core/url_args_bundle";
import {withRouter} from "react-router-dom";
import moment from 'moment';

class UpdateStandardGameFragment extends React.Component{

    urlArgsBundle = (new UrlArgsBundle()).prepareFrom(this.props.location.search);

    getFragment = (fragmentTag) => {
        this.urlArgsBundle.setActivityTag(ACTIVITY_TAG.MAIN).setFragmentTag(fragmentTag);
        this.props.history.push(this.urlArgsBundle.getArgsAsUrlParams())
    }

    constructor(props) {
        super(props);
        this.state = {
            uid : "",
            name: "",
            open_time_hour: "7",
            close_time_hour: "7",
            open_time_minute: "15",
            close_time_minute: "15",
            open_time_type: "AM",
            close_time_type: "AM",
            sort_position: ""
        }
    }

    componentDidMount() {

        const params = new URLSearchParams();
        params.append("__api_key__", API_KEY)
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('get_all_games_meta.php'), params, config)
            .then(res => {
                if (res.data.state === 'OK') {
                    console.log(this.urlArgsBundle.getStringExtra('game_uid'))
                     res.data.data.standardGames.forEach(standardGame => {
                            if (standardGame.uid === this.urlArgsBundle.getStringExtra('game_uid')) {
                                let to = standardGame.open_time.split(' ')
                                let tc = standardGame.close_time.split(' ')
                                console.log(standardGame)
                                this.setState({
                                    ...this.state,
                                    uid: standardGame.uid,
                                    name: standardGame.name,
                                    open_time_hour: to[0].split(':')[0],
                                    open_time_minute: to[0].split(':')[1],
                                    open_time_type: to[1],
                                    close_time_hour: tc[0].split(':')[0],
                                    close_time_minute: tc[0].split(':')[1],
                                    close_time_type: tc[1],
                                    sort_position: standardGame.sort_position
                                })
                            }
                        })
                }
            });
    }

    updateGame = () => {



        if (this.state.uid !== '') {
            const params = new URLSearchParams();
            console.log("condition true")

            params.append("__api_key__", API_KEY)
            params.append("name", this.state.name)
            params.append("standard_game_uid", this.state.uid)
            params.append("open_time_hour", this.state.open_time_hour)
            params.append("open_time_minute", this.state.open_time_minute)
            params.append("open_time_am_or_pm", this.state.open_time_type)
            params.append("close_time_hour", this.state.close_time_hour)
            params.append("close_time_minute", this.state.close_time_minute)
            params.append("close_time_am_or_pm", this.state.close_time_type)
            params.append("sort_position", this.state.sort_position)

            const config = {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                }
            };

            axios.post(createApiServerRoute('update_standard_game.php'), params, config)
                .then(res => {
                    console.log("result fetched")

                    console.log(res)
                    if (res.data.state === 'OK') {
                        console.log("result OK")
                        this.getFragment(FRAGMENT_TAG.STANDARD_GAME)
                    } else {
                        console.log("result NOT OK")
                    }
                })
        }
    }


    render() {

        return <div className={"py-36 ba-gray-200 grid place-items-center"}>
            <div className="form-card w-96 bg-white py-6 px-10 rounded-2xl shadow-2xl flex flex-col gap-6">
                <h1 className={"text-center text-indigo-800 text-2xl pb-3"}>Update Standard Game</h1>
                <TextField fullWidth id="standard-basic" label="Name" value={this.state.name} variant="outlined"
                           onChange={(e) => {
                               this.setState({...this.state , name : e.target.value})
                           }}/>

                <TimePicker
                    className={"h-14 rounded-md"}
                    value={moment(this.state.open_time_hour + ":" + this.state.open_time_minute + " " + this.state.open_time_type , 'h:mm a')}
                    format={'h:mm a'}
                    placeholder={'Open Time'}
                    onChange={(time, timeString) => {
                        let t = timeString.split(' ')
                        this.setState({
                            ...this.state,
                            open_time_hour: t[0].split(':')[0],
                            open_time_minute: t[0].split(':')[1],
                            open_time_type: t[1].toUpperCase()
                        })
                    }}
                />

                <TimePicker
                    className={"h-14 rounded-md"}
                    value={moment(this.state.close_time_hour + ":" + this.state.close_time_minute + " " + this.state.close_time_type , 'h:mm a')}
                    format={'h:mm a'}
                    placeholder={'Close Time'}
                    onChange={(time, timeString) => {
                        let t = timeString.split(' ')

                        this.setState({
                            ...this.state,
                            close_time_hour: t[0].split(':')[0],
                            close_time_minute: t[0].split(':')[1],
                            close_time_type: t[1].toUpperCase()
                        })
                    }}
                />

                <TextField fullWidth id="standard-basic" value={this.state.sort_position}
                           label="Sort Position" variant="outlined"
                           inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} onChange={(e) => {
                    const re = /[0-9]+/g;
                    if (e.target.value === '' || re.test(e.target.value)) {
                        this.setState({...this.state , sort_position : e.target.value})
                    }}} />
                <Button className={"h-12 rounded-md"} variant="contained" color="primary" onClick={this.updateGame}>Update</Button>
            </div>
        </div>
    }

}

export default withRouter(UpdateStandardGameFragment)