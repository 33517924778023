import React from "react";
import './floating_action_button_styles.scss';

const FloatingActionButton = ({clickHandler, icon}) => {
    return <div className="cab5-fab-icon absolute w-16 h-16 rounded-full flex justify-center items-center text-5xl right-7 bottom-7 cursor-pointer"
                onClick={clickHandler}>
        {icon}
    </div>
}

export default FloatingActionButton;