import React from "react";

import MiniStatCard from "../mini_stat_card/mini_stat_card";
import {createApiServerRoute} from "../../../models/network/api_client";
import axios from "axios";
import {API_KEY} from "../../../utils/constants";

class MiniStatCardList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            users : 0,
            totalWithdraws: 0,
            totalFunds: 0,
        }
    }

    componentDidMount() {
        const params = new URLSearchParams();
        params.append("__api_key__", API_KEY)

        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('fetch_dashboard_meta.php'), params, config)
            .then(res => {
                this.setState({
                    ...this.state,
                    users: res.data.data.meta['users'],
                    totalWithdraws: res.data.data.meta['total_withdraws'],
                    totalFunds: res.data.data.meta['total_funds'],
                })
            });
    }

    render() {
         const cardsData = [
             {
                 icon: require('../../../drawable/services-icon/service_icon_1.png').default,
                 label: 'Active Users',
                 up: true,
                 count: this.state.users,
                 bgColor: "#D25380",
                 textualColor: "white",
             },
             {
                icon: require('../../../drawable/services-icon/service_icon_1.png').default,
                label: 'Total Withdrawals',
                up: true,
                count: this.state.totalWithdraws,
                bgColor: "#1B9C85",
                textualColor: "white"
            },
            {
                icon: require('../../../drawable/services-icon/service_icon_1.png').default,
                label: 'Total Funds',
                up: true,
                count: this.state.totalFunds,
                bgColor: "#E57C23",
                textualColor: "white"
            }
        ]
        return (
            <div className="mini-stat-cards-container grid grid-cols-5 gap-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2">
                {cardsData.map(cardData => <div key={cardData.label} className="col-xl-3 col-md-6">
                    <MiniStatCard {...cardData} />
                </div>)}
            </div>
        );
    }
}

export default MiniStatCardList;
