import {UPDATE_FREE_DISTANCE} from './action_update_free_distance';

const INITIAL_STATE = {
    freeDistance: 0,
    fetching: true,
    updating: false,
    freeDistanceInput: ''
}

const updateFreeDistanceReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE_FREE_DISTANCE:
            return {
                ...state,
                freeDistance: action.payload.freeDistance,
                fetching: action.payload.fetching,
                updating: action.payload.updating,
                freeDistanceInput: action.payload.freeDistanceInput
            }
        default:
            return state;
    }
}

export default updateFreeDistanceReducer;
