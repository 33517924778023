import { SET_CURRENT_SCHEDULE_RIDES } from "./action_schedule_rides";

const INITIAL_STATE = {
    currentScheduleRides: {
        scheduled_rides : [],
        custom_scheduled_rides : []
    }
}

const scheduleRidesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_CURRENT_SCHEDULE_RIDES:
            return {
                ...state,
                currentScheduleRides: action.payload
            }
        default:
            return state;
    }
}

export default scheduleRidesReducer;
