import React from "react";
import {Button, TextField} from "@material-ui/core";
import {ACTIVITY_TAG, API_KEY} from "../../../utils/constants";
import axios from "axios";
import {createApiServerRoute} from "../../../models/network/api_client";
import UrlArgsBundle from "../../../core/url_args_bundle";
import {withRouter} from "react-router-dom";
import moment from "moment";

class UpdateResultFragment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            khanapara_fr : 'XX',
            khanapara_sr : 'XX',
            shilong_fr : 'XX',
            shilong_sr : 'XX',
            all_histories: []
        }
    }


    // urlArgsBundle =  (new UrlArgsBundle()).prepareFrom(this.props.location.search);
    // changeFragment = (fragmentTag) => {
    //     this.urlArgsBundle.setActivityTag(ACTIVITY_TAG.MAIN).setFragmentTag(fragmentTag);
    //     return this.urlArgsBundle.getArgsAsUrlParams()
    // }

    getPlayHistory = ()=>{
        const params = new URLSearchParams();
        params.append("__api_key__", API_KEY)
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('fetch_all_users_bid_histories.php'), params, config)
            .then(res => {
                if (res.data.state === 'OK') {
                    const mergeResult = res.data.data.bids.teer_bid_histories
                    const sortedResult=mergeResult.slice(0).sort((a,b)=>
                        a.played_at.localeCompare(b.played_at));
                    this.setState({
                        all_histories: sortedResult
                        // all_histories: sortedResult.filter(item=>moment(item['played_at']).format('DD-MM-YYYY') === moment(new Date()).format('DD-MM-YYYY'))
                    })
                } else {
                    console.log(res)
                }
            });
    }

    componentDidMount() {
        this.getPlayHistory()
        const params = new URLSearchParams();
        params.append("__api_key__", API_KEY)
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        // let gameType = this.urlArgsBundle.getStringExtra('gameType');
        // let subCategory = this.urlArgsBundle.getStringExtra('subCategory')

        axios.post(createApiServerRoute('fetch_teer_results.php'), params, config)
            .then(res => {
                if (res.data.state === 'OK') {
                    console.log("res.data.data.teer_results",res.data.data.teer_results);
                    if(res.data.data.teer_results != null){
                    this.setState({
                        ...this.state,
                        khanapara_fr : res.data.data.teer_results.khanapara_fr,
                        khanapara_sr : res.data.data.teer_results.khanapara_sr,
                        shilong_fr : res.data.data.teer_results.shilong_fr,
                        shilong_sr : res.data.data.teer_results.shilong_sr,
                        
                    })
                    }
                }
            });
    }

    updateGameRate = () => {
        console.log("all_histories",this.state.all_histories);
       // return;
        const params = new URLSearchParams();
        params.append("__api_key__", API_KEY)
        params.append("khanapara_fr", this.state.khanapara_fr)
        params.append("khanapara_sr", this.state.khanapara_sr)
        params.append("shilong_fr", this.state.shilong_fr)
        params.append("shilong_sr", this.state.shilong_sr)

        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('add_teer_result.php'), params, config)
            .then(res => {
                if (res.data.state === 'OK') {
                    window.location.reload();
                    // let tag = this.urlArgsBundle.getStringExtra('gameType') + "_GAMES"
                    // this.props.history.push(this.changeFragment(tag))
                }
            });
    }

    render() {
        return (
            <div className={"py-1 ba-gray-200 grid place-items-center"}>
                <div className="form-card w-96 bg-white py-6 px-10 rounded-2xl shadow-2xl flex flex-col gap-6">
                    <h1 className={"text-center text-indigo-800 text-2xl pb-3"}>Update Results</h1>
                    <TextField fullWidth id="standard-basic" label="KHANAPARA TEER FR" value={this.state.khanapara_fr} variant="outlined" onChange={(e) => {
                        // const re = /[0-9]+/g;
                        // if (e.target.value === '' || re.test(e.target.value)) {
                            this.setState({...this.state , khanapara_fr : e.target.value})
                        // }
                    }}
                        />
                        <TextField fullWidth id="standard-basic" label="KHANAPARA TEER SR" value={this.state.khanapara_sr} variant="outlined" onChange={(e) => {
                        // const re = /[0-9]+/g;
                        // if (e.target.value === '' || re.test(e.target.value)) {
                            this.setState({...this.state , khanapara_sr : e.target.value})
                        // }
                        }}/>
                        <TextField fullWidth id="standard-basic" label="SHILLONG TEER FR" value={this.state.shilong_fr} variant="outlined" onChange={(e) => {
                        // const re = /[0-9]+/g;
                        // if (e.target.value === '' || re.test(e.target.value)) {
                            this.setState({...this.state , shilong_fr : e.target.value})
                        // }
                        }}/>
                        <TextField fullWidth id="standard-basic" label="SHILLONG TEER SR" value={this.state.shilong_sr} variant="outlined" onChange={(e) => {
                        // const re = /[0-9]+/g;
                        // if (e.target.value === '' || re.test(e.target.value)) {
                            this.setState({...this.state , shilong_sr : e.target.value})
                        // }
                    }}/>
                        
                    <Button className={"h-12 rounded-md"} variant="contained" color="primary" onClick={this.updateGameRate} >
                        Update
                    </Button>
                </div>
            </div>
        );
    }

}

export default withRouter(UpdateResultFragment)
