import {SET_STANDARD_GAMES, SET_STARLINE_GAMES, SET_DESAWAR_GAMES} from "./action_games";

const INITIAL_STATE = {
    games : {
        currentStandardGames : [],
        currentStarlineGames : [],
        currentDesawarGames : []
    }
}

const gameReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_STANDARD_GAMES:
            return {
                ...state,
                games : {
                    ...state.games,
                    currentStandardGames: action.payload
                }
            }
        case SET_STARLINE_GAMES:
            return {
                ...state,
                games : {
                    ...state.games,
                    currentStarlineGames: action.payload,
                }
            }
        case SET_DESAWAR_GAMES:
            return {
                ...state,
                games : {
                    ...state.games,
                    currentDesawarGames: action.payload
                }
            }
        default:
            return state;
    }
}

export default gameReducer;