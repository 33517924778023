import React from "react";
import {Button, TextField} from "@material-ui/core";
import {API_KEY} from "../../../utils/constants";
import axios from "axios";
import {createApiServerRoute} from "../../../models/network/api_client";

class PushNotificationFragment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            title: '',
            message: ''
        }
    }

    send = () => {
        console.log("jello")
        const params = new URLSearchParams();
        params.append("__api_key__", API_KEY)
        params.append("title", this.state.title)
        params.append("message", this.state.message)
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('send_push_notification.php'), params, config)
            .then(res => {
                if (res.data.data.send) {
                    this.setState({
                        ...this.state,
                        title: '',
                        message: ''
                    })
                }
            });
    }

    render() {
        return (
            <div className={"py-36 ba-gray-200 grid place-items-center"}>
                <div className="form-card w-96 bg-white py-6 px-10 rounded-2xl shadow-2xl flex flex-col gap-6">
                    <h1 className={"text-center text-indigo-800 text-2xl pb-3"}>Send Push Notification</h1>
                    <TextField fullWidth id="standard-basic" label="Title"
                        value={this.state.title} variant="outlined"
                        onChange={(e) => {
                            this.setState({...this.state , title : e.target.value})
                        }}
                    />
                    <TextField fullWidth multiline rows={5} id="standard-basic" label="Notification"
                        value={this.state.message} variant="outlined"
                        onChange={(e) => {
                            this.setState({...this.state , message : e.target.value})
                        }}
                    />
                    <Button className={"h-12 rounded-md"} variant="contained"
                            color="primary" onClick={() => this.send()}>
                        Send
                    </Button>
                </div>
            </div>
        )
    }
}

export default PushNotificationFragment