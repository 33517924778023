import React from "react";
import {Button, TextField} from "@material-ui/core";
import {Stack} from "@mui/material";
import {API_KEY} from "../../../utils/constants";
import axios from "axios";
import {createApiServerRoute} from "../../../models/network/api_client";
import './in_app_setting_styles.scss';

class SettingFragment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            popupMessage: null,
            appExpiryMessage: null,
            description: null,
            backupUrl: null,
            GoWebApiUrl: null
        }
    }

    onDataUpdate = () => {
        console.log("hello")
        const params = new URLSearchParams();
        params.append("__api_key__", API_KEY)
        params.append("popup_message", this.state.popupMessage)
        params.append("app_expiry_message", this.state.appExpiryMessage)
        params.append("download_backup_url", this.state.backupUrl)
        params.append("goweb_api_url", this.state.GoWebApiUrl)
        params.append("how_to_play_description", this.state.description)
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('update_in_app_setting.php'), params, config)
            .then(res => {
                console.log(res.data)
                if (res.data.state === 'OK') {
                    this.setState({
                        ...this.state,
                        popupMessage: res.data.data.meta.popup_message,
                        appExpiryMessage: res.data.data.meta.app_expiry_message,
                        description: res.data.data.meta.how_to_play_description,
                        backupUrl: res.data.data.meta.download_backup_url,
                        GoWebApiUrl: res.data.data.meta.goweb_api_url
                    })
                }
            });
    }

    componentDidMount() {
        const params = new URLSearchParams();
        params.append("__api_key__", API_KEY)
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('fetch_in_app_settings.php'), params, config)
            .then(res => {
                if (res.data.state === 'OK') {
                    console.log(res.data.data.meta)
                    this.setState({
                        ...this.state,
                        popupMessage: res.data.data.meta.popup_message,
                        appExpiryMessage: res.data.data.meta.app_expiry_message,
                        description: res.data.data.meta.how_to_play_description,
                        backupUrl: res.data.data.meta.download_backup_url,
                        GoWebApiUrl: res.data.data.meta.goweb_api_url
                    })
                }
            });
    }

    render() {
        return <div className={"py-1 grid place-items-center col-auto"}>
            <div className="form-card w-1/3 bg-white py-6 px-10 rounded-2xl shadow-2xl flex flex-col gap-6">
                <h1 className={"text-center text-indigo-800 text-2xl pb-3"}>In App Settings</h1>
                <Stack direction={"row"}>
                    <TextField fullWidth id="standard-multiline-static" value={this.state.popupMessage || ''} label="Pop Up Message" multiline rows={5} variant="outlined"
                        onChange={e => {
                            this.setState({
                                ...this.state,
                                popupMessage : e.target.value
                            })
                        }}/>
                    <Button sx={{p: 50}} size={'large'} variant="contained" className={"px-6"} color={"primary"} onClick={() => this.onDataUpdate()}>Update</Button>
                </Stack>
                <Stack direction={"row"}>
                    <TextField fullWidth id="standard-multiline-static" value={this.state.appExpiryMessage || ''} label="App Expiry Message" multiline rows={5} variant="outlined"
                        onChange={e => {
                            this.setState({
                                ...this.state,
                                appExpiryMessage : e.target.value
                            })
                        }}/>
                    <Button sx={{p: 50}} size={'large'} variant="contained" className={"px-6"} color={"primary"} onClick={this.onDataUpdate}>Update</Button>
                </Stack>
                <Stack direction={"row"}>
                    <TextField fullWidth id="standard-multiline-static" value={this.state.description || ''} label="How To Play Description" multiline rows={5} variant="outlined"
                        onChange={e => {
                            this.setState({
                                ...this.state,
                                description : e.target.value
                            })
                        }}/>
                    <Button sx={{p: 50}} size={'large'} variant="contained"  className={"px-6"} color={"primary"} onClick={this.onDataUpdate}>Update</Button>
                </Stack>
                <Stack direction={"row"}>
                    <TextField fullWidth id="outlined-basic" value={this.state.backupUrl || ''} label="Download Backup URL" variant="outlined"
                       onChange={e => {
                           this.setState({
                               ...this.state,
                               backupUrl : e.target.value
                           })
                       }}/>
                    <Button sx={{p: 50}} size={'large'} variant="contained" className={"px-6"} color={"primary"} onClick={this.onDataUpdate}>Update</Button>
                </Stack>
                <Stack direction={"row"}>
                    <TextField fullWidth id="outlined-basic" value={this.state.GoWebApiUrl || ''} label="GoWeb Api URL" variant="outlined"
                       onChange={e => {
                           this.setState({
                               ...this.state,
                               GoWebApiUrl : e.target.value
                           })
                       }}/>
                    <Button sx={{p: 50}} size={'large'} variant="contained" className={"px-6"} color={"primary"} onClick={this.onDataUpdate}>Update</Button>
                </Stack>
            </div>
        </div>

    }
}

export default SettingFragment