import React from 'react';
import './add_ride_category_fragment_style.scss';
import axios from 'axios';
import { createApiServerRoute } from "../../../models/network/api_client";
import { withRouter } from "react-router-dom";

class AddRideCategoryFragment extends React.Component {

    addRideCategory = (event) => {
        event.preventDefault();
        const params = new FormData();
        let {pickedImage, categoryInput, categoryEnabled, priceInput, perKmPriceInput} = this.state
        params.append('name', categoryInput);
        params.append('enabled', categoryEnabled);
        params.append('image', pickedImage);
        params.append('price', priceInput);
        params.append('per_km_cost', perKmPriceInput);


        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('add_ride_category.php'), params, config)
            .then(res => {
                console.log(res);
                console.log(res.data);
                if (res.data['cab_5_response_state'] === 'OK') {
                    this.props.history.goBack();
                }
            });
    }

    constructor(props) {
        super(props);
        this.state = {
            pickedImage: null,
            categoryInput: '',
            categoryEnabled: 'Y',
            priceInput: '',
            perKmPriceInput: ''
        }
    }

    render() {
        return <div className="flex flex-column justify-center items-center md:mt-10">
            <form className="w-full max-w-lg" onSubmit={this.addRideCategory}>
                <div className="m-4">
                    <label className="inline-block mb-2 text-gray-500">Image Upload</label>
                    <div className="flex items-center justify-center w-full">
                        <label
                            className="flex flex-col w-full h-32 border-4 border-blue-200 border-dashed hover:bg-gray-100 hover:border-gray-300">
                            <div className="flex flex-col items-center justify-center pt-7">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                     className="w-8 h-8 text-gray-400 group-hover:text-gray-600"
                                     fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"/>
                                </svg>
                                <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">Attach a file</p>
                            </div>
                            <input type="file" className="opacity-0" onChange={(e) => { this.setState({...this.state, pickedImage: e.target.files[0]}) }} />
                        </label>
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 grid-cols-2">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                               htmlFor="grid-first-name">
                            Ride Category Name
                        </label>
                        <input
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            id="grid-first-name" type="text"
                            value={this.state.categoryInput} placeholder="Ride Category Name"
                            onChange={(e) => {this.setState({...this.state, categoryInput: e.target.value})} }
                        />
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 grid-cols-2"
                               htmlFor="grid-state">
                            Enable/Disable
                        </label>
                        <div className="relative">
                            <select
                                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="grid-state" onChange={e => { this.setState({...this.state, categoryEnabled: e.target.value}) }}>
                                <option value='Y' selected>Enable</option>
                                <option value='N'>Disable</option>
                            </select>
                            <div
                                className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 20 20">
                                    <path
                                        d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 grid-cols-2">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                               htmlFor="grid-first-name">
                            Price
                        </label>
                        <input
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            id="grid-first-name" type="text" placeholder="Price"
                            value={this.state.priceInput}
                            onChange={(e) => {this.setState({...this.state, priceInput: e.target.value})} }
                        />
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 grid-cols-2"
                               htmlFor="grid-state">
                            Per Kilometer Price
                        </label>
                        <input
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                            id="grid-first-name" type="text" placeholder="Per Kilometer Price"
                            value={this.state.perKmPriceInput}
                            onChange={(e) => {this.setState({...this.state, perKmPriceInput: e.target.value})} }
                        />
                    </div>
                </div>
                <div className="md:flex md:items-center md:ml-12">
                    <div className="md:w-1/3" />
                    <div className="md:w-2/3">
                        <button
                            className="shadow bg-blue-500 hover:bg-blue-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                            type="submit">
                            Add Ride Category
                        </button>
                    </div>
                </div>
            </form>
        </div>
    }
}

export default withRouter(AddRideCategoryFragment);
