import React from 'react';
import logo from "./../../../drawable/avatar.png";

const TableUserNameColumn = ({avatarUrl, firstName, lastName, email, clickHandler}) => {

    return <td className="px-6 py-4 whitespace-nowrap" onClick={clickHandler}>
        <div className="flex items-center">
            <div className="flex-shrink-0 h-10 w-10">
                <img className="h-10 w-10 rounded-full"
                     src={avatarUrl === null ? logo : avatarUrl}
                     alt="" />
            </div>
            <div className="ml-4">
                <div className="text-sm font-medium text-gray-900">
                    {`${firstName} ${lastName}`}
                </div>
                <div className="text-sm text-gray-500">
                    {email}
                </div>
            </div>
        </div>
    </td>
}

export default TableUserNameColumn;