import React from 'react';
import './scheduled_rides_fragment_style.scss';
import axios from 'axios';
import { createApiServerRoute } from "../../../models/network/api_client";
import { connect } from 'react-redux';
import {actionSetCurrentScheduleRides} from "../../../models/redux/reducers/activity_main_reducers/schedule_rides_reducer/action_schedule_rides";
import {withRouter} from "react-router-dom";
import TableIndexColumn from "../../../components/table_component/table_index_column/table_index_column";
import TableUserNameColumn from "../../../components/table_component/table_user_name_column/table_user_name_column";
import TableGreenishTextualColumn from "../../../components/table_component/table_greenish_textual_column/table_greenish_textual_column";
import TableTextualColumn from "../../../components/table_component/table_textual_column/table_textual_column";
import TableContainerColumn from "../../../components/table_component/table_container_column/table_container_column";
import TableBody from "../../../components/table_component/table_body/table_body";
import TableHeading from "../../../components/table_component/table_heading/table_heading";
import Table from "../../../components/table_component/table/table";
import TableHeader from "../../../components/table_component/table_header/table_header";
import FloatingActionButton from "../../../components/floating_action_btn/FloatingActionButton";
import {Add} from "@material-ui/icons";
import UrlArgsBundle from "../../../core/url_args_bundle";
import {ACTIVITY_TAG, FRAGMENT_TAG} from "../../../utils/constants";
import ChipsContainer from "../../../components/chip_component/chips_container/chips_container";
import Chip from "../../../components/chip_component/chip";

const mapStateToProps = rootReducer => ({
    schedule_rides: rootReducer.schedule_rides.currentScheduleRides
});

const mapDispatchToProps = dispatch => ({
    actionSetCurrentScheduleRides: schedule_rides => dispatch(actionSetCurrentScheduleRides(schedule_rides))
});

class ScheduledRidesFragment extends React.Component {

    sendRides = (scheduleRideID) => {
        const params = new URLSearchParams();
        params.append('scheduled_ride_id', scheduleRideID);

        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('send_rides_of_scheduled_ride.php'), params, config)
            .then(res => {
                console.log(res.data)
                const scheduleRideArr = this.props.schedule_rides.scheduled_rides.filter(scheduleRide => scheduleRide['schedule_ride_id'] !== scheduleRideID );
                const customScheduleRideArr = this.props.schedule_rides.custom_scheduled_rides.filter(scheduleRide => scheduleRide['schedule_ride_id'] !== scheduleRideID );
                const rides = {
                    "scheduled_rides" : scheduleRideArr,
                    "custom_scheduled_rides" : customScheduleRideArr
                }
                this.props.actionSetCurrentScheduleRides(rides)
            });
    }

    cancelRides = (scheduleRideID) => {
        const params = new URLSearchParams();
        params.append('schedule_ride_id', scheduleRideID);

        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('delete_schedule_passenger_ride.php'), params, config)
            .then(res => {
                console.log(res.data)
                if (res.data.data['delete_scheduled_ride_success']) {
                    const scheduleRideArr = this.props.schedule_rides.scheduled_rides.filter(scheduleRide => scheduleRide['schedule_ride_id'] !== scheduleRideID );
                    const customScheduleRideArr = this.props.schedule_rides.custom_scheduled_rides.filter(scheduleRide => scheduleRide['schedule_ride_id'] !== scheduleRideID );
                    const rides = {
                        "scheduled_rides" : scheduleRideArr,
                        "custom_scheduled_rides" : customScheduleRideArr
                    }
                    this.props.actionSetCurrentScheduleRides(rides)
                }
            });
    }

    componentDidMount() {
        const params = new URLSearchParams();

        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('get_all_scheduled_rides.php'), params, config)
            .then(res => {
                console.log(res.data.data)
                this.props.actionSetCurrentScheduleRides(res.data.data.rides)
            });
    }

    getScheduleRideFragment = () => {
        const urlArgsBundle = new UrlArgsBundle();
        urlArgsBundle.setActivityTag(ACTIVITY_TAG.MAIN).setFragmentTag(FRAGMENT_TAG.SCHEDULE_RIDE);
        return urlArgsBundle.getArgsAsUrlParams()
    }

    getTripsFragment = fragmentTag => {
        const urlArgsBundle = new UrlArgsBundle();
        urlArgsBundle.setActivityTag(ACTIVITY_TAG.MAIN).setFragmentTag(fragmentTag);
        return urlArgsBundle.getArgsAsUrlParams()
    }

    render() {

        console.log(this.props)

        let scheduledRides = this.props.schedule_rides.scheduled_rides.filter(e => e.type === "simple")
        let customScheduledRides = this.props.schedule_rides.custom_scheduled_rides.filter(e => e.type === "simple")

        return <>
            <ChipsContainer>
                <Chip active={true} clickHandler={() => {this.props.history.push(this.getTripsFragment(FRAGMENT_TAG.SCHEDULED_RIDES))}}>Scheduled Ride</Chip>
                <Chip active={false} clickHandler={() => {this.props.history.push(this.getTripsFragment(FRAGMENT_TAG.CITY_TO_CITY_SCHEDULED_RIDE))}}>City To City Scheduled Ride</Chip>
            </ChipsContainer>
            <div className="flex flex-col">
                <Table>
                    <TableHeader>
                        <TableHeading>#</TableHeading>
                        <TableHeading>User</TableHeading>
                        <TableHeading>Phone</TableHeading>
                        <TableHeading>Schedule At</TableHeading>
                        <TableHeading>Pickup Location</TableHeading>
                        <TableHeading>Send Rides</TableHeading>
                        <TableHeading>Cancel Rides</TableHeading>
                    </TableHeader>
                    <TableBody>
                        {scheduledRides.map((d, index) => <tr key={d.schedule_ride_id}>
                            <TableIndexColumn clickHandler={() => {}} num={index + 1} />
                            <TableUserNameColumn
                                avatarUrl={d.passenger_avatar}
                                firstName={d['passenger_first_name']}
                                lastName={d['passenger_last_name']}
                                email={d['passenger_email']} />
                            <TableGreenishTextualColumn text={d.passenger_phone} />
                            <TableTextualColumn text={d.schedule_at} />
                            <TableGreenishTextualColumn text={d.pickup_location} />
                            <TableContainerColumn>
                                <button
                                    className="shadow bg-blue-500 hover:bg-blue-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                                    type="button"
                                    onClick={()=> {this.sendRides(d.schedule_ride_id)}}>
                                    Send Rides
                                </button>
                            </TableContainerColumn>
                            <TableContainerColumn>
                                <button
                                    className="shadow bg-blue-500 hover:bg-blue-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                                    type="button"
                                    onClick={()=> {this.cancelRides(d.schedule_ride_id)}}>
                                    Cancel
                                </button>
                            </TableContainerColumn>
                        </tr>)}
                    </TableBody>
                </Table>
            </div>

            <div className={"pt-20 pb-4 px-8 font-bold text-indigo-800"}>
                Custom Scheduled
            </div>

            <div className="flex flex-col">
                <Table>
                    <TableHeader>
                        <TableHeading>#</TableHeading>
                        <TableHeading>User</TableHeading>
                        <TableHeading>Phone</TableHeading>
                        <TableHeading>Schedule At</TableHeading>
                        <TableHeading>Pickup Location</TableHeading>
                        <TableHeading>Send Rides</TableHeading>
                        <TableHeading>Cancel Rides</TableHeading>
                    </TableHeader>
                    <TableBody>
                        {customScheduledRides.map((d, index) => <tr key={d.schedule_ride_id}>
                            <TableIndexColumn clickHandler={() => {}} num={index + 1} />
                            <TableUserNameColumn
                                avatarUrl={d.passenger_avatar}
                                firstName={d['passenger_first_name']}
                                lastName={d['passenger_last_name']}
                                email={d['passenger_email']} />
                            <TableGreenishTextualColumn text={d.passenger_phone} />
                            <TableTextualColumn text={d.schedule_at} />
                            <TableGreenishTextualColumn text={d.pickup_location} />
                            <TableGreenishTextualColumn text={"Auto Send"} />
                            <TableContainerColumn>
                                <button
                                    className="shadow bg-blue-500 hover:bg-blue-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
                                    type="button"
                                    onClick={()=> {this.cancelRides(d.schedule_ride_id)}}>
                                    Cancel
                                </button>
                            </TableContainerColumn>
                        </tr>)}
                    </TableBody>
                </Table>
            </div>

            <FloatingActionButton
                clickHandler={() => this.props.history.push(this.getScheduleRideFragment())}
                icon={<Add/>}/>
        </>
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ScheduledRidesFragment));
