import React from "react";
import MainActivity from "./views/activities/main_activity/MainActivity";
import './Cab5Admin.scss';
import LoginActivity from "./views/activities/login_activity/LoginActivity";
import {withRouter} from "react-router-dom";
import {ACTIVITY_TAG} from "./utils/constants";
import UrlArgsBundle from "./core/url_args_bundle";

class Cab5Admin extends React.Component {

    urlArgsBundle = (new UrlArgsBundle()).prepareFrom(this.props.location.search);
    componentDidMount() {

        if (localStorage.getItem('user') === null) {
            this.urlArgsBundle.setActivityTag(ACTIVITY_TAG.LOGIN)
            this.props.history.push(this.urlArgsBundle.getArgsAsUrlParams())
        }
    }

    render() {

        if (this.urlArgsBundle.hasActivityTag()) {
            let activityTag = this.urlArgsBundle.getActivityTag();
            if (activityTag === ACTIVITY_TAG.MAIN) {
                return <MainActivity />
            } else if (activityTag === ACTIVITY_TAG.LOGIN) {
                return <LoginActivity />
            }
        }

        return <MainActivity />
    }
}

export default withRouter(Cab5Admin);
