import React from 'react';
import {withRouter} from "react-router-dom";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import axios from "axios";
import {createApiServerRoute} from "../../../models/network/api_client";
import './fragment_city_to_city_schedule_ride_style.scss';
import UrlArgsBundle from "../../../core/url_args_bundle";
import {ACTIVITY_TAG, FRAGMENT_TAG} from "../../../utils/constants";

const FormSubmitBtn = ({text, disabled}) => {
    return <button
        className="shadow bg-blue-500 hover:bg-blue-400 focus:shadow-outline focus:outline-none text-white w-full font-bold py-2 px-4 rounded"
        disabled={disabled} type="submit">{text}</button>
}

const BackBtn = ({disabled, clickHandler}) => {
    return <button
        className="shadow bg-blue-500 hover:bg-blue-400 focus:shadow-outline focus:outline-none text-white w-full font-bold py-2 px-4 rounded"
        disabled={disabled} type="button" onClick={clickHandler}>Back</button>
}


const FormInput = ({label, forId, changeHandler, placeHolder, imeType, value, disabled}) => {
    return <>
        <label className="block m-4 uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor={forId}>
            {label}
        </label>
        <input
            className="appearance-none w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
            id={forId}
            type={imeType}
            value={value}
            placeholder={placeHolder}
            disabled={disabled}
            onChange={changeHandler}/>
    </>
}

const ErrorBox = ({error}) => {
    return <p className="text-red-500 mb-3">{error}</p>
}

const UserCard = ({bigTitle, avatar, firstName, lastName, email}) => {
    return <div className="bg-gray-300 py-3 px-10 rounded-md shadow-lg w-full mt-10">
        <h2 className="font-semibold text-indigo-700 text-2xl mb-6">{bigTitle}</h2>
        <div className="flex flex-row">
            <img className="w-20 h-20 object-cover rounded-full mr-4 shadow-lg"
                 src={avatar}
                 alt="avatar"/>
            <div className="flex flex-col">
                <p className="capitalize text-xl mt-1">{firstName} {lastName}</p>
                <p className="text-l text-gray-700 mt-1">{email}</p>
            </div>
        </div>
    </div>
}

class CityToCityScheduleRideFragment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            client_phone_step: {
                phone_input: '',
                display_error: false,
                data: {
                    passenger: null,
                    status: 'IDLE'
                }
            },
            driver_phone_step: {
                phone_input: '',
                display_error: false,
                data: {
                    driver: null,
                    status: 'IDLE'
                }
            },
            schedule_time: new Date(),
            pickup_location: {
                text: '',
                display_error: false
            },
            pickup_longitude: {
                text: '',
                display_error: false
            },
            pickup_latitude: {
                text: '',
                display_error: false
            },
            scheduling: {
                status: 'IDLE'
            }
        }
    }



    scheduleRide = (e) => {
        e.preventDefault();

        if (this.state.step === 1) {
            if (this.state.client_phone_step.data.status === 'FOUND') {
                this.setState({...this.state, step: 2})
            } else {
                this.getClient();
            }
        } else if (this.state.step === 2) {
            if (this.state.driver_phone_step.data.status === 'FOUND' && this.state.driver_phone_step.data.driver['city_to_city_ride_categories'].length > 0) {
                this.setState({...this.state, step: 3})
            } else {
                this.getDriver();
            }
        } else if (this.state.step === 3) {
            const passengerId = this.state.client_phone_step.data.passenger.passenger.id;
            const driverId = this.state.driver_phone_step.data.driver.driver.id;
            const pickupLatitude = this.state.pickup_latitude.text;
            const pickupLongitude = this.state.pickup_longitude.text;
            const pickupLocation = this.state.pickup_location.text;
            const rideCategoryId = this.state.driver_phone_step.data.driver.city_to_city_ride_categories.filter(v => v.selected)[0].id;

            console.log(rideCategoryId.id);

            let date = this.state.schedule_time;
            let hours= date.getHours();
            let minutes = date.getMinutes();
            let year = date.getFullYear();
            let month = date.getMonth()+1;
            let current_date = date.getDate();

            let scheduleAt = `${year}-${(month < 10 ? '0' : '') + month}-${(current_date < 10 ? '0' : '') + current_date} ${(hours < 10 ? '0' : '') + hours}:${(minutes < 10 ? '0' : '') + minutes}:00`
            console.log(scheduleAt);

            this.setState({
                ...this.state,
                scheduling: {
                    status: 'SCHEDULING'
                }
            })

            const params = new URLSearchParams();
            params.append('passenger_id', passengerId);
            params.append('pickup_lng', pickupLongitude);
            params.append('pickup_lat', pickupLatitude);
            params.append('pickup_location_name', pickupLocation);
            params.append('schedule_at', scheduleAt);
            params.append('driver_id', driverId);
            params.append('city_to_city_ride_category_id', rideCategoryId);

            const config = {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                }
            };

            axios.post(createApiServerRoute('schedule_custom_city_to_city_ride.php'), params, config)
                .then(res => {
                    if (res.data['cab_5_response_state'] === 'OK') {
                        this.setState({
                            ...this.state,
                            step: this.state.step + 1,
                            scheduling: {
                                status: 'SCHEDULED'
                            }
                        });
                    }

                });

            console.log("OKAY All is well")
        }
    }

    changeClientPhoneInput = e => {
        this.setState({
            ...this.state,
            client_phone_step: {
                ...this.state.client_phone_step,
                phone_input: e.target.value,
                display_error: true,
                data: {
                    passenger: null,
                    status: 'IDLE'
                }
            }
        })
    }

    getClient = () => {
        const params = new URLSearchParams();
        params.append('phone', this.state.client_phone_step.phone_input);

        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        this.setState({
            ...this.state,
            client_phone_step: {
                ...this.state.client_phone_step,
                data: {
                    ...this.state.client_phone_step.data,
                    status: 'FINDING'
                }
            }
        })

        axios.post(createApiServerRoute('login_passenger.php'), params, config)
            .then(res => {
                console.log(res.data);
                if (res.data['cab_5_response_state'] === 'OK') {
                    this.setState({
                        ...this.state,
                        step: this.state.step + 1,
                        client_phone_step: {
                            ...this.state.client_phone_step,
                            data: {
                                passenger: res.data.data,
                                status: 'FOUND'
                            }
                        }
                    })
                }
                else if (res.data['cab_5_response_state'] === 'FAILURE') {
                    if (res.data.data.exceptions.hasOwnProperty('no_passenger_found')) {
                        this.setState({
                            ...this.state,
                            client_phone_step: {
                                ...this.state.client_phone_step,
                                data: {
                                    passenger: null,
                                    status: 'NOT FOUND'
                                }
                            }
                        })
                    }
                }
            });
    }

    chooseClientStep = () => {
        const {client_phone_step: step} = this.state;
        const showEmptyInputError = (step.display_error && step.phone_input === '')
        const showNoClientFoundError = (step.display_error && step.data.passenger === null && step.data.status === 'NOT FOUND')
        const btnProps = {
            title: 'Next',
            disabled: false
        };
        btnProps.disabled = step.data.status === 'FINDING'
        if (step.data.status === 'IDLE' || step.data.status === 'NOT FOUND') {
            btnProps.title = 'FIND';
        }
        if (step.data.status === 'FINDING') {
            btnProps.title = 'FINDING...';
        }
        return <>
            <FormInput
                forId="client-phone-number-input"
                label="Client Phone Number"
                placeHolder="+923471234567"
                value={this.state.client_phone_step.phone_input}
                imeType="tel"
                disabled={btnProps.disabled}
                changeHandler={this.changeClientPhoneInput}/>
            {showEmptyInputError && <ErrorBox error="Please Enter Client Phone Number"/>}
            {showNoClientFoundError && <ErrorBox error="No Client Found"/>}
            <FormSubmitBtn text={btnProps.title} disabled={btnProps.disabled || this.state.client_phone_step.phone_input === ""}/>
        </>
    }

    getDriver = () => {
        console.log('getDriver Called !')
        const params = new URLSearchParams();
        params.append('phone', this.state.driver_phone_step.phone_input);

        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        this.setState({
            ...this.state,
            driver_phone_step: {
                ...this.state.driver_phone_step,
                data: {
                    ...this.state.driver_phone_step.data,
                    status: 'FINDING'
                }
            }
        })

        axios.post(createApiServerRoute('get_driver_for_custom_schedule_ride.php'), params, config)
            .then(res => {
                console.log(res.data);
                const data = {
                    ...res.data,
                    data: {
                        ...res.data.data,
                        city_to_city_ride_categories: res.data.data['city_to_city_ride_categories'].map((category, index) => ({...category, selected: index === 0}))
                    }
                }
                if (data['cab_5_response_state'] === 'OK') {
                    this.setState({
                        ...this.state,
                        step: data.data['city_to_city_ride_categories'].length === 0 ? this.state.step : this.state.step + 1,
                        driver_phone_step: {
                            ...this.state.driver_phone_step,
                            data: {
                                driver: data.data,
                                status: 'FOUND'
                            }
                        }
                    })
                } else if (res.data['cab_5_response_state'] === 'FAILURE') {
                    if (res.data.data.exceptions.hasOwnProperty('no_driver_found')) {
                        this.setState({
                            ...this.state,
                            driver_phone_step: {
                                ...this.state.driver_phone_step,
                                data: {
                                    driver: null,
                                    status: 'NOT FOUND'
                                }
                            }
                        })
                    }
                }
            });
    }

    changeDriverPhoneInput = e => {
        this.setState({
            ...this.state,
            driver_phone_step: {
                ...this.state.driver_phone_step,
                phone_input: e.target.value,
                display_error: true,
                data: {
                    driver: null,
                    status: 'IDLE'
                }
            }
        })
    }


    chooseDriverStep = () => {
        const {driver_phone_step: step} = this.state;

        const showEmptyInputError = (step.display_error && step.phone_input === '')
        const showNoDriverFoundError = (step.display_error && step.data.driver === null && step.data.status === 'NOT FOUND')

        let showDriverHasNoRideCategories = false;

        const btnProps = {
            title: 'Next',
            disabled: false
        };

        btnProps.disabled = step.data.status === 'FINDING'

        if (step.data.status === 'IDLE') {
            btnProps.title = 'FIND';
        }

        if (step.data.status === 'FINDING') {
            btnProps.title = 'FINDING...';
        }

        if (step.data.status === 'FOUND') {
            if (step.data.driver['city_to_city_ride_categories'].length === 0) {
                btnProps.title = 'FIND';
                showDriverHasNoRideCategories = true
            }
        }

        return <>
            <FormInput
                forId="driver-phone-number-input"
                label="Driver Phone Number"
                placeHolder="+923471234567"
                value={step.phone_input}
                imeType="tel"
                disabled={btnProps.disabled}
                changeHandler={this.changeDriverPhoneInput} />
            {showEmptyInputError && <ErrorBox error="Please Enter Driver Phone Number"/>}
            {showNoDriverFoundError && <ErrorBox error="No Driver Found"/>}
            {showDriverHasNoRideCategories && <ErrorBox error="Driver has No Ride Category Enabled."/>}
            <div className="grid grid-cols-2 gap-4">
                <BackBtn disabled={btnProps.disabled} clickHandler={() => {this.setState({...this.state, step: 1})}}/>
                <FormSubmitBtn text={btnProps.title} disabled={btnProps.disabled || step.phone_input===""} />
            </div>
        </>
    }

    selectChosenRideCategory = event => {
        this.setState({
            ...this.state,
            driver_phone_step: {
                ...this.state.driver_phone_step,
                data: {
                    ...this.state.driver_phone_step.data,
                    driver: {
                        ...this.state.driver_phone_step.data.driver,
                        ride_categories: this.state.driver_phone_step.data.driver.city_to_city_ride_categories.map(category => ({...category, selected: category.id === event.target.value}))
                    }
                }
            }
        })
    }

    changePickupLocationInput = e => {
        this.setState({
            ...this.state,
            pickup_location: {
                text: e.target.value,
                display_error: true,
            }
        })
    }

    changePickupLongitudeInput = e => {
        this.setState({
            ...this.state,
            pickup_longitude: {
                text: e.target.value,
                display_error: true,
            }
        })
    }

    changePickupLatitudeInput = e => {
        this.setState({
            ...this.state,
            pickup_latitude: {
                text: e.target.value,
                display_error: true,
            }
        })
    }


    finalStep = () => {
        const {pickup_location, pickup_longitude, pickup_latitude} = this.state;

        const showPickupLocationEmptyError = (pickup_location.display_error && pickup_location.text === '')
        const showPickupLongitudeEmptyError = (pickup_longitude.display_error && pickup_longitude.text === '')
        const showPickupLatitudeEmptyError = (pickup_latitude.display_error && pickup_latitude.text === '')

        const btnProps = {
            title: this.state.scheduling.status === 'SCHEDULING' ? 'SCHEDULING' : 'Schedule Ride',
            disabled: this.state.scheduling.status === 'SCHEDULING'
        };

        return <>
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 grid-cols-2" htmlFor="choose-ride-category">
                Choose Ride Category
            </label>
            <div className="relative">
                <select
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="choose-ride-category" onChange={this.selectChosenRideCategory}>
                    {this.state.driver_phone_step.data.driver['city_to_city_ride_categories']
                        .map(rideCategory => <option key={rideCategory.id} value={rideCategory.id} selected={rideCategory.selected}>{rideCategory.name}</option>)
                    }
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                </div>
            </div>

            <label className="block mt-2 uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="schedule-ride-time">
                Schedule Time
            </label>
            <Flatpickr
                data-enable-time
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="schedule-ride-time"
                value={this.state.schedule_time}
                onChange={([date]) => {

                    console.log(date)
                    this.setState({...this.state, schedule_time: date});
                    // console.log(this.state)
                }}
            />

            <FormInput
                forId="pickup-location-name"
                label="Pickup Location Name"
                placeHolder="White Hill"
                value={this.state.pickup_location.text}
                imeType="text"
                disabled={btnProps.disabled}
                changeHandler={this.changePickupLocationInput} />

            {showPickupLocationEmptyError && <ErrorBox error="Please Enter Pickup Location Name"/>}

            <FormInput
                forId="pickup-latitude"
                label="Pickup Latitude"
                placeHolder="128.123121421"
                value={this.state.pickup_latitude.text}
                imeType="number"
                disabled={btnProps.disabled}
                changeHandler={this.changePickupLatitudeInput} />

            {showPickupLatitudeEmptyError && <ErrorBox error="Please Enter Pickup Latitude"/>}

            <FormInput
                forId="pickup-longitude"
                label="Pickup Longitude"
                placeHolder="128.123121421"
                value={this.state.pickup_longitude.text}
                imeType="number"
                disabled={btnProps.disabled}
                changeHandler={this.changePickupLongitudeInput} />

            {showPickupLongitudeEmptyError && <ErrorBox error="Please Enter Pickup Longitude"/>}

            <div className="grid grid-cols-2 gap-4">
                <BackBtn disabled={btnProps.disabled} clickHandler={() => {this.setState({...this.state, step: 2})}}/>
                <FormSubmitBtn text={btnProps.title} disabled={btnProps.disabled} />

            </div>
        </>
    }

    goBackTo = () => {
        const urlArgsBundle = new UrlArgsBundle();
        urlArgsBundle.setActivityTag(ACTIVITY_TAG.MAIN).setFragmentTag(FRAGMENT_TAG.CITY_TO_CITY_SCHEDULED_RIDE);
        this.props.history.push(urlArgsBundle.getArgsAsUrlParams())
    }

    successPrompt = () => {

        return <>
            <div className="text-center">
                <h2 className="font-bold text-indigo-600 mb-6">
                    Your Ride Has Been Successfully Scheduled
                </h2>
                <button
                    className="py-2 font-semibold px-8 rounded-lg shadow-lg bg-indigo-600 text-white text-md-center"
                    onClick={() => {
                        this.goBackTo()
                    }}>
                    BACK
                </button>
            </div>
        </>
    }

    getMyStep = () => {
        switch (this.state.step) {
            case 1:
                return this.chooseClientStep
            case 2:
                return this.chooseDriverStep
            case 3:
                return this.finalStep
            case 4:
                return this.successPrompt
            default:
                return () => {};
        }
    }

    render() {
        console.log(this.state)
        return <div className="flex flex-col schedule-ride-fragment">
            <div className="grid grid-cols-2 items-center min-h-full my-auto">
                <div className="bg-gray-100">
                    <form className="px-32" onSubmit={this.scheduleRide}>
                        {this.getMyStep()()}
                    </form>
                </div>
                <div className="bg-gray-100 px-6">
                    {this.state.client_phone_step.data.passenger !== null && <UserCard bigTitle="Passenger"
                                                                                       avatar={this.state.client_phone_step.data.passenger.avatar.avatar}
                                                                                       firstName={this.state.client_phone_step.data.passenger.passenger['first_name']}
                                                                                       lastName={this.state.client_phone_step.data.passenger.passenger['last_name']}
                                                                                       email={this.state.client_phone_step.data.passenger.passenger['email']}/>}
                    {this.state.driver_phone_step.data.driver !== null && <UserCard bigTitle="Driver"
                                                                                    avatar={this.state.driver_phone_step.data.driver.avatar.avatar}
                                                                                    firstName={this.state.driver_phone_step.data.driver.driver['first_name']}
                                                                                    lastName={this.state.driver_phone_step.data.driver.driver['last_name']}
                                                                                    email={this.state.driver_phone_step.data.driver.driver['email']}/>}
                </div>
            </div>
        </div>
    }
}

export default withRouter(CityToCityScheduleRideFragment);
