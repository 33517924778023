export const SET_CURRENT_UPDATE_CITY_NAME = 'SET_CURRENT_UPDATE_CITY_NAME';
export const SET_CURRENT_UPDATE_CITY_ENABLED = 'SET_CURRENT_UPDATE_CITY_ENABLED';

export const actionSetCurrentUpdateCityName = name => ({
    type: SET_CURRENT_UPDATE_CITY_NAME,
    payload: name
})
export const actionSetCurrentUpdateCityEnabled = enabled => ({
    type: SET_CURRENT_UPDATE_CITY_ENABLED,
    payload: enabled
})
