import React from "react";
import FloatingActionButton from "../../../components/floating_action_btn/FloatingActionButton";
import {ACTIVITY_TAG, API_KEY, FRAGMENT_TAG, GAME_TYPES} from "../../../utils/constants";
import {Add} from "@material-ui/icons";
import {withRouter} from "react-router-dom";
import UrlArgsBundle from "../../../core/url_args_bundle";
import axios from "axios";
import {createApiServerRoute} from "../../../models/network/api_client";
import './desawar_game_chart_styles.scss';
import {Alert} from "@mui/material";
import {Snackbar} from "@material-ui/core";

class DesawarGameChartFragment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            charts: {
                head: [],
                body: [],
                showError: false,
                vertical : 'top',
                horizontal: 'right',
                error: 'Please fill all fields'
            }
        }
    }


    urlArgsBundle =  (new UrlArgsBundle()).prepareFrom(this.props.location.search);
    changeFragment = (fragmentTag) => {
        this.urlArgsBundle.setActivityTag(ACTIVITY_TAG.MAIN).setFragmentTag(fragmentTag);
        return this.urlArgsBundle.getArgsAsUrlParams()
    }

    componentDidMount() {
        const params = new URLSearchParams();
        params.append("__api_key__", API_KEY)
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('fetch_desawar_game_chart.php'), params, config)
            .then(res => {
                if (res.data.state === 'OK') {
                    console.log(res.data.data.meta);
                    this.setState({
                        ...this.state,
                        charts: res.data.data.meta
                    })
                }
            });
    }

    deleteChart = (gameChartUid) => {
        if (gameChartUid !== null) {

            const params = new URLSearchParams();
            params.append("__api_key__", API_KEY)
            params.append("game_type", GAME_TYPES.DESAWAR)
            params.append("game_chart_uid", gameChartUid)
            const config = {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                }
            };

            axios.post(createApiServerRoute('delete_game_chart.php'), params, config)
                .then(res => {
                    console.log(res);
                    if (res.data.state === 'OK' && res.data.data['deleted']) {
                        window.location.reload()
                    } else  {
                        this.setState({...this.state, showError: true, error : Object.keys(res.data.data.exceptions)[0]})
                    }
                });

        }
    }

    render() {

        let user = JSON.parse(localStorage.getItem('user'))

        return <>

            {
                this.state.charts.head.length >= 1 && <div className="desawar-game-chart-8552ce90-7820-4f48-9d92-c36dbc22645f">
                    <Snackbar anchorOrigin={{horizontal: "right" , vertical: "top"}} open={this.state.showError} autoHideDuration={6000} onClose={() => {
                        this.setState({...this.state , showError: false, error: 'Please fill all fields'})
                    }}>
                        <Alert onClose={() => {this.setState({...this.state , showError: false, error: 'Please fill all fields'})}} severity="error" sx={{ width: '100%' }}>
                            {this.state.error}
                        </Alert>
                    </Snackbar>
                    <table>
                        <tr className="chart-row head-row">
                            <th className="chart-col head-col date-col">Date</th>
                            {this.state.charts.head.map((t, i) => <th key={i} className="chart-col head-col">{t}</th>)}
                        </tr>
                    {this.state.charts.body.map((r, i)  => <tr key={i} className="chart-row body-row">
                        <td className="chart-col date-col">{r.date.split(' ')[1].split('-').map(x => <span>{x}</span>)}</td>
                        {r.results.map((res, i) => {
                            return (
                                <td key={i} className="chart-col res-col relative cursor-pointer">
                                    {res.open_digit}{res.close_digit}
                                    {
                                        user === null ? '' : (user['type'] === 'MAIN' ? <span
                                        className={"absolute top-1 right-1"}
                                        style={{fontSize: "10px"}}
                                        onClick={() => this.deleteChart(res.uid)}>
                                        &#10060;
                                    </span> : '' )}
                                </td>
                            )
                        })}
                    </tr>)}
                    </table>
                </div>
            }
            {
                user === null ? '' : (user['type'] === 'MAIN' ?<FloatingActionButton
                clickHandler={() => {
                    this.urlArgsBundle.putExtra('game_uid' , this.urlArgsBundle.getStringExtra('game_uid'))
                    this.props.history.push(this.changeFragment(FRAGMENT_TAG.ADD_DESAWAR_GAME_CHART))
                }}
                icon={<Add/>}/> : '' )}
        </>;
    }

}

export default withRouter(DesawarGameChartFragment);