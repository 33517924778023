import {
    Add,
    CalendarTodayTwoTone, Delete, Edit, GridOn
} from "@material-ui/icons";
import axios from "axios";
import React from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import FloatingActionButton from "../../../components/floating_action_btn/FloatingActionButton";
import UrlArgsBundle from "../../../core/url_args_bundle";
import { createApiServerRoute } from "../../../models/network/api_client";
import { actionSetStandardGames } from "../../../models/redux/reducers/activity_main_reducers/games_reducer/action_games";
import { ACTIVITY_TAG, API_KEY, FRAGMENT_TAG, GAME_TYPES } from "../../../utils/constants";

class StandardGameFragment extends React.Component {

    deepEqual = (object1, object2) => {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);
        if (keys1.length !== keys2.length) {
            return false;
        }
        for (const key of keys1) {
            const val1 = object1[key];
            const val2 = object2[key];
            const areObjects = this.isObject(val1) && this.isObject(val2);
            if (
                (areObjects && !this.deepEqual(val1, val2)) ||
                (!areObjects && val1 !== val2)
            ) {
                return false;
            }
        }
        return true;
    }
    isObject = (object) => {
        return object != null && typeof object === 'object';
    }

    urlArgsBundle = new UrlArgsBundle();
    getAddStandardGameFragment = (fragmentTag) => {
        this.urlArgsBundle.setActivityTag(ACTIVITY_TAG.MAIN).setFragmentTag(fragmentTag);
        return this.urlArgsBundle.getArgsAsUrlParams()
    }

    deleteGame = (gameUid) => {
        const params = new URLSearchParams();
        params.append("__api_key__", API_KEY)
        params.append("game_uid", gameUid)
        params.append("game_type", GAME_TYPES.STANDARD)
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('delete_game.php'), params, config)
            .then(res => {
                console.log(res)
                if (res.data.state === 'OK') {
                    this.props.actionSetStandardGames(this.props.standardGames.filter((game) => game.uid !== gameUid))
                }
            });
    }

    componentDidMount() {
        const params = new URLSearchParams();
        params.append("__api_key__", API_KEY)
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('get_all_games_meta.php'), params, config)
            .then(res => {
                if (res.data.state === 'OK') {

                    if (!this.deepEqual(res.data.data.standardGames, this.props.standardGames)) {
                        this.props.actionSetStandardGames(res.data.data.standardGames)
                    }
                }
            });

    }

    render() {
        console.log(this.props.standardGames)

        let user = JSON.parse(localStorage.getItem('user'))

        return <>

            <div className="w-full game-cards flex gap-x-6 gap-y-10 flex-wrap justify-center p-16">
                {
                    this.props.standardGames.map((card, index) => {
                        return <div className={"card bg-gray-200 min-w-md rounded-lg"}>
                            <div className="card-header px-6 py-3 rounded-tr-xl rounded-tl-xl bg-blue-800 font-semibold text-white items-center flex justify-between">
                                <h1 className={"text-2xl text-white"}>{card.name}</h1>
                                <div className="card-options flex justify-left items-center gap-4">
                                    {
                                    user === null ? '' : (user['type'] === 'MAIN' ? <Edit className={"ml-16 cursor-pointer"} style={{ fontSize: '1.2rem' }} onClick={
                                        () => {
                                            this.urlArgsBundle.putExtra('game_uid', card.uid)
                                            this.props.history.push(this.getAddStandardGameFragment(FRAGMENT_TAG.UPDATE_STANDARD_GAME))
                                        }}
                                    /> : '' )}
                                    <CalendarTodayTwoTone className={"cursor-pointer"} style={{ fontSize: '1.2rem' }} onClick={
                                        () => {
                                            this.urlArgsBundle.putExtra('game_uid', card.uid)
                                            this.props.history.push(this.getAddStandardGameFragment(FRAGMENT_TAG.STANDARD_GAME_CHART))
                                        }} />
                                    {
                                        user === null ? '' : (user['type'] === 'MAIN' ? <><GridOn className={"cursor-pointer"} style={{ fontSize: '1.2rem' }} onClick={
                                        () => {
                                            this.props.history.push(this.getAddStandardGameFragment(FRAGMENT_TAG.STANDARD_GAME_SUB_CATEGORIES))
                                        }} />

                                    <Delete className={"cursor-pointer"} style={{ fontSize: '1.2rem' }} onClick={
                                        () => this.deleteGame(card.uid)} /> </> : '' )}
                                </div>
                            </div>
                            <div className="card-body py-4 flex flex-col gap-1 items-center justify-center ">
                                <h3>Open Time : <span className={"text-indigo-800"}>{card.open_time}</span></h3>
                                <h3>Close Time : <span className={"text-indigo-800"}>{card.close_time}</span></h3>
                            </div>
                        </div>
                    })
                }
            </div>
            {
                user === null ? '' : (user['type'] === 'MAIN' ? <FloatingActionButton
                clickHandler={() => this.props.history.push(this.getAddStandardGameFragment(FRAGMENT_TAG.ADD_STANDARD_GAME))}
                icon={<Add />} /> : '' )}
        </>
    }
}

const mapStateToProps = rootReducer => ({
    standardGames: rootReducer.gameReducer.games.currentStandardGames
})

const mapDispatchToProps = dispatch => ({
    actionSetStandardGames: standardGames => dispatch(actionSetStandardGames(standardGames))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StandardGameFragment));
