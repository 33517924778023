import React from "react";

const tableDateColumn = ({ date , clickHandler}) => {

    date = date.split(" ");

    return <td className="px-0 py-4 whitespace-nowrap" onClick={clickHandler}>
        <div className="flex">
            <div className="ml-4">
                <div className="text-sm font-medium text-gray-500">
                    {`${date[0]}`}
                </div>
                <div className="text-sm text-gray-500">
                    {date[1]}
                </div>
            </div>
        </div>
    </td>
}

export default tableDateColumn;