import MiniStatCardList from "../../../components/mini_stat_card_list_component/mini_stat_card_list/mini_stat_card_list";
import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {actionSetMeta} from "../../../models/redux/reducers/activity_main_reducers/dashboard_reducer/action_dashboard";

class DashboardFragment extends React.Component {

    render() {
        return <>
            <MiniStatCardList />
        </>
    }

}

const mapStateToProps = rootReducer => ({
    currentMeta: rootReducer.metaReducer.currentMeta
});

const mapDispatchToProps = dispatch => ({
    actionSetMeta: meta => dispatch(actionSetMeta(meta))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DashboardFragment));

