import React from 'react';

const tableBankColumn = ({upperName , lowerName , clickHandler}) => {
    return <td className="px-6 py-4 whitespace-pre" onClick={clickHandler}>
        <div className="flex items-center">
            <div className="ml-4">
                <div className="text-sm font-medium text-gray-900">
                    {`${upperName}`}
                </div>
                <div className="text-sm text-gray-500">
                    {lowerName}
                </div>
            </div>
        </div>
    </td>
}

export default tableBankColumn;