import {SET_UNPAID_DRIVERS} from "./action_set_unpaid_drivers";

const INITIAL_STATE = {
    unpaid_drivers: {
        api_called: false,
        drivers: []
    }
}

const unpaidDriversReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_UNPAID_DRIVERS:
            return {
                ...state,
                unpaid_drivers: action.payload
            }
        default:
            return state;
    }
}

export default unpaidDriversReducer;
