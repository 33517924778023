import './drawer_menu_item_styles.scss';

const DrawerMenuItem = ({isActive, icon, name , clickHandler}) => {
    return <li className={`drawer-menu-item relative cursor-pointer ${isActive && 'active'}`} onClick={clickHandler}>
        <div className="link">
            {icon}
            <span>&nbsp;&nbsp;{name}</span>
        </div>
    </li>
}

export default DrawerMenuItem;
