import {SET_META} from "./action_dashboard";

const INITIAL_STATE = {
    currentMeta: []
}

const metaReducer = (state = INITIAL_STATE , action) => {
    switch (action.type) {
        case SET_META:
            return {
                ...state,
                currentMeta: action.payload
            }
        default:
            return state;
    }
}

export default metaReducer;