import {SET_CURRENT_CITIES} from "./action_cities";

const INITIAL_STATE = {
    currentCities: []
}

const citiesReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_CURRENT_CITIES:
            return {
                ...state,
                currentCities: action.payload
            }
        default:
            return state;
    }
}

export default citiesReducer;
