import React from "react";

const TableGreenishTextualColumn = ({text , clickListener}) => {
    return <td className="px-6 py-4 whitespace-nowrap" onClick={clickListener}>
        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
             {text}
        </span>
    </td>
}

export default TableGreenishTextualColumn;
