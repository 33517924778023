export const SET_STANDARD_GAMES = 'SET_STANDARD_GAMES'
export const SET_STARLINE_GAMES = 'SET_STARLINE_GAMES'
export const SET_DESAWAR_GAMES = 'SET_DESAWAR_GAMES'

export const actionSetStarlineGames = starline_games => ({
    type : SET_STARLINE_GAMES,
    payload : starline_games
})

export const actionSetDesawarGames = desawar_games => ({
    type : SET_DESAWAR_GAMES,
    payload : desawar_games
})

export const actionSetStandardGames = standard_games => ({
    type : SET_STANDARD_GAMES,
    payload : standard_games
})