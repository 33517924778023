import React from "react";
import { withRouter } from "react-router-dom";
import AppBar from "../../../components/app_bar_component/app_bar";
import ContentFrame from "../../../components/content_frame_component/content_frame";
import Drawer from "../../../components/drawer_component/drawer/drawer";
import {ACTIVITY_TAG, ACTIVITY_TITLE, FRAGMENT_TAG, FRAGMENT_TITLE} from "../../../utils/constants";
import AddCityFragment from "../../fragments/add_city_fragment/AddCityFragment";
import AddCityToCityRideCategoryFragment from "../../fragments/add_city_to_city_ride_category_fragment/AddCityToCityRideCategoryFragment";
import AddDesawarGameFragment from "../../fragments/add_desawar_game_fragment/AddDesawarGameFragment";
import AddRideCategoryFragment from "../../fragments/add_ride_category_fragment/AddRideCategoryFragment";
import AddStandardGameChartFragment from "../../fragments/add_standard_game_chart_fragment/AddStandardGameChartFragment";
import AddStandardGame from "../../fragments/add_standard_game_fragment/AddStandardGame";
import AddStarlineGameFragment from "../../fragments/add_starline_game_fragment/AddStarlineGameFragment";
import CancelledTripsFragment from "../../fragments/cancelled_trips_fragment/CancelledTripsFragment";
import CaptainsFragment from "../../fragments/captains_fragment/CaptainsFragment";
import CitiesFragment from "../../fragments/cities_fragment/CitiesFragment";
import CityToCityRideCategoriesFragment from "../../fragments/city_to_city_ride_categories_fragment/CityToCityRideCategoriesFragment";
import CityToCityScheduledRideFragment from "../../fragments/city_to_city_scheduled_ride_fragment/CityToCityScheduledRideFragment";
import CityToCityScheduleRideFragment from "../../fragments/city_to_city_schedule_ride_fragment/CityToCityScheduleRideFragment";
import DashboardFragment from "../../fragments/dashboard_fragment/DashboardFragment";
import DesawarGameFragment from "../../fragments/desawar_game_fragment/DesawarGameFragment";
import DesawarGameSubCategoriesFragment from "../../fragments/desawar_game_sub_categories_fragment/DesawarGameSubCategoriesFragment";
import DriverProfileFragment from "../../fragments/driver_profile_fragment/DriverProfileFragment";
import FreeKilometersFragment from "../../fragments/free_kilometers_fragment/FreeKilometersFragment";
import GameRateFragment from "../../fragments/game_rate_fragment/GameRateFragment";
import PaymentsFragment from "../../fragments/payments_fragment/PaymentsFragment";
import ReviewsFragment from "../../fragments/reviews_fragment/ReviewsFragment";
import RideCategoriesFragment from "../../fragments/ride_categories_fragment/RideCategoriesFragment";
import RunningTripsFragment from "../../fragments/running_trips_fragment/RunningTripsFragment";
import ScheduledRidesFragment from "../../fragments/scheduled_rides_fragment/ScheduledRidesFragment";
import ScheduleRideFragment from "../../fragments/schedule_ride_fragment/ScheduleRideFragment";
import SettingFragment from "../../fragments/setting_fragment/SettingFragment";
import SetupFragment from "../../fragments/setup_fragment/SetupFragment";
import StandardGameChartFragment from "../../fragments/standard_game_chart_fragment/StandardGameChartFragment";
import StandardGameFragment from "../../fragments/standard_game_fragment/StandardGameFragment";
import StandardGameSubCategoriesFragment from "../../fragments/standard_game_sub_categories_fragment/StandardGameSubCategoriesFragment";
import StarlineGameFragment from "../../fragments/StarlineGameFragment/StarlineGameFragment";
import StarlineGameChartFragment from "../../fragments/starline_game_chart_fragment/StarlineGameChartFragment";
import StarlineGameSubCategoriesFragment from "../../fragments/starline_game_sub_categories_fragment/StarlineGameSubCategoriesFragment";
import TripsFragment from "../../fragments/trips_fragment/TripsFragment";
import UpdateCityFragment from "../../fragments/update_city_fragment/UpdateCityFragment";
import UpdateCityToCityRideCategoryFragment from "../../fragments/update_city_to_city_ride_category_fragment/UpdateCityToCityRideCategoryFragment";
import UpdateDesawarGameFragment from "../../fragments/update_desawar_game_fragment/UpdateDesawarGameFragment";
import UpdateRideCategoryFragment from "../../fragments/update_ride_category_fragment/UpdateRideCategoryFragment";
import UpdateStandardGameFragment from "../../fragments/update_standard_game_fragment/UpdateStandardGameFragment";
import UpdateStarlineGameFragment from "../../fragments/update_starline_game_fragment/UpdateStarlineGameFragment";
import UsersFragment from "../../fragments/users_fragment/UsersFragment";
import UserProfileFragment from "../../fragments/user_profile_fragment/UserProfileFragment";
import './activity_main_styles.scss';
import DesawarGameChartFragment from "../../fragments/desawar_game_chart_fragment/DesawarGameChartFragment";
import SliderImagesFragment from "../../fragments/slider_images_fragment/SliderImagesFragment";
import AddStarlineGameChartFragment
    from "../../fragments/add_starline_game_chart_fragment/AddStarlineGameChartFragment";
import AddDesawarGameChartFragment from "../../fragments/add_desawar_game_chart_fragment/AddDesawarGameChartFragment";
import InAppSettingsFragment from "../../fragments/in_app_settings_fragment/InAppSettingFragment";
import PaymentGatewayFragment from "../../fragments/payment_gateway_fragment/PaymentGatewayFragment";
import WithdrawFragment from "../../fragments/withdraw_fragment/WithdrawFragment";
import PushNotificationFragment from "../../fragments/push_notification_fragment/PushNotificationFragment";
import UrlArgsBundle from "../../../core/url_args_bundle";
// import SubAdminFragment from "../../fragments/sub_admin_fragment/SubAdminFragment";
import AboutFragment from "../../fragments/about_fragment/AboutFragment";
import AddSubAdminFragment from "../../fragments/add_sub_admin_fragment/AddSubAdminFragment";
import UpdateStandardGameChartFragment
    from "../../fragments/update_standard_game_chart_fragment/UpdateStandardGameChartFragment";
import ChangePasswordFragment from "../../fragments/change_password_fragment/ChangePasswordFragment";
import TeerGameFragment from "../../fragments/teer_game_fragment/TeerGameFragment";
import UpdateTeerGameFragment from "../../fragments/update_teer_game_fragment/UpdateTeerGameFragment";
import FcGameFragment from "../../fragments/fc_game_fragment/FcGameFragment";
import UpdateFcGameFragment from "../../fragments/update_fc_game_fragment/UpdateFcGameFragment";
import PlayHistoryFragment from "../../fragments/play_history_fragment/PlayHistoryFragment";
import UpdateResultFragment from "../../fragments/update_result/UpdateResultFragment";
import WinnersFragment from "../../fragments/winners_fragment/WinnersFragment";


class MainActivity extends React.Component {

    urlArgsBundle = (new UrlArgsBundle()).prepareFrom(this.props.location.search);
    componentDidMount() {

        if (localStorage.getItem('user') === null) {
            console.log("hello")
            this.urlArgsBundle.setActivityTag(ACTIVITY_TAG.LOGIN)
            this.props.history.push(this.urlArgsBundle.getArgsAsUrlParams())
        }
    }

    getCurrentFragment = (fragment) => {
        switch (fragment) {
       /*     case FRAGMENT_TAG.ADD_CITY:
                return [FRAGMENT_TITLE.ADD_CITY, <AddCityFragment />]
            case FRAGMENT_TAG.ADD_RIDE_CATEGORY:
                return [FRAGMENT_TITLE.ADD_RIDE_CATEGORY, <AddRideCategoryFragment />]
            case FRAGMENT_TAG.CAPTAINS:
                return [FRAGMENT_TITLE.CAPTAINS, <CaptainsFragment />]
            case FRAGMENT_TAG.CITIES:
                return [FRAGMENT_TITLE.CITIES, <CitiesFragment />]
            case FRAGMENT_TAG.DRIVER_PROFILE:
                return [FRAGMENT_TITLE.DRIVER_PROFILE, <DriverProfileFragment />]
            case FRAGMENT_TAG.PAYMENTS:
                return [FRAGMENT_TITLE.PAYMENTS, <PaymentsFragment />]
            case FRAGMENT_TAG.REVIEWS:
                return [FRAGMENT_TITLE.REVIEWS, <ReviewsFragment />]
            case FRAGMENT_TAG.RIDE_CATEGORIES:
                return [FRAGMENT_TITLE.RIDE_CATEGORIES, <RideCategoriesFragment />]
            case FRAGMENT_TAG.SETUP:
                return [FRAGMENT_TITLE.SETUP, <SetupFragment />]
            case FRAGMENT_TAG.SCHEDULED_RIDES:
                return [FRAGMENT_TITLE.SCHEDULED_RIDES, <ScheduledRidesFragment />]
            case FRAGMENT_TAG.SCHEDULE_RIDE:
                return [FRAGMENT_TITLE.SCHEDULE_RIDE, <ScheduleRideFragment />]
            case FRAGMENT_TAG.UPDATE_CITY:
                return [FRAGMENT_TITLE.UPDATE_CITY, <UpdateCityFragment />]
            case FRAGMENT_TAG.TRIPS:
                return [FRAGMENT_TITLE.TRIPS, <TripsFragment />]
            case FRAGMENT_TAG.UPDATE_RIDE_CATEGORY:
                return [FRAGMENT_TITLE.UPDATE_RIDE_CATEGORY, <UpdateRideCategoryFragment />]
                */
            case FRAGMENT_TAG.USERS:
                return [FRAGMENT_TITLE.USERS, <UsersFragment />]
          /*  case FRAGMENT_TAG.FREE_KILOMETERS:
                return [FRAGMENT_TITLE.FREE_KILOMETERS, <FreeKilometersFragment />]
            case FRAGMENT_TAG.RUNNING_TRIPS:
                return [FRAGMENT_TITLE.RUNNING_TRIPS, <RunningTripsFragment />]
            case FRAGMENT_TAG.CANCELLED_TRIPS:
                return [FRAGMENT_TITLE.CANCELLED_TRIPS, <CancelledTripsFragment />]
            case FRAGMENT_TAG.USER_PROFILE:
                return [FRAGMENT_TITLE.USER_PROFILE, <UserProfileFragment />]
            case FRAGMENT_TAG.CITY_TO_CITY_SCHEDULED_RIDE:
                return [FRAGMENT_TITLE.CITY_TO_CITY_SCHEDULED_RIDE, <CityToCityScheduledRideFragment />]
            case FRAGMENT_TAG.CITY_TO_CITY_SCHEDULE_RIDE:
                return [FRAGMENT_TITLE.CITY_TO_CITY_SCHEDULE_RIDE, <CityToCityScheduleRideFragment />]
            case FRAGMENT_TAG.CITY_TO_CITY_RIDE_CATEGORIES:
                return [FRAGMENT_TITLE.CITY_TO_CITY_RIDE_CATEGORIES, <CityToCityRideCategoriesFragment />]
            case FRAGMENT_TAG.ADD_CITY_TO_CITY_RIDE_CATEGORY:
                return [FRAGMENT_TITLE.ADD_CITY_TO_CITY_RIDE_CATEGORY, <AddCityToCityRideCategoryFragment />]
            case FRAGMENT_TAG.UPDATE_CITY_TO_CITY_RIDE_CATEGORY:
                return [FRAGMENT_TITLE.UPDATE_CITY_TO_CITY_RIDE_CATEGORY, <UpdateCityToCityRideCategoryFragment />]
            case FRAGMENT_TAG.STANDARD_GAME:
                return [FRAGMENT_TITLE.STANDARD_GAME, <StandardGameFragment />]
            case FRAGMENT_TAG.STARLINE_GAME:
                return [FRAGMENT_TITLE.STARLINE_GAME, <StarlineGameFragment />]
            case FRAGMENT_TAG.DESAWAR_GAME:
                return [FRAGMENT_TITLE.DESAWAR_GAME, <DesawarGameFragment />]
            case FRAGMENT_TAG.ADD_STANDARD_GAME:
                return [FRAGMENT_TITLE.ADD_STANDARD_GAME, <AddStandardGame />]
            case FRAGMENT_TAG.UPDATE_STANDARD_GAME:
                return [FRAGMENT_TITLE.UPDATE_STANDARD_GAME, <UpdateStandardGameFragment />]
            case FRAGMENT_TAG.UPDATE_STARLINE_GAME:
                return [FRAGMENT_TITLE.UPDATE_STARLINE_GAME, <UpdateStarlineGameFragment />]
            case FRAGMENT_TAG.ADD_STARLINE_GAME:
                return [FRAGMENT_TITLE.ADD_STARLINE_GAME, <AddStarlineGameFragment />]
            case FRAGMENT_TAG.UPDATE_DESAWAR_GAME:
                return [FRAGMENT_TITLE.UPDATE_DESAWAR_GAME, <UpdateDesawarGameFragment />]
            case FRAGMENT_TAG.ADD_DESAWAR_GAME:
                return [FRAGMENT_TITLE.ADD_DESAWAR_GAME, <AddDesawarGameFragment />]
                */
            case FRAGMENT_TAG.SETTING_FRAGMENT:
                return [FRAGMENT_TITLE.SETTING_FRAGMENT, <SettingFragment />]

                /*
            case FRAGMENT_TAG.STANDARD_GAME_SUB_CATEGORIES:
                return [FRAGMENT_TITLE.STANDARD_GAME_SUB_CATEGORIES, <StandardGameSubCategoriesFragment />]
            case FRAGMENT_TAG.STARLINE_GAME_SUB_CATEGORIES:
                return [FRAGMENT_TITLE.STARLINE_GAME_SUB_CATEGORIES, <StarlineGameSubCategoriesFragment />]
            case FRAGMENT_TAG.DESAWAR_GAME_SUB_CATEGORIES:
                return [FRAGMENT_TITLE.DESAWAR_GAME_SUB_CATEGORIES, <DesawarGameSubCategoriesFragment />]
                */

            case FRAGMENT_TAG.GAME_RATE:
                return [FRAGMENT_TITLE.GAME_RATE, <GameRateFragment />]
                /*
            case FRAGMENT_TAG.STANDARD_GAME_CHART:
                return [FRAGMENT_TITLE.STANDARD_GAME_CHART, <StandardGameChartFragment />]
            case FRAGMENT_TAG.ADD_STANDARD_GAME_CHART:
                return [FRAGMENT_TITLE.ADD_STANDARD_GAME_CHART, <AddStandardGameChartFragment />]
            case FRAGMENT_TAG.STARLINE_GAME_CHART:
                return [FRAGMENT_TITLE.STARLINE_GAME_CHART, <StarlineGameChartFragment />]
            case FRAGMENT_TAG.DESAWAR_GAME_CHART:
                return [FRAGMENT_TITLE.DESAWAR_GAME_CHART, <DesawarGameChartFragment />]
                */
            case FRAGMENT_TAG.SLIDER_IMAGES:
                return [FRAGMENT_TITLE.SLIDER_IMAGES, <SliderImagesFragment />]
                /*
           
            case FRAGMENT_TAG.ADD_DESAWAR_GAME_CHART:
                return [FRAGMENT_TITLE.ADD_DESAWAR_GAME_CHART, <AddDesawarGameChartFragment />]
                */
                case FRAGMENT_TAG.ADD_STARLINE_GAME_CHART:
                return [FRAGMENT_TITLE.ADD_STARLINE_GAME_CHART, <AddStarlineGameChartFragment />]

            case FRAGMENT_TAG.IN_APP_SETTINGS:
                return [FRAGMENT_TITLE.IN_APP_SETTINGS, <InAppSettingsFragment />]
            case FRAGMENT_TAG.PAYMENT_GATEWAY:
                return [FRAGMENT_TITLE.PAYMENT_GATEWAY, <PaymentGatewayFragment />]
            case FRAGMENT_TAG.WITHDRAW:
                return [FRAGMENT_TITLE.WITHDRAW, <WithdrawFragment />]
            case FRAGMENT_TAG.PUSH_NOTIFICATION:
                return [FRAGMENT_TITLE.PUSH_NOTIFICATION, <PushNotificationFragment />]
          //   case FRAGMENT_TAG.SUB_ADMINS:
          //       return [FRAGMENT_TITLE.SUB_ADMINS, <SubAdminFragment />]
          case FRAGMENT_TAG.WINNERS:
            return [FRAGMENT_TITLE.WINNERS, <WinnersFragment/>]
            case FRAGMENT_TAG.ABOUT:
                return [FRAGMENT_TITLE.ABOUT, <AboutFragment />]
            case FRAGMENT_TAG.ADD_SUB_ADMIN:
                return [FRAGMENT_TITLE.ADD_SUB_ADMIN, <AddSubAdminFragment />]
            case FRAGMENT_TAG.UPDATE_STANDARD_GAME_CHART:
                return [FRAGMENT_TITLE.UPDATE_STANDARD_GAME_CHART, <UpdateStandardGameChartFragment />]
            case FRAGMENT_TAG.CHANGE_PASSWORD:
                return [FRAGMENT_TITLE.CHANGE_PASSWORD, <ChangePasswordFragment />]
            case FRAGMENT_TAG.TEER_GAME:
                return [FRAGMENT_TITLE.TEER_GAME, <TeerGameFragment />]
            case FRAGMENT_TAG.PLAY_HISTORY:
                return [FRAGMENT_TITLE.PLAY_HISTORY, <PlayHistoryFragment />]
            case FRAGMENT_TAG.UPDATE_RESULT:
                return [FRAGMENT_TITLE.UPDATE_RESULT, <UpdateResultFragment />]
            case FRAGMENT_TAG.UPDATE_TEER_GAME:
                return [FRAGMENT_TITLE.UPDATE_TEER_GAME, <UpdateTeerGameFragment />]
            case FRAGMENT_TAG.FC_GAME:
                return [FRAGMENT_TITLE.TEER_GAME, <FcGameFragment />]
            case FRAGMENT_TAG.UPDATE_FC_GAME:
                return [FRAGMENT_TITLE.UPDATE_FC_GAME, <UpdateFcGameFragment />]
            default:
                return [FRAGMENT_TITLE.DASHBOARD, <DashboardFragment />]
        }
    }

    render() {

        const queryParams = {};

        for (let [k, v] of (new URLSearchParams(this.props.location.search)).entries()) {
            queryParams[k] = v;
        }

        let currentFragmentTag = FRAGMENT_TAG.DASHBOARD;
        let currentFragmentTitle = FRAGMENT_TITLE.DASHBOARD;
        let currentFragment = <DashboardFragment />;

        if (queryParams.hasOwnProperty('fragment')) {
            if (localStorage.getItem('user') === null) {
                console.log("hello")
                this.urlArgsBundle.setActivityTag(ACTIVITY_TAG.LOGIN)
                this.props.history.push(this.urlArgsBundle.getArgsAsUrlParams())
                window.location.reload()
            }
            let { fragment: fragmentTag } = queryParams;
            let [fragmentTitle, fragment] = this.getCurrentFragment(fragmentTag);
            currentFragmentTag = fragmentTag;
            currentFragmentTitle = fragmentTitle;
            currentFragment = fragment;
        }

        document.title = ACTIVITY_TITLE.MAIN;

        return <>
            <div className="main-activity h-screen py-4 px-4">
                <div className="h-full flex">
                    <Drawer awer fragmentTag={currentFragmentTag} />
                    <ContentFrame>
                        <AppBar fragmentTitle={currentFragmentTitle} />
                        <div className="app-bar-bottom-divider mx-5" />
                        <div className="content-container mr-1 mb-3 px-3 py-6 overflow-y-auto">
                            {currentFragment}
                        </div>
                    </ContentFrame>
                </div>
            </div>
        </>
    }
}

export default withRouter(MainActivity);
