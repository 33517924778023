import React from "react";
import {Button, TextField} from "@material-ui/core";
import {Stack} from "@mui/material";
import {API_KEY} from "../../../utils/constants";
import axios from "axios";
import {createApiServerRoute} from "../../../models/network/api_client";
import { FiUploadCloud } from "react-icons/fi";
import { AvatarInput } from "../slider_images_fragment/SliderImagesFragment";

class SettingFragment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            paytmPublicKey: null,
            paytmPrivateKey: null,
            googlePayUid: null,
            phonePayUid: null,
            upiId: null,
            upiId2: null,
            message: null,
            qrCode: null,
            url : "",
            images : null
        }
    }

    handleFiles = (e) => {
        console.log(e)
        this.setState({
            ...this.state,
            url : URL.createObjectURL(e.target.files[0]),
            qrCode: e.target.files[0]
        })
    };

    onDataUpdate = () => {
        const params = new URLSearchParams();
        params.append("__api_key__", API_KEY)
        params.append("paytm_public_key", this.state.paytmPublicKey)
        params.append("paytm_private_key", this.state.paytmPrivateKey)
        params.append("googlepay_uid", this.state.googlePayUid)
        params.append("phonepe_uid", this.state.phonePayUid)
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('update_payment_gateway_keys.php'), params, config)
            .then(res => {
                console.log(res.data.data.keys)
                if (res.data.state === 'OK') {
                    this.setState({
                        ...this.state,
                        paytmPublicKey: res.data.data.keys['paytm_public_key'],
                        paytmPrivateKey: res.data.data.keys['paytm_private_key'],
                        googlePayUid: res.data.data.keys['googlepay_key'],
                        phonePayUid: res.data.data.keys['phonepe_key']
                    })
                    // window.location.reload()
                }
            });

            const config1 = {
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                }
            };
            console.log("test-----------------",this.state.qrCode);

            const params1 = new FormData();
            params1.append("__api_key__", API_KEY)
            params1.append("upi_id", this.state.upiId)
            params1.append("upi_id2", this.state.upiId2)
            params1.append("text", this.state.message)
            if(this.state.qrCode!= null){
                params1.append("image", this.state.qrCode, this.state.qrCode.name)
            }
            axios.post(createApiServerRoute('create_fund.php'), params1, config1)
                .then(res => {
                    if (res.data.state === 'OK') {
                        this.setState({
                            ...this.state,
                            upiId: res.data.data.fund['upi_id'],
                            upiId2: res.data.data.fund['upi_id2'],
                            message: res.data.data.fund['text'],
                            qrCode: res.data.data.fund['image'],
                        })
                        window.location.reload()
                    }
                });
    }

    componentDidMount() {
        const params = new URLSearchParams();
        params.append("__api_key__", API_KEY)
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('fetch_payment_gateway_keys.php'), params, config)
            .then(res => {
                if (res.data.state === 'OK') {
                    console.log(res.data)
                    this.setState({
                        ...this.state,
                        paytmPublicKey: res.data.data.keys['paytm_public_key'],
                        paytmPrivateKey: res.data.data.keys['paytm_private_key'],
                        googlePayUid: res.data.data.keys['googlepay_key'],
                        phonePayUid: res.data.data.keys['phonepe_key']
                    })
                }
            });

            axios.post(createApiServerRoute('get_fund_meta.php'), params, config)
            .then(res => {
                if (res.data.state === 'OK') {
                    console.log(res.data)
                    this.setState({
                        ...this.state,
                            upiId: res.data.data.fund['upi_id'],
                            upiId2: res.data.data.fund['upi_id2'],
                            message: res.data.data.fund['text'],
                            images: res.data.data.fund['image'],
                    })
                }
            });
    }

    render() {
        return <div className={"py-1 ba-gray-200 grid place-items-center"}>
            <div className="form-card w-1/3 bg-white py-6 px-10 rounded-2xl shadow-2xl flex flex-col gap-6">
                <h1 className={"text-center text-indigo-800 text-2xl pb-3"}>Payment Gateways</h1>

                <Stack direction={"row"}>
                    <TextField fullWidth id="outlined-basic" value={this.state.paytmPublicKey || ''} label="Paytm Key 1" variant="outlined"
                        onChange={e => {
                            this.setState({
                                ...this.state,
                                paytmPublicKey: e.target.value
                            })
                        }}/>
                    <Button sx={{p: 50}} size={'large'} variant="contained" className={"px-6"} color={"primary"} onClick={() => this.onDataUpdate()}>Update</Button>
                </Stack>
                <Stack direction={"row"}>
                    <TextField fullWidth id="outlined-basic" value={this.state.paytmPrivateKey || ''} label="Paytm Key 2" variant="outlined"
                        onChange={e => {
                            this.setState({
                                ...this.state,
                                paytmPrivateKey : e.target.value
                            })
                        }}/>
                    <Button sx={{p: 50}} size={'large'} variant="contained" className={"px-6"} color={"primary"} onClick={() => this.onDataUpdate()}>Update</Button>
                </Stack>
                <Stack direction={"row"}>
                    <TextField fullWidth id="outlined-basic" value={this.state.googlePayUid || ''} label="PhonePe UPI ID" variant="outlined"
                        onChange={e => {
                            this.setState({
                                ...this.state,
                                googlePayUid : e.target.value
                            })
                        }}/>
                    <Button sx={{p: 50}} size={'large'} variant="contained" className={"px-6"} color={"primary"} onClick={() => this.onDataUpdate()}>Update</Button>
                </Stack>
                <Stack direction={"row"}>
                    <TextField fullWidth id="outlined-basic" value={this.state.phonePayUid || ''} label="GooglePay UPI ID" variant="outlined"
                        onChange={e => {
                            this.setState({
                                ...this.state,
                                phonePayUid : e.target.value
                            })
                        }}/>
                    <Button sx={{p: 50}} size={'large'} variant="contained" className={"px-6"} color={"primary"} onClick={() => this.onDataUpdate()}>Update</Button>
                </Stack>
                
                <Stack direction={"row"}>
                    <TextField fullWidth id="outlined-basic" value={this.state.upiId || ''} label="UPI ID" variant="outlined"
                        onChange={e => {
                            this.setState({
                                ...this.state,
                                upiId : e.target.value
                            })
                        }}/>
                    <Button sx={{p: 50}} size={'large'} variant="contained" className={"px-6"} color={"primary"} onClick={() => this.onDataUpdate()}>Update</Button>
                </Stack>

                <Stack direction={"row"}>
                    <TextField fullWidth id="outlined-basic" value={this.state.upiId2 || ''} label="UPI ID 2" variant="outlined"
                        onChange={e => {
                            this.setState({
                                ...this.state,
                                upiId2 : e.target.value
                            })
                        }}/>
                    <Button sx={{p: 50}} size={'large'} variant="contained" className={"px-6"} color={"primary"} onClick={() => this.onDataUpdate()}>Update</Button>
                </Stack>
                <Stack direction={"row"}>
                    <TextField fullWidth id="outlined-basic" value={this.state.message || ''} label="Message" variant="outlined"
                        onChange={e => {
                            this.setState({
                                ...this.state,
                                message : e.target.value
                            })
                        }}/>
                    <Button sx={{p: 50}} size={'large'} variant="contained" className={"px-6"} color={"primary"} onClick={() => this.onDataUpdate()}>Update</Button>
                </Stack>
                <Stack direction={"row"}>
                        <div className={"relative"} style={{width: '70%'}}>
                            <input type="file" className={"absolute top-0 left-0 right-0 bottom-0 opacity-0"} onChange={(e) => this.handleFiles(e)}/>
                            {this.state.qrCode === null ?
                            <FiUploadCloud style={{width: 40, height: 50, color: '#1C3FAA', cursor: "pointer"}}
                                               as={Button}/> :
                                                <p>{this.state.qrCode.name}</p>
                            }
                        </div>
                    <Button sx={{p: 50}} size={'large'} variant="contained" className={"px-6"} color={"primary"} onClick={() => this.onDataUpdate()}>Update</Button>
                </Stack>
            </div>
        </div>

    }
}

export default SettingFragment