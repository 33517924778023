import { SET_CURRENT_CAPTAINS } from "./action_captains";

const INITIAL_STATE = {
    currentCaptains: []
}

const captainsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_CURRENT_CAPTAINS:
            return {
                ...state,
                currentCaptains: action.payload
            }
        default:
            return state;
    }
}

export default captainsReducer;
