import React from "react";
import './fragment_city_to_city_ride_categories.scss';
import {Add} from "@material-ui/icons";

import axios from 'axios';
import {createApiServerRoute} from "../../../models/network/api_client";

import {connect} from 'react-redux';
import {actionSetCurrentCityToCityRideCategories} from "../../../models/redux/reducers/activity_main_reducers/city_to_city_ride_categories_reducer/action_city_to_city_ride_categories";
import {Switch} from "@material-ui/core";
import FloatingActionButton from "../../../components/floating_action_btn/FloatingActionButton";
import {withRouter} from "react-router-dom";
import {ACTIVITY_TAG, FRAGMENT_TAG} from "../../../utils/constants";
import TableHeading from "../../../components/table_component/table_heading/table_heading";
import TableIndexColumn from "../../../components/table_component/table_index_column/table_index_column";
import TableUserNameColumn from "../../../components/table_component/table_user_name_column/table_user_name_column";
import Table from "../../../components/table_component/table/table";
import TableHeader from "../../../components/table_component/table_header/table_header";
import TableBody from "../../../components/table_component/table_body/table_body";
import TableTextualColumn from "../../../components/table_component/table_textual_column/table_textual_column";
import TableContainerColumn from "../../../components/table_component/table_container_column/table_container_column";
import UrlArgsBundle from "../../../core/url_args_bundle";
import Chip from "../../../components/chip_component/chip";
import ChipsContainer from "../../../components/chip_component/chips_container/chips_container";

class CityToCityRideCategoriesFragment extends React.Component {

    toggleRideCategory = (rideCategoryId) => {
        this.props.actionSetCurrentCityToCityRideCategories(this.props.cityToCityRideCategoriesReducer.map(rideCategory => ({
            ...rideCategory,
            enableStatus: rideCategory.id === rideCategoryId ? 'TOGGLING' : rideCategory.enableStatus
        })))

        const params = new URLSearchParams();
        params.append('city_to_city_ride_category_id', rideCategoryId);

        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('toggle_city_to_city_ride_category_enable_disable_state.php'), params, config)
            .then(res => {
                console.log(res.data);
                this.props.actionSetCurrentCityToCityRideCategories(this.props.cityToCityRideCategoriesReducer.map(rideCategory => ({
                    ...rideCategory,
                    enableStatus: rideCategory.id === rideCategoryId ? 'TOGGLED' : rideCategory.enableStatus,
                    enabled: rideCategory.id === rideCategoryId ? res.data.data.enabled : rideCategory.enabled
                })));
            });
    }

    componentDidMount() {
        const params = new URLSearchParams();
        params.append('which_ones', 'ALL');

        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        };

        axios.post(createApiServerRoute('fetch_city_to_city_ride_categories.php'), params, config)
            .then(res => {
                console.log(res);
                this.props.actionSetCurrentCityToCityRideCategories(res.data.data.categories.map(rideCategory => ({
                    ...rideCategory,
                    enableStatus: 'IDLE'
                })));
            });
    }

    getAddRideCategory = () => {
        const urlArgsBundle = new UrlArgsBundle();
        urlArgsBundle.setActivityTag(ACTIVITY_TAG.MAIN).setFragmentTag(FRAGMENT_TAG.ADD_CITY_TO_CITY_RIDE_CATEGORY);
        return urlArgsBundle.getArgsAsUrlParams()
    }

    getUpdateRideCategory = (rideId) => {
        const urlArgsBundle = new UrlArgsBundle();
        urlArgsBundle.setActivityTag(ACTIVITY_TAG.MAIN).setFragmentTag(FRAGMENT_TAG.UPDATE_CITY_TO_CITY_RIDE_CATEGORY);
        urlArgsBundle.putExtra("RIDE_CATEGORY_ID" , rideId)
        return urlArgsBundle.getArgsAsUrlParams()
    }

    getTripsFragment = fragmentTag => {
        const urlArgsBundle = new UrlArgsBundle();
        urlArgsBundle.setActivityTag(ACTIVITY_TAG.MAIN).setFragmentTag(fragmentTag);
        return urlArgsBundle.getArgsAsUrlParams()
    }

    render() {


        console.log(this.props.cityToCityRideCategoriesReducer)


        return <>
            <ChipsContainer>
                <Chip active={false} clickHandler={() => {this.props.history.push(this.getTripsFragment(FRAGMENT_TAG.RIDE_CATEGORIES))}}>Ride Categories</Chip>
                <Chip active={true} clickHandler={() => {this.props.history.push(this.getTripsFragment(FRAGMENT_TAG.CITY_TO_CITY_RIDE_CATEGORIES))}}>City To City Ride Categories</Chip>
            </ChipsContainer>

            <div className="flex flex-col">
                <Table>
                    <TableHeader>
                        <TableHeading>#</TableHeading>
                        <TableHeading>Name</TableHeading>
                        <TableHeading>Price Per Km</TableHeading>
                        <TableHeading>Enabled</TableHeading>
                        <TableHeading>Created At</TableHeading>
                    </TableHeader>
                    <TableBody>
                        {this.props.cityToCityRideCategoriesReducer.map((rideCategory, index) => <tr key={rideCategory.id}>
                            <TableIndexColumn clickHandler={() => this.props.history.push(this.getUpdateRideCategory(rideCategory.id))} num={index + 1} />

                            <TableUserNameColumn
                                avatarUrl={rideCategory.image}
                                email={`Rs. ${rideCategory.price}`}
                                firstName={rideCategory.from_city + ' to'} lastName={rideCategory.to_city}
                                clickHandler={ ()  => this.props.history.push(this.getUpdateRideCategory(rideCategory.id)) } />

                            <TableTextualColumn text={rideCategory.per_km_cost}
                                                clickHandler={ () => this.props.history.push(this.getUpdateRideCategory(rideCategory.id)) } />

                            <TableContainerColumn>
                                <Switch onChange={() => {
                                    this.toggleRideCategory(rideCategory.id)
                                }} color='primary' checked={rideCategory.enabled} disabled={rideCategory.enableStatus === 'TOGGLING'}/>
                            </TableContainerColumn>

                            <TableTextualColumn text={rideCategory.created_at}
                                                clickHandler={() => this.props.history.push(this.getAddRideCategory(rideCategory.id))} />
                        </tr>)}
                    </TableBody>
                </Table>
            </div>
            <FloatingActionButton
                clickHandler={() => this.props.history.push(this.getAddRideCategory())}
                icon={<Add />} />
        </>
    }
}

const mapStateToProps = rootReducer => ({
    cityToCityRideCategoriesReducer: rootReducer.cityToCityRideCategoriesReducer.currentCityToCityRideCategories
});

const mapDispatchToProps = dispatch => ({
    actionSetCurrentCityToCityRideCategories: rideCategories => dispatch(actionSetCurrentCityToCityRideCategories(rideCategories))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CityToCityRideCategoriesFragment));
