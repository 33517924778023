import React from "react";
import {Button, Snackbar, TextField} from "@material-ui/core";
import {API_KEY} from "../../../utils/constants";
import axios from "axios";
import {createApiServerRoute} from "../../../models/network/api_client";
import {Alert} from "@mui/material";

class ChangePasswordFragment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            password: '',
            showError: false,
            error : 'Please Fill All Fields',
            type : 'error'
        }
    }


    updatePassword = () => {

        if (this.state.password === '') {
            this.setState({...this.state , showError: true})
        } else {

            const params = new URLSearchParams();
            let user = JSON.parse(localStorage.getItem('user'))

            params.append("__api_key__", API_KEY)
            params.append("admin_uid", user.uid)
            params.append("password", this.state.password)

            const config = {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                }
            };

            axios.post(createApiServerRoute('update_password.php'), params, config)
                .then(res => {
                    console.log(res)
                    if (res.data.state === 'OK' && res.data.data['updated']) {
                        this.setState({...this.state, showError: true, error: 'password_updated' , type: 'success'})
                    } else {
                        this.setState({...this.state, showError: true, error: Object.keys(res.data.data.exceptions)[0]})
                    }
                })
        }

    }

    render() {
        return (
            <>
                <Snackbar anchorOrigin={{horizontal: "right" , vertical: "top"}} open={this.state.showError} autoHideDuration={6000} onClose={() => {
                    this.setState({...this.state , showError: false, type: 'error', error: 'Please fill all fields'})
                }}>
                    <Alert onClose={() => {this.setState({...this.state , showError: false, error: 'Please fill all fields'})}} severity={this.state.type} sx={{ width: '100%' }}>
                        {this.state.error}
                    </Alert>
                </Snackbar>
                <div className={"py-36 ba-gray-200 grid place-items-center"}>
                    <div className="form-card w-96 bg-white py-6 px-10 rounded-2xl shadow-2xl flex flex-col gap-6">
                        <h1 className={"text-center text-indigo-800 text-2xl pb-3"}>Change Password</h1>
                        <TextField fullWidth id="standard-basic" label="Password" value={this.state.password} variant="outlined" ref='password'
                                   hintText="Password"
                                   floatingLabelText="Password"
                                   type="password"
                                   onChange={(e) => {
                                       this.setState({...this.state , password : e.target.value})
                                   }}/>
                        <Button className={"h-12 rounded-md"} variant="contained" color="primary" onClick={this.updatePassword}>Update</Button>

                    </div>
                </div>
            </>
        );
    }

}

export default ChangePasswordFragment
